import React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from '../Partners.module.css';

const PartnerSubformHeader = ({ title }) => (
  <div className={styles.partnerSubformHeader}>
    <Typography variant="h6">{title}</Typography>
  </div>
);

export default PartnerSubformHeader;
