import initialState from './state';
import { LOAD_CRM_SHOPS } from './action-types';

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${LOAD_CRM_SHOPS}_SUCCESS`:
      return {
        ...state,
        shops: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
