import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import styles from '../MergeUsers.module.css';
import RegistrationRequestRepository from '../../../repository/registrationRequests/RegistrationRequestRepository';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import SelectUserButton from './SelectUserButton';

export default function RegistrationRequestSearch(props) {
  const [result, setResult] = React.useState({});
  const [busy, setBusy] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(props.searchTerm);

  useEffect(() => {
    if (searchTerm) {
      searchRegistrationRequests(searchTerm);
    }
  }, [searchTerm]);

  const searchRegistrationRequests = (searchTerm) => {
    setBusy(true);
    setSearchTerm(searchTerm);

    RegistrationRequestRepository.search(searchTerm, 'PENDING')
      .then((data) => {
        setResult(data);
      })
      .catch((error) =>
        toastr.error('Could not load Registration requests', error.message),
      )
      .finally(() => {
        setBusy(false);
      });
  };

  const resultTable =
    result != null && result.length > 0 ? (
      <Table>
        <TableBody>
          {result.map((regReq) => (
            <TableRow>
              <TableCell>Registration request</TableCell>
              <TableCell className={styles.tableCell}>{regReq.email}</TableCell>
              <TableCell className={styles.tableCell}>
                {regReq.shopName}
              </TableCell>
              <TableCell className={styles.tableCell}>
                {moment(regReq.created).format('DD/MM/YYYY HH:mm')}
              </TableCell>
              <TableCell>
                {' '}
                <SelectUserButton
                  user={{
                    type: 'REGISTRATION_REQUEST',
                    id: regReq.id,
                    email: regReq.email,
                  }}
                  onClick={props.onClick}
                  searchTerm={searchTerm}
                />
              </TableCell>
            </TableRow>
          ))}{' '}
        </TableBody>
      </Table>
    ) : (
      <div>
        <div>No Registration requests found</div>
        <HorizontalSpacer />
      </div>
    );

  return busy ? <CircularProgress /> : <div>{resultTable}</div>;
}
