import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'ramda';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { noop } from '../../../../../utils/utils';
import {
  APP_USAGE_PERIODS,
  SUPPORTED_APP_USAGE_PERIODS,
} from './constants/app-usage-periods';

export default class AppUsagePeriodDropDown extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOf(SUPPORTED_APP_USAGE_PERIODS),
    onChange: PropTypes.func,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    id: 'appUsagePeriodDropDown',
    label: 'Period',
    value: APP_USAGE_PERIODS.ONE_MONTH.key,
    onChange: noop,
    margin: 'none',
    fullWidth: false,
    required: false,
    disabled: false,
  };

  render() {
    return (
      <FormControl
        margin={this.props.margin}
        fullWidth={this.props.fullWidth}
        required={this.props.required}
        disabled={this.props.disabled}>
        <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
        <Select
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}>
          {values(APP_USAGE_PERIODS).map((usagePeriod) => (
            <MenuItem key={usagePeriod.key} value={usagePeriod.key}>
              {usagePeriod.label}
            </MenuItem>
          ))}
        </Select>
        {this.props.disabled ? <FormHelperText>Disabled</FormHelperText> : ''}
      </FormControl>
    );
  }
}
