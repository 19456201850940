import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { noop } from '../../../utils/utils';
import WarningMessage from '../../../shared/components/messages/WarningMessage/WarningMessage';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import styles from './UserChangeEmail.module.css';
import UserChangeEmailCard from './components/UserChangeEmailCard/UserChangeEmailCard';

export default class UserChangeEmail extends React.Component {
  static propTypes = {
    email: PropTypes.string,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    email: '',
    onSave: noop,
    onCancel: noop,
    isLoading: false,
  };

  render() {
    return (
      <div className={styles.userChangeEmail}>
        <h1>Change Email Address</h1>

        {this.props.isLoading ? (
          <CircularProgress />
        ) : (
          <div className={styles.emailCardWrapper}>
            <WarningMessage text="Please remind the user that he will be logged out from the user app when his email address is changed" />
            <HorizontalSpacer />
            <UserChangeEmailCard
              email={this.props.email}
              onSave={this.props.onSave}
              onCancel={this.props.onCancel}
            />
          </div>
        )}
      </div>
    );
  }
}
