import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorContainer.module.css';

export default class ErrorContainer extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    errorMessage: '',
  };

  shouldComponentUpdate(nextProps) {
    return this.props.errorMessage !== nextProps.errorMessage;
  }

  setInputRef = (ref) => {
    this.inputRef = ref;
  };

  showErrorMessage = () => {
    if (this.inputRef) {
      this.inputRef.setCustomValidity(this.props.errorMessage);
      this.inputRef.reportValidity();
    }
  };

  render() {
    this.showErrorMessage();

    return <input ref={this.setInputRef} className={styles.hiddenInput} />;
  }
}
