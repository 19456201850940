import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import styles from './EdenredReportForm.module.css';

export default class EdenredReportForm extends React.Component {
  state = {
    fileSelected: false,
  };

  static propTypes = {
    downloadReportLink: PropTypes.string,
    diff: PropTypes.string,
    onUpload: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.fileSelector = React.createRef();
  }

  setUploadState = () => {
    this.setState({ fileSelected: this.fileSelector.current?.files?.length });
  };

  upload = () => {
    if (this.state.fileSelected) {
      this.props.onUpload(this.fileSelector.current.files[0]);
    }
  };

  render() {
    return (
      <div className={styles.edenredIntegrationWrapper}>
        <h1>Edenred integration</h1>
        <HorizontalSpacer />
        <div className={styles.edenredForm}>
          <TextField
            name="upload-csv"
            type="file"
            inputRef={this.fileSelector}
            onChange={this.setUploadState}
          />
          <Button
            id="upload-button"
            variant="contained"
            color="primary"
            disabled={!this.state.fileSelected}
            onClick={this.upload}>
            Upload report
          </Button>
        </div>
        <HorizontalSpacer />
        {this.props.diff && <div id="edenred-diff">{this.props.diff}</div>}
        <HorizontalSpacer />
        <div className={styles.edenredForm}>
          <Link href={this.props.downloadReportLink} rel="noopener noreferrer">
            <Button variant="contained" color="primary">
              Download report
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}
