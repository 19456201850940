import React from 'react';
import { connect } from 'react-redux';
import { getAuthUserEmail } from '../../store/session/selectors';
import Home from './Home';

const mapStateToProps = (state) => ({
  authenticatedUserEmail: getAuthUserEmail(state),
});

class HomeContainer extends React.Component {
  render() {
    return <Home userName={this.props.authenticatedUserEmail} />;
  }
}

export default connect(mapStateToProps)(HomeContainer);
