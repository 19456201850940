import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { errorMiddleware } from 'redux-thunk-lifecycle/src/middleware/error';
import { createBrowserHistory } from 'history';
import createRootReducer from './reducer';
import { mapErrorToToast } from './middlewareHelper/errorMiddlewareHelper';

export const history = createBrowserHistory();

const middleware = [
  thunk,
  routerMiddleware(history),
  errorMiddleware(mapErrorToToast),
];

const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
