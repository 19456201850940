import React from 'react';
import PartnersMPIntegration from './PartnersMPIntegration';
import PartnerSubformHeader from './PartnerSubformHeader';

export default class PartnersMPIntegrationsContainer extends React.Component {
  defaultIntegration = {
    id: undefined,
    partnerId: undefined,
    showInMerchantPortal: undefined,
    description: [],
    logoLink: undefined,
    moreInfoLink: undefined,
    type: undefined,
  };

  state = {
    disabled: false,
    integrations: [],
  };

  constructor(props, context) {
    super(props, context);
    const { updateIntegrations, integrations, disabled } = this.props;
    this.updateIntegrations = updateIntegrations;
    this.state.disabled = disabled;
    this.state.integrations = this.isEmptyIntegrations(integrations)
      ? [this.defaultIntegration]
      : integrations;
  }

  isEmptyIntegrations = (integrations) => {
    let isEmptyArray = !integrations || integrations?.length === 0;
    if (isEmptyArray) return true;
    let integration = integrations[0];
    let isEmptyIntegration = !integration || !integration.partnerId;
    return isEmptyArray && isEmptyIntegration;
  };

  updateIntegration = (index, integration) => {
    let newIntegrations = this.state.integrations;
    newIntegrations[index] = integration;

    let newState = {
      ...this.state,
      integrations: newIntegrations,
    };
    this.updateIntegrations(newState.integrations);
  };

  renderIntegration = (integration, index) => {
    return (
      <div
        hidden={this.state.disabled}
        style={{ width: '100%', marginTop: '.5rem' }}
        key={index}>
        <PartnerSubformHeader title="Merchant Portal Partner Integration" />
        <PartnersMPIntegration
          disabled={this.state.disabled}
          index={index}
          integration={integration}
          updateIntegration={this.updateIntegration}
          required={integration.showInMerchantPortal}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.integrations?.map((integration, key) =>
          this.renderIntegration(integration, key),
        )}
      </>
    );
  }
}
