import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { toastr } from 'react-redux-toastr';
import { generatePath, withRouter } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import moment from 'moment';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import CONSTANTS from '../../../constants';
import BackIconButton from '../../../shared/components/BackIconButton/BackIconButton';
import shopRepository from '../../../repository/shop/ShopRepository';
import Field from '../../../shared/components/Field/Field';
import styles from './ShopDetails.module.css';
import ShopIntegrations from './components/ShopIntegrations/ShopIntegrations';
import ShopDetailActionMenu from './components/ShopDetailActionMenu/ShopDetailActionMenu';

class ShopDetails extends React.Component {
  static propTypes = {
    shopId: PropTypes.number,
  };

  static defaultProps = {
    shopId: 0,
  };

  state = {
    isBusy: false,
    shop: {
      featureSet: [],
      paymentMethods: [],
      thirdPartyReferences: [],
      shopChurnReason: null,
    },
  };

  componentDidMount() {
    this.loadShop();
  }

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  loadShop = () => {
    this.setIsBusy(true);

    shopRepository
      .getShopById(this.props.shopId)
      .then(this.setShop)
      .catch((error) => toastr.error('Could not load shop', error.message))
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  setShop = (shop) => {
    this.setState({
      ...this.state,
      shop,
    });
  };

  shopFeatures() {
    return (
      <div className={styles.features}>
        {this.state.shop.featureSet.map((feature, index) => (
          <Chip
            key={index}
            label={feature}
            color={'default'}
            className={styles.featureChip}
          />
        ))}
      </div>
    );
  }

  generalShopData() {
    return (
      <div className={styles.shopForm}>
        <Field label="Name" value={this.state.shop.name} />
        <Field label="E-mail" value={this.state.shop.email} />
        <Field label="Language" value={this.state.shop.language} />
        <Field label="Source code" value={this.state.shop.sourceCode} />
        <Field label="Source ID" value={this.state.shop.sourceId} />
        <Field label="Merchant ID" value={`${this.state.shop.merchantId}`} />
        <Field label="Address" value={this.getAddress()} />
        <Field label="City" value={this.getCity()} />
        <Field label="Longitude" value={`${this.state.shop.longitude}`} />
        <Field label="Latitude" value={`${this.state.shop.latitude}`} />
        <Field
          label="Subscription type"
          value={this.state.shop.subscriptionType}
        />
        <Field label="Currency" value={this.state.shop.currencyCode} />
        <Field label="Phone" value={this.state.shop.phone} />
        <Field label="Website" value={this.state.shop.website} />
        <Field label="Webshop" value={this.state.shop.webshop} />
        <Field label="Facebook link" value={this.state.shop.facebookLink} />
        <Field
          label="Date to be deleted"
          value={
            this.state.shop.dateToBeDeleted
              ? moment(this.state.shop.dateToBeDeleted).format('DD/MM/YYYY')
              : null
          }
        />
        <Field
          label="Date when shop was deleted"
          value={
            this.state.shop.deletionDate
              ? moment(this.state.shop.deletionDate).format('DD/MM/YYYY HH:mm')
              : null
          }
        />
        <Field
          label="Churn Reason"
          value={
            this.state.shop?.shopChurnReason?.churnReason?.description || ''
          }
        />
        <Field
          label="Churn Date"
          value={this.state.shop?.shopChurnReason?.churnDate || ''}
        />
        <Field
          label="Subscription start date"
          value={
            this.state.shop.subscriptionStartDate
              ? moment(this.state.shop.subscriptionStartDate).format(
                  'DD/MM/YYYY',
                )
              : null
          }
        />
        {this.state.shop.description ? (
          <p className={styles.description}>{this.state.shop.description}</p>
        ) : (
          <span />
        )}
        {this.shopFeatures()}
      </div>
    );
  }

  getAddress() {
    return this.state.shop.address ? this.state.shop.address : '';
  }

  getCity() {
    const city = this.state.shop.city ?? '';
    const zip = this.state.shop.zipCode ?? '';
    const citySeparator = !!city && !!zip ? ' ' : '';
    const country = this.state.shop.country ?? '';
    const countrySeparator = ' - ';
    return city + citySeparator + zip + countrySeparator + country;
  }

  mapsUrl() {
    return (
      'https://www.google.com/maps/embed/v1/place?q=' +
      this.state.shop.latitude +
      ',' +
      this.state.shop.longitude +
      '&key=' +
      CONSTANTS.ENV.GOOGLE_MAPS_API_KEY
    );
  }

  render() {
    const shopData = (
      <div>
        <Paper>
          <div className={styles.cardHeader}>
            <BackIconButton
              id="backButton"
              linkTo={generatePath(CONSTANTS.ROUTES.MERCHANT_DETAILS.path, {
                merchantId: this.state.shop.merchantId
                  ? this.state.shop.merchantId
                  : 0,
              })}
            />
            <Typography variant="h5" className={styles.shopName}>
              {this.state.shop.name} - {this.state.shop.id}
            </Typography>
            <ShopDetailActionMenu
              shopId={this.state.shop.id}
              canRestoreShop={this.state.shop.deleted}
              canDeleteShop={!this.state.shop.deleted}
              onStateChange={this.loadShop}
            />
            {this.state.shop.deleted ? (
              <Chip label="Deleted" color="secondary" />
            ) : this.state.shop.dateToBeDeleted ? (
              <Chip label="Marked to be deleted" />
            ) : null}
            {this.state.shop.hidden ? (
              <Chip label="Hidden" color="secondary" />
            ) : null}
          </div>
          {this.generalShopData()}
        </Paper>

        <iframe
          title="Shop location"
          width="600"
          height="450"
          loading="lazy"
          allowFullScreen
          src={this.mapsUrl()}></iframe>

        <ShopIntegrations
          paymentMethods={this.state.shop.paymentMethods}
          thirdPartyReferences={this.state.shop.thirdPartyReferences}
          pointOfSales={this.state.shop.pointOfSales}
          apiKeys={this.state.shop.apiKeys}
          shop={this.state.shop}
        />
      </div>
    );
    return (
      <div className={styles.shopDetails}>
        <HorizontalSpacer size="small" />
        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>{this.state.shop ? shopData : <NoDataFound />}</div>
        )}
      </div>
    );
  }
}

export default withRouter(ShopDetails);
