import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { noop } from '../../../../../utils/utils';

class BackToCampaignDetailsButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: noop,
  };

  render() {
    return (
      <Button
        id="backToDetailsButton"
        type="button"
        variant="outlined"
        color="secondary"
        onClick={this.props.onClick}>
        Back to details
      </Button>
    );
  }
}

export default BackToCampaignDetailsButton;
