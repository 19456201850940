import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { toastr } from 'react-redux-toastr';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import CONSTANTS from '../../../../../constants';
import IconButtonWithConfirmation from '../../../../../shared/components/ButtonWithConfirmation/IconButtonWithConfirmation';
import ButtonWithConfirmation from '../../../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import NoDataFound from '../../../../../shared/components/NoDataFound/NoDataFound';
import partnerOverviewService from '../../../overview/PartnerOverviewService';
import styles from './ApiKeys.module.css';

export default class ApiKeys extends React.Component {
  state = {
    keys: [],
    permissions: [],
    isBusy: true,
    newUserEmail: '',
  };

  static propTypes = {
    partnerId: PropTypes.string,
  };

  componentDidMount() {
    this.loadApiKeys();
  }

  loadApiKeys() {
    this.setIsBusy(true);
    partnerOverviewService
      .getPartnerApiKeys(this.props.partnerId)
      .then((data) => {
        this.setState({
          ...this.state,
          keys: data,
        });
      })
      .catch((error) => toastr.error('Could not load apiKeys', error.message))
      .finally(() => {
        this.setIsBusy(false);
      });
  }

  setIsBusy = (busy) => {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  };

  addApiKey = () => {
    this.setIsBusy(true);
    return new Promise((resolve, reject) => {
      partnerOverviewService
        .createApiKey(this.props.partnerId, this.state.permissions.join(','))
        .then((data) => {
          toastr.success('Success', `ApiKey was created`);
          resolve();
        })
        .catch((error) => {
          toastr.error('Could not create ApiKey', this.getError(error));
          reject();
        })
        .finally(() => {
          this.setIsBusy(false);
          this.loadApiKeys();
          this.setState({
            ...this.state,
            permissions: [],
          });
        });
    });
  };

  addPermission = (event, added) => {
    if (added) {
      this.setState({
        ...this.state,
        permissions: this.state.permissions.concat([event.target.value]),
      });
    } else {
      this.setState({
        ...this.state,
        permissions: this.state.permissions.filter(
          (e) => e !== event.target.value,
        ),
      });
    }
  };

  getError(error) {
    let message = '';

    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) {
          if (error.response.data.error.description) {
            return error.response.data.error.description;
          } else {
            return error.message;
          }
        } else {
          if (error.response.data.errorMessage) {
            message = error.response.data.errorMessage;
            if (error.response.data.fieldErrors) {
              error.response.data.fieldErrors.forEach((e) => {
                message += ` ${e.field}: ${e.message}`;
              });
            }
          } else {
            message = error.message;
          }
        }
      } else {
        message = error.response;
      }
    } else {
      message = error.message;
    }

    return message;
  }

  deleteApiKey = (apiKey) => {
    this.setIsBusy(true);

    partnerOverviewService
      .deleteApiKey(this.props.partnerId, apiKey)
      .then(() => {
        toastr.success('Success', `Api key was deleted`);
      })
      .catch((error) => toastr.error('Could not delete api key', error.message))
      .finally(() => {
        this.setIsBusy(false);
        this.loadApiKeys();
      });
  };

  render() {
    return (
      <div className={styles.buttonBar}>
        {this.addUserButton()}
        <Paper className={styles.tableWrapper} id="usersOverview">
          {this.state.isBusy ? (
            <CircularProgress />
          ) : this.state.keys.length ? (
            this.userTable()
          ) : (
            <NoDataFound />
          )}
        </Paper>
      </div>
    );
  }

  userTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ApiKey</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Permissions</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.keys.map((apiKey) => (
            <TableRow key={apiKey.apiKey}>
              <TableCell>{apiKey.apiKey}</TableCell>
              <TableCell>{apiKey.status}</TableCell>
              <TableCell>{apiKey.permissions}</TableCell>
              <TableCell className={styles.actionColumn}>
                <div className={styles.actionBar}>
                  <div className={styles.action}>
                    {this.deleteButton(apiKey)}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  addUserButton() {
    return (
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_PARTNERS]}>
        <ButtonWithConfirmation
          buttonVariant="text"
          buttonText="Add"
          onConfirm={this.addApiKey}
          cancelButtonText="Cancel"
          icon={<AddIcon />}
          confirmButtonText="Add"
          title="Add api key"
          id="AddApiKey"
          message="Select permissions and confirm">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Loyalty Token"
              onChange={this.addPermission}
              value={'OP_CREATE_LOYALTY_TOKEN'}
              checked={this.state.permissions.includes(
                'OP_CREATE_LOYALTY_TOKEN',
              )}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Loyalty Tx"
              onChange={this.addPermission}
              value={'OP_CREATE_PARTNER_LOYALTY_TX'}
              checked={this.state.permissions.includes(
                'OP_CREATE_PARTNER_LOYALTY_TX',
              )}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Reward Tx"
              onChange={this.addPermission}
              value={'OP_CREATE_PARTNER_REWARD_TX'}
              checked={this.state.permissions.includes(
                'OP_CREATE_PARTNER_REWARD_TX',
              )}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Coupon Tx"
              onChange={this.addPermission}
              value={'OP_CREATE_PARTNER_COUPON_TX'}
              checked={this.state.permissions.includes(
                'OP_CREATE_PARTNER_COUPON_TX',
              )}
            />
          </FormGroup>
        </ButtonWithConfirmation>
      </HasPermission>
    );
  }

  deleteButton(apiKey) {
    return (
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_PARTNERS]}>
        <IconButtonWithConfirmation
          onConfirm={this.deleteApiKey}
          param={apiKey.apiKey}
          message="Are you sure that you want to delete this api key?"
          title="Delete"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          id={'deleteApiKey'}
          icon={<DeleteIcon />}>
          {apiKey.apiKey}
        </IconButtonWithConfirmation>
      </HasPermission>
    );
  }
}
