import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { noop } from '../../../../../utils/utils';
import styles from './CreateCampaignButton.module.css';

class CreateCampaignButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: noop,
  };

  render() {
    return (
      <Tooltip
        title="Create Campaign"
        placement="left"
        classes={{ tooltip: styles.tooltip }}>
        <Fab
          id="createCampaignButton"
          className={styles.button}
          color="primary"
          aria-label="Add"
          onClick={this.props.onClick}>
          <AddIcon />
        </Fab>
      </Tooltip>
    );
  }
}

export default CreateCampaignButton;
