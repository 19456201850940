import { createSelector } from 'reselect';
import { createLoadingSelector } from '../lifecycle/selectors';
import name from './name';
import {
  CREATE_CAMPAIGN,
  GET_MESSAGE_CAMPAIGNS,
  LOAD_CAMPAIGN_BY_ID,
  PUBLISH_CAMPAIGN,
  SEND_TEST_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from './action-types';

export const getSubState = (state) => state[name];

export const getPaginatedJoynMessageCampaigns = createSelector(
  [getSubState],
  (state) => state.paginatedJoynMessageCampaigns,
);

export const getCampaign = createSelector(
  [getSubState],
  (state) => state.campaign,
);

export const getJoynMessageCampaigns = createSelector(
  [getPaginatedJoynMessageCampaigns],
  (paginatedJoynMessageCampaigns) => paginatedJoynMessageCampaigns.content,
);

export const getJoynMessageCampaignsPageNumber = createSelector(
  [getPaginatedJoynMessageCampaigns],
  (paginatedJoynMessageCampaigns) => paginatedJoynMessageCampaigns.number,
);

export const getJoynMessageCampaignsPageSize = createSelector(
  [getPaginatedJoynMessageCampaigns],
  (paginatedJoynMessageCampaigns) => paginatedJoynMessageCampaigns.size,
);

export const getJoynMessageCampaignsTotalElements = createSelector(
  [getPaginatedJoynMessageCampaigns],
  (paginatedJoynMessageCampaigns) =>
    paginatedJoynMessageCampaigns.totalElements,
);

export const getCampaignName = createSelector(
  [getCampaign],
  (campaign) => campaign.name,
);

export const getCampaignType = createSelector(
  [getCampaign],
  (campaign) => campaign.type,
);

export const getCampaignLanguage = createSelector(
  [getCampaign],
  (campaign) => campaign.templateLanguage,
);

export const getCampaignSubject = createSelector(
  [getCampaign],
  (campaign) => campaign.subject,
);

export const getCampaignContent = createSelector(
  [getCampaign],
  (campaign) => campaign.content,
);

export const getCampaignCoverImage = createSelector(
  [getCampaign],
  (campaign) => campaign.coverImage,
);

export const getCampaignCallToAction = createSelector(
  [getCampaign],
  (campaign) => campaign.callToAction,
);

export const getCampaignCallToActionText = createSelector(
  [getCampaignCallToAction],
  (campaignCallToAction = {}) => campaignCallToAction.text,
);

export const getCampaignCallToActionAddress = createSelector(
  [getCampaignCallToAction],
  (campaignCallToAction = {}) => campaignCallToAction.url,
);

export const getCampaignTarget = createSelector(
  [getCampaign],
  (campaign) => campaign.targetFilter,
);

export const getCampaignTargetRecipientCount = createSelector(
  [getCampaignTarget],
  (campaignTarget = {}) => campaignTarget.recipientCount,
);

export const getCampaignTargetRecipientLimit = createSelector(
  [getCampaignTarget],
  (campaignTarget = {}) => campaignTarget.recipientLimit,
);

export const getCampaignTargetZipCodes = createSelector(
  [getCampaignTarget],
  (campaignTarget = {}) => campaignTarget.zipCodes,
);

export const getCampaignTargetLanguages = createSelector(
  [getCampaignTarget],
  (campaignTarget = {}) => campaignTarget.languages,
);

export const getCampaignTargetGender = createSelector(
  [getCampaignTarget],
  (campaignTarget = {}) => campaignTarget.gender,
);

export const getCampaignTargetAgeRanges = createSelector(
  [getCampaignTarget],
  (campaignTarget = {}) => campaignTarget.ageRanges,
);

export const getCampaignTargetUserType = createSelector(
  [getCampaignTarget],
  (campaignTarget = {}) => campaignTarget.userType,
);

export const getCampaignTargetUserAppUsagePeriod = createSelector(
  [getCampaignTarget],
  (campaignTarget = {}) => campaignTarget.userAppUsagePeriod,
);

export const isFetchingJoynMessageCampaigns = createLoadingSelector([
  GET_MESSAGE_CAMPAIGNS,
]);
export const isLoadingCampaign = createLoadingSelector([LOAD_CAMPAIGN_BY_ID]);
export const isCreatingCampaign = createLoadingSelector([CREATE_CAMPAIGN]);
export const isUpdatingCampaign = createLoadingSelector([UPDATE_CAMPAIGN]);
export const isSendingTestCampaign = createLoadingSelector([
  SEND_TEST_CAMPAIGN,
]);
export const isPublishingCampaign = createLoadingSelector([PUBLISH_CAMPAIGN]);
