import initialState from './state';
import { GET_BUSINESSES } from './action-types';

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_BUSINESSES}_SUCCESS`:
      return {
        ...state,
        paginatedBusinesses: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
