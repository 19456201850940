import React from 'react';
import PropTypes from 'prop-types';
import RecipientLimitDisplay from './components/RecipientLimitDisplay/RecipientLimitDisplay';
import RecipientCountDisplay from './components/RecipientCountDisplay/RecipientCountDisplay';

class RecipientInfo extends React.Component {
  static propTypes = {
    recipientLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recipientCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    recipientLimit: 0,
    recipientCount: 0,
    errorMessage: '',
  };

  render() {
    return (
      <div>
        <RecipientLimitDisplay value={this.props.recipientLimit} />
        <RecipientCountDisplay
          value={this.props.recipientCount}
          errorMessage={this.props.errorMessage}
        />
      </div>
    );
  }
}

export default RecipientInfo;
