import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import { path } from 'ramda';
import TextField from '@material-ui/core/TextField/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import { Button, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import EntityForm from '../../../shared/components/EntityForm/EntityForm';
import CONSTANTS from '../../../constants';
import ShopRepository from '../../../repository/shop/ShopRepository';
import NavigationService from '../../../shared/navigation/NavigationService';
import LanguageDropDown from '../../../shared/components/LanguageDropDown/LanguageDropDown';
import { CURRENCIES } from '../../../shared/constants/currencies';
import CRMRepository from '../../../repository/crm/CRMRepository';
import OdooAutocomplete from './OdooAutocomplete';
import styles from './ShopDetailsCreate.module.css';

export default class ShopDetailsCreate extends React.Component {
  state = {
    isBusy: false,
    fieldErrors: {},
    subscriptions: [],
    shop: {
      language: 'nl',
      country: 'BE',
      currencyCode: 'EUR',
      sourceCode: 'ODOO',
      merchantId: 0,
      zipCode: null,
    },
  };

  componentDidMount() {
    const merchantId = parseInt(
      path(['match', 'params', 'merchantId'], this.props),
      10,
    );
    this.setState((prevState) => ({
      ...prevState,
      shop: {
        ...prevState.shop,
        merchantId: merchantId,
      },
      merchantId: merchantId,
    }));
  }

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  setFieldError = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [key]: value,
      },
    }));
  };

  updateShopProp = (prop, value) => {
    this.setState((prevState) => ({
      ...prevState,
      shop: {
        ...prevState.shop,
        [prop]: value,
      },
    }));
  };

  handleFieldChange = (prop) => (event) => {
    this.updateShopProp(prop, event.target.value);
    this.setFieldError(prop, null);
  };

  backToMerchantDetails = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.MERCHANT_DETAILS.path, {
      merchantId: this.state.merchantId,
    });
  };

  toShopDetails = (shopId) => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.SHOP_DETAILS.path, {
      shopId: shopId,
    });
  };

  handleUpdateFailure = (error) => {
    const { response } = error;
    if (
      response.status === 409 &&
      response.data.errorMessage === 'SHARED_LOYALTY_NOT_CONFIRMED'
    ) {
      this.setState({ askConfirmation: true });
    } else if (response.status === 409 && response.data.error) {
      toastr.error(response.data.error.title, response.data.error.description);
    } else if (response.status === 400 && response.data.fieldErrors) {
      response.data.fieldErrors.forEach((fieldError) =>
        this.setFieldError(fieldError.field, fieldError.message),
      );
    } else {
      toastr.error('Could not save shop', error.message);
    }
    this.setIsBusy(false);
  };

  save = () => {
    this.setIsBusy(true);

    ShopRepository.createShop(this.state.shop)
      .then((data) => this.toShopDetails(data.id))
      .catch((error) => this.handleUpdateFailure(error));
  };

  mapsUrl() {
    return (
      'https://www.google.be/maps?q=' +
      this.state.shop.latitude +
      ',' +
      this.state.shop.longitude +
      '&key=AIzaSyBUQ1cXk4eAxHV-AgVUPB-PtWc1g0v1XJ4'
    );
  }

  setLatLong = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      shop: {
        ...prevState.shop,
        latitude: data.latitude,
        longitude: data.longitude,
      },
    }));
  };

  geoLookup = () => {
    CRMRepository.geoCode({
      zip: this.state.shop.zipCode,
      city: this.state.shop.city,
      countryCode: this.state.shop.country,
      street: this.state.shop.address,
    })
      .then((data) => this.setLatLong(data))
      .catch((error) =>
        toastr.error('Could not look up lat/long', error.message),
      );
  };

  latState() {
    if (
      this.state.shop.latitude &&
      (this.state.shop.latitude > 60 || this.state.shop.latitude < 40)
    ) {
      return styles.latLongNok;
    }
    return styles.latLongOk;
  }

  longState() {
    if (
      this.state.shop.longitude &&
      (this.state.shop.longitude > 10 || this.state.shop.longitude < 0)
    ) {
      return styles.latLongNok;
    }
    return styles.latLongOk;
  }

  onSelected = (shop) => {
    this.setState({
      ...this.state,
      shop: shop,
    });
    this.updateShopProp('merchantId', this.state.merchantId);

    CRMRepository.readShopSubscriptionData(shop.id).then((data) => {
      this.setState({
        ...this.state,
        subscriptions: data.subscriptions,
      });
      this.updateShopProp('subscriptionStartDate', data.startDateGuess);
    });
  };

  renderShopForm() {
    return (
      <div className={styles.shopDetailsCardWrapper}>
        <HorizontalSpacer />
        <OdooAutocomplete onSelected={this.onSelected} />
        <HorizontalSpacer />
        <EntityForm onCancel={this.backToMerchantDetails} onSave={this.save}>
          {this.state.askConfirmation === true ? (
            <>
              <Alert severity={'warning'}>
                This is a second shop. Does this merchant have shared loyalty?
                <HorizontalSpacer />
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    onChange={this.handleFieldChange('activateSharedLoyalty')}
                    aria-label="activateSharedLoyalty"
                    name="activateSharedLoyalty">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Alert>
            </>
          ) : (
            undefined
          )}
          <TextField
            label="Name"
            InputLabelProps={{ shrink: true }}
            type="text"
            id="name"
            value={this.state.shop.name}
            onChange={this.handleFieldChange('name')}
            error={!!this.state.fieldErrors.name}
            helperText={this.state.fieldErrors.name}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="E-mail"
            InputLabelProps={{ shrink: true }}
            type="email"
            id="email"
            value={this.state.shop.email}
            onChange={this.handleFieldChange('email')}
            error={!!this.state.fieldErrors.email}
            helperText={this.state.fieldErrors.email}
            margin="normal"
            fullWidth
            required
          />
          <FormControl
            margin="normal"
            id="country"
            fullWidth
            error={!!this.state.fieldErrors.country}>
            <InputLabel shrink={true}>Country code</InputLabel>
            <Select
              required
              value={this.state.shop.country}
              onChange={this.handleFieldChange('country')}>
              <MenuItem value="" />
              <MenuItem id="BE" value="BE">
                BE
              </MenuItem>
              <MenuItem id="NL" value="NL">
                NL
              </MenuItem>
              <MenuItem id="LU" value="LU">
                LU
              </MenuItem>
              <MenuItem id="FR" value="FR">
                FR
              </MenuItem>
              <MenuItem id="GB" value="GB">
                GB
              </MenuItem>
              <MenuItem id="DE" value="DE">
                DE
              </MenuItem>
              <MenuItem id="PT" value="PT">
                PT
              </MenuItem>
            </Select>
          </FormControl>
          <LanguageDropDown
            label="Language"
            InputLabelProps={{ shrink: true }}
            id="language"
            value={this.state.shop.language}
            onChange={this.handleFieldChange('language')}
            margin="normal"
            fullWidth
            required
            allowEmpty={true}
            error={!!this.state.fieldErrors.language}
          />
          <TextField
            label="Source ID"
            InputLabelProps={{ shrink: true }}
            id="sourceId"
            type="text"
            value={this.state.shop.sourceId}
            onChange={this.handleFieldChange('sourceId')}
            error={!!this.state.fieldErrors.sourceId}
            helperText={this.state.fieldErrors.sourceId}
            margin="normal"
            fullWidth
            required
          />
          <FormControl
            margin="normal"
            id="sourceCode"
            required
            fullWidth
            error={!!this.state.fieldErrors.sourceCode}>
            <InputLabel shrink={true}>Source code</InputLabel>
            <Select
              value={this.state.shop.sourceCode}
              onChange={this.handleFieldChange('sourceCode')}>
              <MenuItem id="ODOO" value="ODOO">
                ODOO
              </MenuItem>
              <MenuItem id="NAVISION" value="NAVISION">
                NAVISION
              </MenuItem>
              <MenuItem id="DYNAMICS" value="DYNAMICS">
                Dynamics
              </MenuItem>
              <MenuItem id="QUSTO" value="QUSTO">
                Qusto
              </MenuItem>
              <MenuItem id="ONLINE_ONBOARDING" value="ONLINE_ONBOARDING">
                ONLINE_ONBOARDING
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.currencyCode}>
            <InputLabel shrink={true}>Currency</InputLabel>
            <Select
              label="Currency"
              value={this.state.shop.currencyCode}
              margin="normal"
              onChange={this.handleFieldChange('currencyCode')}
              fullWidth
              required
              error={!!this.state.fieldErrors.currencyCode}
              displayEmpty>
              {Object.keys(CURRENCIES).map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {CURRENCIES[currency]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Phone number"
            InputLabelProps={{ shrink: true }}
            type="tel"
            value={this.state.shop.phone}
            onChange={this.handleFieldChange('phone')}
            error={!!this.state.fieldErrors.phone}
            helperText={this.state.fieldErrors.phone}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Facebook link"
            InputLabelProps={{ shrink: true }}
            type="text"
            value={this.state.shop.facebookLink}
            onChange={this.handleFieldChange('facebookLink')}
            error={!!this.state.fieldErrors.facebookLink}
            helperText={this.state.fieldErrors.facebookLink}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Website"
            InputLabelProps={{ shrink: true }}
            type="text"
            value={this.state.shop.website}
            onChange={this.handleFieldChange('website')}
            error={!!this.state.fieldErrors.website}
            helperText={this.state.fieldErrors.website}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Webshop"
            InputLabelProps={{ shrink: true }}
            type="text"
            value={this.state.shop.webshop}
            onChange={this.handleFieldChange('webshop')}
            error={!!this.state.fieldErrors.webshop}
            helperText={this.state.fieldErrors.webshop}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Address"
            InputLabelProps={{ shrink: true }}
            type="text"
            value={this.state.shop.address}
            onChange={this.handleFieldChange('address')}
            error={!!this.state.fieldErrors.address}
            helperText={this.state.fieldErrors.address}
            margin="normal"
            fullWidth
          />
          <TextField
            label="City"
            InputLabelProps={{ shrink: true }}
            type="text"
            value={this.state.shop.city}
            onChange={this.handleFieldChange('city')}
            error={!!this.state.fieldErrors.city}
            helperText={this.state.fieldErrors.city}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Zip code"
            InputLabelProps={{ shrink: true }}
            type="text"
            value={this.state.shop.zipCode}
            onChange={this.handleFieldChange('zipCode')}
            error={!!this.state.fieldErrors.zipCode}
            helperText={this.state.fieldErrors.zipCode}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Latitude (±50)"
            InputLabelProps={{ shrink: true }}
            id="latitude"
            type="number"
            value={this.state.shop.latitude}
            onChange={this.handleFieldChange('latitude')}
            error={!!this.state.fieldErrors.latitude}
            helperText={this.state.fieldErrors.latitude}
            className={this.latState()}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="Longitude (±5)"
            InputLabelProps={{ shrink: true }}
            id="longitude"
            type="number"
            value={this.state.shop.longitude}
            onChange={this.handleFieldChange('longitude')}
            error={!!this.state.fieldErrors.longitude}
            helperText={this.state.fieldErrors.longitude}
            className={this.longState()}
            margin="normal"
            fullWidth
            required
          />
          <div>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={this.geoLookup}>
              Lookup lat/long
            </Button>
          </div>
          <a href={this.mapsUrl()} target="_blank" rel="noopener noreferrer">
            Show on map
          </a>
          <TextField
            label="Description"
            InputLabelProps={{ shrink: true }}
            multiline={true}
            type="text"
            value={this.state.shop.description}
            onChange={this.handleFieldChange('description')}
            error={!!this.state.fieldErrors.description}
            helperText={this.state.fieldErrors.description}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Subscription start date"
            InputLabelProps={{ shrink: true }}
            type="date"
            value={this.state.shop.subscriptionStartDate}
            onChange={this.handleFieldChange('subscriptionStartDate')}
            error={!!this.state.fieldErrors.subscriptionStartDate}
            helperText={this.state.fieldErrors.subscriptionStartDate}
            margin="normal"
            fullWidth
          />
          <div>
            {!!this.state.subscriptions &&
            this.state.subscriptions.length > 0 ? (
              <div>
                <div>Odoo subscriptions</div>
                {this.state.subscriptions.map((s) => (
                  <div>
                    {s.startDate} {s.product} {s.tags} {s.code} {s.tags}
                  </div>
                ))}
              </div>
            ) : (
              undefined
            )}
          </div>
        </EntityForm>
      </div>
    );
  }
  render() {
    return (
      <div className={styles.shopDetailsUpdate}>
        <h1>Create shop</h1>

        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>{this.renderShopForm()}</div>
        )}
      </div>
    );
  }
}
