import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import styles from './UserSearch.module.css';

export default function UserSearch(props) {
  const [email, setEmail] = React.useState(null);
  const [firstname, setFirstname] = React.useState(null);
  const [lastname, setLastname] = React.useState(null);

  useEffect(() => {
    const previousSearch = sessionStorage.getItem('user_search');
    if (previousSearch != null) {
      let json = JSON.parse(previousSearch);
      setEmail(json.email);
      setFirstname(json.firstname);
      setLastname(json.lastname);
    }
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value);
  };
  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };

  const searchOnPressEnter = (event) => {
    if (event.key === 'Enter') {
      sessionStorage.setItem(
        'user_search',
        JSON.stringify({
          email: email,
          firstname: firstname,
          lastname: lastname,
        }),
      );
      event.preventDefault();
      props.onSearch(email, firstname, lastname);
    }
  };

  return (
    <div className={styles.searchBar}>
      <TextField
        id="emailInput"
        className={styles.searchInput}
        placeholder={'Email'}
        type="search"
        value={email}
        onChange={handleEmailChange}
        onKeyPress={searchOnPressEnter}
      />
      <TextField
        id="firstnameInput"
        className={styles.searchInput}
        placeholder={'Firstname'}
        type="search"
        value={firstname}
        onChange={handleFirstnameChange}
        onKeyPress={searchOnPressEnter}
      />
      <TextField
        id="lastnameInput"
        className={styles.searchInput}
        placeholder={'Lastname'}
        type="search"
        value={lastname}
        onChange={handleLastnameChange}
        onKeyPress={searchOnPressEnter}
      />
    </div>
  );
}
