import HttpApi from '../http/HttpApi';
import CONSTANTS from '../../../constants';

export const JoynV1Api = new HttpApi(CONSTANTS.ENV.API_V1);

export const JoynV2Api = new HttpApi(CONSTANTS.ENV.API_V2);

export const SammyApi = new HttpApi(CONSTANTS.ENV.SAMMY_API);

export const JoynCampaignApi = new HttpApi(CONSTANTS.ENV.API_JOYN_CAMPAIGN);

export const JoynCrmApi = new HttpApi(CONSTANTS.ENV.API_JOYN_CRM);
