import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CONSTANTS from '../../../constants';
import NavigationService from '../../../shared/navigation/NavigationService';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import PartnerRepository from '../../../repository/partner/PartnerRepository';
import BackIconButton from '../../../shared/components/BackIconButton/BackIconButton';
import styles from '../Partners.module.css';
import PartnerForm from '../components/PartnerForm';

export default class PartnerCreate extends React.Component {
  state = {
    isBusy: false,
    fieldErrors: {},
    partner: {
      state: 'ACTIVE',
      integrations: [],
    },
  };

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  setFieldError = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [key]: value,
      },
    }));
  };

  updateProp = (prop, value) => {
    this.setState((prevState) => ({
      ...prevState,
      partner: {
        ...prevState.partner,
        [prop]: value,
      },
    }));
  };

  updateIntegrations = (integrations) => {
    let newState = {
      ...this.state,
      partner: {
        ...this.state.partner,
        integrations,
      },
    };
    this.setState(newState);
  };

  handleFieldChange = (prop) => (event) => {
    this.updateProp(prop, event.target.value);
    this.setFieldError(prop, null);
  };

  stateInputChanged = (event, value) => {
    this.updateProp('state', value);
    this.setFieldError('state', null);
  };

  toPartnerDetails = (id) => {
    toastr.success('Created');
    NavigationService.navigateTo(CONSTANTS.ROUTES.PARTNER_DETAILS.path, {
      partnerId: id,
    });
  };

  create = () => {
    const handleUpdateFailure = (error) => {
      const { response } = error;
      if (response.status === 409 && response.data.error) {
        toastr.error(
          response.data.error.title,
          response.data.error.description,
        );
      } else if (response.status === 400 && response.data.fieldErrors) {
        response.data.fieldErrors.forEach((fieldError) =>
          this.setFieldError(fieldError.field, fieldError.message),
        );
      } else if (response.data.errorMessage) {
        toastr.error('Could not save partner', response.data.errorMessage);
      } else {
        toastr.error('Could not save partner', error.message);
      }
    };

    PartnerRepository.create(this.state.partner)
      .then((data) => this.toPartnerDetails(data.id))
      .catch((error) => handleUpdateFailure(error));
  };

  renderPartnerForm() {
    return (
      <Paper>
        <div className={styles.cardHeader}>
          <BackIconButton linkTo={CONSTANTS.ROUTES.PARTNER_OVERVIEW.path} />
          <Typography variant="h5" className={styles.partnerName}>
            Create Partner
          </Typography>
        </div>

        <PartnerForm
          partner={this.state.partner}
          fieldErrors={this.state.fieldErrors}
          handleFieldChange={this.handleFieldChange}
          stateInputChanged={this.stateInputChanged}
          updateIntegrations={this.updateIntegrations}
          onSave={this.create}
          isCreate={true}
        />
      </Paper>
    );
  }
  render() {
    return (
      <div className={styles.partnerDetails}>
        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>
            {this.state.partner ? this.renderPartnerForm() : <NoDataFound />}
          </div>
        )}
      </div>
    );
  }
}
