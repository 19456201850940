import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { goToCampaignTarget } from '../../../../../store/navigation/campaigns/thunks';
import ModifyCampaignTargetButton from './ModifyCampaignTargetButton';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToCampaignTarget,
    },
    dispatch,
  );

class ModifyCampaignTargetButtonContainer extends React.Component {
  goToCampaignTarget = () => {
    const campaignIdFromUrl = this.props.match.params.campaignId;

    if (campaignIdFromUrl) {
      this.props.goToCampaignTarget(campaignIdFromUrl);
    } else {
      toastr.error('Error', 'Could not read campaign ID from URL');
    }
  };

  render() {
    return <ModifyCampaignTargetButton onClick={this.goToCampaignTarget} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ModifyCampaignTargetButtonContainer));
