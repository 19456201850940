import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Prompt, Redirect } from 'react-router-dom';
import { noop } from '../../../utils/utils';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import BackToCampaignOverviewButton from '../components/BackToCampaignOverviewButton/BackToCampaignOverviewButton';
import CampaignNameInputContainer from '../components/CampaignNameInput/CampaignNameInputContainer';
import CampaignLanguageDropDownContainer from '../components/CampaignLanguageDropDown/CampaignLanguageDropDownContainer';
import CampaignExternalLinkTextContainer from '../components/CampaignExternalLinkText/CampaignExternalLinkTextContainer';
import CampaignExternalLinkAddressContainer from '../components/CampaignExternalLinkAddress/CampaignExternalLinkAddressContainer';
import CampaignSubjectInputContainer from '../components/CampaignTitleInput/CampaignSubjectInputContainer';
import CampaignBodyInputContainer from '../components/CampaignBodyInput/CampaignBodyInputContainer';
import RecipientInfo from '../components/RecipientInfo/RecipientInfo';
import ModifyCampaignTargetButtonContainer from './components/ModifyCampaignTargetButton/ModifyCampaignTargetButtonContainer';
import CampaignImageUrlContainer from './components/CampaignImageUrl/CampaignImageUrlContainer';
import SendTestCampaignButton from './components/SendTestCampaignButton/SendTestCampaignButton';
import PublishCampaignButton from './components/PublishCampaignButton/PublishCampaignButton';
import styles from './CampaignDetails.module.css';

const CAMPAIGN_DETAIL_FORM_ID = 'campaignDetailsForm';

class CampaignDetails extends React.Component {
  static propTypes = {
    saveCampaign: PropTypes.func,
    isSaving: PropTypes.bool,
    saveAndSendTestCampaign: PropTypes.func,
    publishCampaign: PropTypes.func,
    isPublishing: PropTypes.bool,
    campaign: PropTypes.objectOf(PropTypes.shape),
    campaignRecipientLimit: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    campaignRecipientCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  static defaultProps = {
    saveCampaign: noop,
    isSaving: false,
    saveAndSendTestCampaign: noop,
    publishCampaign: noop,
    isPublishing: false,
    campaign: {},
    campaignRecipientLimit: 0,
    campaignRecipientCount: 0,
  };

  state = {
    initialCampaign: this.props.campaign,
    redirectTo: false,
  };

  saveCampaignAndNavigate = (nextLocation) => {
    Promise.resolve(this.props.saveCampaign()).then(() => {
      this.setState({ redirectTo: nextLocation.pathname });
    });

    return false;
  };

  saveCampaignAndUpdateInitialCampaign = () =>
    Promise.resolve(this.props.saveCampaign()).then(() => {
      this.setState({ initialCampaign: this.props.campaign });
    });

  validateForm = () => {
    const form = document.getElementById(CAMPAIGN_DETAIL_FORM_ID);
    const isValid = form && form.checkValidity();

    if (form && !isValid) {
      form.reportValidity();
    }

    return isValid;
  };

  // TODO: this should also update initial campaign?
  validateAndSendTestCampaign = () => {
    const formIsValid = this.validateForm();

    if (formIsValid) {
      this.props.saveAndSendTestCampaign();
    }
  };

  render() {
    if (this.state.redirectTo) {
      return <Redirect push to={this.state.redirectTo} />;
    }

    return (
      <div className={styles.campaignDetails}>
        {/* This prompt causes the campaign to be saved when there are unsaved changes, and the user tries to leave this page */}
        <Prompt
          when={!equals(this.state.initialCampaign, this.props.campaign)}
          message={this.saveCampaignAndNavigate}
        />

        <h1>Campaign Details</h1>

        <form id={CAMPAIGN_DETAIL_FORM_ID} autoComplete="off">
          <div className={styles.column}>
            <Paper className={styles.paper}>
              <Typography variant="h6">General Info</Typography>
              <CampaignNameInputContainer />
              <CampaignLanguageDropDownContainer />
            </Paper>

            <Paper className={styles.paper}>
              <Typography variant="h6">Feed Content</Typography>
              <CampaignSubjectInputContainer />
              <CampaignBodyInputContainer />
            </Paper>
          </div>

          <div className={styles.column}>
            <Paper className={styles.paper}>
              <Typography variant="h6">External Link (optional)</Typography>
              <CampaignExternalLinkTextContainer />
              <CampaignExternalLinkAddressContainer />
            </Paper>

            <Paper className={styles.paper}>
              <Typography variant="h6">Target</Typography>

              <HorizontalSpacer size="small" />

              {this.props.campaignRecipientCount > 0 ? (
                <RecipientInfo
                  recipientLimit={this.props.campaignRecipientLimit}
                  recipientCount={this.props.campaignRecipientCount}
                />
              ) : (
                ''
              )}

              <ModifyCampaignTargetButtonContainer />
            </Paper>
          </div>

          <div className={styles.column}>
            <Paper className={styles.paper}>
              <Typography variant="h6">Campaign Image</Typography>
              <CampaignImageUrlContainer />
            </Paper>

            {/* TODO: extract to sendTestCampaignBlock component? */}
            <Paper className={styles.paper}>
              <Typography variant="h6">Send Test Campaign</Typography>
              <HorizontalSpacer size="small" />
              <Typography variant="subtitle1">Prerequisites:</Typography>
              <Typography variant="caption">
                &bull; Make sure that you have the app installed on your device
              </Typography>
              <br />
              <Typography variant="caption">
                &bull; Make sure that you are logged in with your admin email
              </Typography>
              <HorizontalSpacer size="medium" />

              {/* TODO: use isSendingTestCampaign selector to show a spinner when the test campaign is being sent? */}
              <SendTestCampaignButton
                onClick={this.validateAndSendTestCampaign}
              />
            </Paper>
          </div>

          <HorizontalSpacer size="XL" />

          <div className={styles.buttonBar}>
            <BackToCampaignOverviewButton />

            {this.props.isSaving || this.props.isPublishing ? (
              <CircularProgress />
            ) : (
              <div className={styles.saveButtons}>
                <Button
                  id="saveButton"
                  color="primary"
                  variant="outlined"
                  type="button"
                  onClick={this.saveCampaignAndUpdateInitialCampaign}>
                  Save Draft
                </Button>

                <span>&nbsp;</span>

                <PublishCampaignButton
                  disabled={this.props.campaignRecipientCount <= 0}
                  validateForm={this.validateForm}
                  onConfirmPublish={this.props.publishCampaign}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default CampaignDetails;
