import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import PropTypes from 'prop-types';
import styles from './BackIconButton.module.css';

class BackIconButton extends React.Component {
  static propTypes = {
    linkTo: PropTypes.string,
    id: PropTypes.string,
  };

  static defaultProps = {
    linkTo: '',
    id: undefined,
  };

  render() {
    return (
      <Link to={this.props.linkTo}>
        <IconButton className={styles.iconButton} id={this.props.id}>
          <Tooltip title="Back to overview" placement="left">
            <BackIcon className={styles.icon} />
          </Tooltip>
        </IconButton>
      </Link>
    );
  }
}

export default withRouter(BackIconButton);
