import * as Sentry from '@sentry/browser';
import { SENTRY_CONFIG } from './sentryConfig';

class SentryService {
  config;

  constructor() {
    this.config = SENTRY_CONFIG;
  }

  init = () => {
    if (!this.config.dsn) {
      return;
    }

    try {
      Sentry.init(this.config);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

const sentryService = new SentryService();

export default sentryService;
