import React, { useEffect, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import UserRepository from '../../../../repository/user/UserRepository';

export default function RewardPromotionCodesView(props) {
  const [rewardPromotionCodes, setRewardPromotionCodes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalElements, setTotalElements] = React.useState(0);
  const [isBusy, setIsBusy] = React.useState(false);
  const [init, setInit] = React.useState(true);

  const loadRewardPromotionCodes = useCallback(
    (page) => {
      setIsBusy(true);
      UserRepository.getRewardPromotionCodes(props.user.id, {
        page: page,
        size: rowsPerPage,
      })
        .then((data) => {
          setRewardPromotionCodes(data.content);
          setPage(data.number);
          setRowsPerPage(data.size);
          setTotalElements(data.totalElements);
        })
        .catch((error) =>
          toastr.error('Could not load RewardPromotionCodes', error.message),
        )
        .finally(() => {
          setIsBusy(false);
          scrollToTop();
        });
    },
    [props.user.id, rowsPerPage],
  );

  const handleChangePage = (event, page) => {
    loadRewardPromotionCodes(page);
  };

  const scrollToTop = () => {
    const titleElement = document.getElementById('RewardPromotionCodesTab');
    titleElement.scrollIntoView();
  };

  useEffect(() => {
    if (init) {
      loadRewardPromotionCodes(0);
      setInit(false);
    }
  }, [init, loadRewardPromotionCodes]);

  const renderTable = () => {
    return totalElements > 0 ? (
      <Paper id="RewardPromotionCodesTab">
        <HorizontalSpacer size="small" />
        <Table id="RewardPromotionCodesTable">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Reward</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Activated on</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isBusy ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              rewardPromotionCodes.map((code) => (
                <TableRow key={code.code} hover>
                  <TableCell>{code.code}</TableCell>
                  <TableCell>{code.rewardDescription}</TableCell>
                  <TableCell>{code.status}</TableCell>
                  <TableCell>
                    {code.activationDate
                      ? moment(code.activationDate).format('DD/MM/YY HH:mm')
                      : ''}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    ) : (
      <Paper id="RewardPromotionCodesTab">
        <p>No Reward PromotionCodes found.</p>
      </Paper>
    );
  };

  return init ? <CircularProgress /> : renderTable();
}
