import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import CircularProgress from '@material-ui/core/CircularProgress';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import CONSTANTS from '../../../../constants';
import HasPermission from '../../../../shared/components/User/HasPermission';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import IconButtonWithConfirmation from '../../../../shared/components/ButtonWithConfirmation/IconButtonWithConfirmation';
import styles from './UserConsent.module.css';

export default class UserConsentView extends React.Component {
  static propTypes = {
    userConsentList: PropTypes.array,
    giveConsent: PropTypes.func,
    declineConsent: PropTypes.func,
    isBusy: PropTypes.bool,
  };

  render() {
    return (
      <div>
        {this.props.isBusy ? (
          <CircularProgress />
        ) : (
          this.renderTable(this.props.userConsentList)
        )}
      </div>
    );
  }

  renderTable(userConsentList) {
    return (
      <Paper>
        <HorizontalSpacer size="small" />
        <Table id="userConsentListTable">
          <TableHead>
            <TableRow>
              <TableCell>About</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {userConsentList.map((consent) => (
              <TableRow key={consent.about} hover>
                <TableCell>
                  {consent.about} V{consent.version}
                </TableCell>
                <TableCell>{this.getStatus(consent)}</TableCell>
                <TableCell className={styles.actionColumn}>
                  <HasPermission
                    permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
                    <div className={styles.actionBar}>
                      {this.actions(consent)}
                    </div>
                  </HasPermission>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  actions(consent) {
    switch (consent.status) {
      case 'GIVEN':
        return this.declineConsentButton(consent);
      case 'DECLINED':
        return this.giveConsentButton(consent);
      default:
        return (
          <div className={styles.actionBar}>
            {this.giveConsentButton(consent)}
            {this.declineConsentButton(consent)}
          </div>
        );
    }
  }

  declineConsentButton(consent) {
    return (
      <div className={styles.action}>
        <IconButtonWithConfirmation
          onConfirm={this.props.declineConsent}
          param={consent}
          message="Are you sure that you want to decline consent on behalf of the user?"
          title="Decline Consent"
          confirmButtonText="Decline"
          cancelButtonText="Cancel"
          id={'decline'}
          icon={<ClearIcon />}>
          Decline consent: {consent.about}
          {this.giveOrDeclineInfo()}
        </IconButtonWithConfirmation>
      </div>
    );
  }

  giveOrDeclineInfo() {
    return (
      <Alert severity={'info'} className={styles.alert}>
        If you decline the user will never receive a reminder email to decline
        or give consent. However the user can manage it in the userportal or
        app.
      </Alert>
    );
  }

  giveConsentButton(consent) {
    return (
      <div className={styles.action}>
        <IconButtonWithConfirmation
          onConfirm={this.props.giveConsent}
          param={consent}
          message="Are you sure that you want to give consent on behalf of the user?"
          title="Give Consent"
          confirmButtonText="Give"
          cancelButtonText="Cancel"
          id={'give'}
          icon={<DoneIcon />}>
          Give consent about {consent.about}
          {this.giveOrDeclineInfo()}
        </IconButtonWithConfirmation>
      </div>
    );
  }

  getStatus(consent) {
    switch (consent.status) {
      case 'GIVEN':
        return (
          <span>
            Given on{' '}
            {moment(consent.statusChangedAt).format('DD/MM/YYYY HH:mm')}
            &nbsp;({consent.origin})
          </span>
        );
      case 'DECLINED':
        return (
          <span>
            Declined on{' '}
            {moment(consent.statusChangedAt).format('DD/MM/YYYY HH:mm')}
            &nbsp;({consent.origin})
          </span>
        );
      default:
        return <span>Consent was never given</span>;
    }
  }
}
