import Keycloak from 'keycloak-js';
import { tap } from 'ramda';
import { setAuthenticatedUser } from '../../store/session/actions';
import store from '../../store/store';
import ProfileRepository from '../../repository/profile/ProfileRepository';
import { KEYCLOAK_CONFIG } from './keycloakConfig';
import AuthUser from './User';

class KeycloakClient {
  keycloak;

  constructor() {
    this.keycloak = new Keycloak(KEYCLOAK_CONFIG);
    this.logout = this.logout.bind(this);
  }

  authenticate() {
    return this.keycloak
      .init({
        onLoad: 'login-required',
        promiseType: 'native',
        enableLogging: false,
        checkLoginIframe: false,
      })
      .then(tap(() => this.storeAuthenticatedUser()));
  }

  storeAuthenticatedUser() {
    let authUser = new AuthUser(
      this.getEmail(),
      this.getName(),
      this.getGivenName(),
      this.getFamilyName(),
    );

    ProfileRepository.getProfile()
      .then((profile) => {
        authUser.setPermissions(profile.permissions);
        authUser.setRoleDescription(profile.roleDescription);
      })
      .finally(() => {
        store.dispatch(setAuthenticatedUser(authUser));
      });
  }

  refreshToken() {
    return this.keycloak.updateToken(5);
  }

  getToken() {
    return this.keycloak.token;
  }

  logout() {
    this.keycloak.logout();
  }

  getEmail() {
    return this.keycloak.idTokenParsed.email;
  }

  getName() {
    return this.keycloak.idTokenParsed.name;
  }

  getGivenName() {
    return this.keycloak.idTokenParsed.given_name;
  }

  getFamilyName() {
    return this.keycloak.idTokenParsed.family_name;
  }
}

const keycloakClient = new KeycloakClient();

export default keycloakClient;
