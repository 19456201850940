import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import UserRepository from '../../../../repository/user/UserRepository';

export default class ActivityView extends React.Component {
  state = {
    activity: [],
    page: 0,
    rowsPerPage: 2,
    totalElements: 0,
    isBusy: true,
  };

  static propTypes = {
    user: PropTypes.object,
  };

  setIsBusy = (busy) => {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  };

  handleChangePage = (event, page) => {
    return this.loadUserActivity(page);
  };

  scrollToTop = () => {
    const titleElement = document.getElementById('ActivityTab');
    titleElement.scrollIntoView();
  };

  async componentDidMount() {
    return this.loadUserActivity(0);
  }

  loadUserActivity(page) {
    this.setIsBusy(true);
    return UserRepository.getActivity(this.props.user.id, {
      page: page,
      pageSize: this.state.rowsPerPage,
    })
      .then((data) => {
        this.setState({
          activity: data.content,
          page: data.number,
          rowsPerPage: data.size,
          totalElements: data.totalElements,
        });
      })
      .catch((error) => toastr.error('Could not load activity', error.message))
      .finally(() => {
        this.setIsBusy(false);
        this.scrollToTop();
      });
  }

  render() {
    return (
      <div>
        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          this.renderTable(this.state.activity)
        )}
      </div>
    );
  }

  renderTable(activityList) {
    return activityList && activityList.length > 0 ? (
      <Paper id="ActivityTab">
        <HorizontalSpacer size="small" />
        <Table id="activityTable">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Shop</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>When</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityList.map((activity) => (
              <TableRow key={`activity_${activity.transactionId}`} hover>
                <TableCell>{activity.activityType}</TableCell>
                <TableCell>{activity.description}</TableCell>
                <TableCell>{activity.shopName}</TableCell>
                <TableCell>{activity.amountDescription}</TableCell>
                <TableCell>
                  {moment(activity.time).format('DD/MM/YY')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={this.state.totalElements}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    ) : (
      <p>No activity found.</p>
    );
  }
}
