import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'ramda';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { noop } from '../../../../../utils/utils';
import {
  SUPPORTED_TARGET_USER_TYPES,
  TARGET_USER_TYPES,
} from './constants/target-user-types';

export default class UserTypeDropDown extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOf(SUPPORTED_TARGET_USER_TYPES),
    onChange: PropTypes.func,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    id: 'userTypeDropDown',
    label: 'User Type',
    value: TARGET_USER_TYPES.ALL_USERS.key,
    onChange: noop,
    margin: 'none',
    fullWidth: false,
    required: false,
    disabled: false,
  };

  render() {
    return (
      <FormControl
        margin={this.props.margin}
        fullWidth={this.props.fullWidth}
        required={this.props.required}
        disabled={this.props.disabled}>
        <InputLabel shrink htmlFor={this.props.id}>
          {this.props.label}
        </InputLabel>
        <Select
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}
          displayEmpty>
          {values(TARGET_USER_TYPES).map((userType) => (
            <MenuItem key={userType.key} value={userType.key}>
              {userType.label}
            </MenuItem>
          ))}
        </Select>
        {this.props.disabled ? <FormHelperText>Disabled</FormHelperText> : ''}
      </FormControl>
    );
  }
}
