import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';

class Clipboard extends React.Component {
  static propTypes = {
    text: PropTypes.string,
  };

  copyToClipboard = () => {
    const tempElement = document.createElement('textarea');
    tempElement.value = this.props.text;
    tempElement.setAttribute('readonly', '');
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);

    toastr.success('Success', `Copied to clipboard`);
  };

  render() {
    if (!document.queryCommandSupported('copy')) {
      return null;
    }
    return (
      <Button
        onClick={this.copyToClipboard}
        color={'secondary'}
        startIcon={<FileCopyIcon />}
        variant={'text'}
      />
    );
  }
}

export default Clipboard;
