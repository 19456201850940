import { createSelector } from 'reselect';
import name from './name';

export const getSubState = (state) => state[name];

export const getAuthUser = createSelector(
  [getSubState],
  (state) => state.authUser,
);

export const getAuthUserEmail = createSelector(
  [getAuthUser],
  (user = {}) => user.email,
);

export const isLoadingSession = createSelector(
  [getSubState],
  (state) => state.loadingSession,
);
