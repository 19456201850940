import React from 'react';
import { toastr } from 'react-redux-toastr';
import DropDown from '../../../../../shared/components/ControlledAutocomplete/ControlledAutocomplete';
import ShopChurnReasonRepository from '../../../../../repository/churn/ChurnReasonRepository';

export default class ShopChurnReasonContainer extends React.Component {
  state = {
    options: [],
    selectedOption: undefined,
    isLoading: false,
  };

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const churnReasons = await ShopChurnReasonRepository.getChurnReasons();
      const options = churnReasons.map((reason) => ({
        key: reason.id,
        value: `${reason.key} - ${reason.description}`,
      }));
      this.updateState('options', options);

      const { selectedChurnReason } = this.props;
      if (selectedChurnReason) {
        const selectedOption = {
          key: selectedChurnReason.id,
          value: `${selectedChurnReason.key} - ${selectedChurnReason.description}`,
        };
        this.updateState('selectedOption', selectedOption);
      }
    } catch (error) {
      toastr.error('Could not load the shop churn reasons', error.message);
    } finally {
      this.updateState('isLoading', false);
    }
  }

  handleSelectChurnReason = (option) => {
    this.updateState('selectedOption', option);

    if (!option) {
      this.props.onSelectChurnReason(undefined);
      return;
    }

    const churnReason = {
      id: option.key,
      key: option.value.split(' - ')[0],
      description: option.value.split(' - ')[1],
    };
    this.props.onSelectChurnReason(churnReason);
  };

  updateState = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  render() {
    const { options, selectedOption, isLoading } = this.state;
    return (
      <>
        <h3>Shop Churn</h3>
        <DropDown
          id="churnReasonsDropDown"
          label="Churn Reasons"
          options={options}
          selectedOption={selectedOption}
          onSelect={this.handleSelectChurnReason}
          isLoading={isLoading}
        />
      </>
    );
  }
}
