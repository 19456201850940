import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { noop } from '../../../../../utils/utils';
import styles from './CampaignImageUrl.module.css';

export default class CampaignImageUrl extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    onChange: noop,
    required: false,
  };

  render() {
    return (
      <div>
        <TextField
          id="campaignImageUrlInput"
          label="Image URL"
          value={this.props.value}
          onChange={this.props.onChange}
          margin="normal"
          type="url"
          placeholder="https://"
          fullWidth
          required={this.props.required}
          helperText="The image should be an ImgIX URL and 670 x 280 px"
        />

        {this.props.value ? (
          <img
            className={styles.imagePreview}
            src={this.props.value}
            alt="Invalid URL"
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}
