import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/Button';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import DeleteIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { noop } from '../../../../../../../utils/utils';
import HorizontalSpacer from '../../../../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import WarningMessage from '../../../../../../../shared/components/messages/WarningMessage/WarningMessage';
import AgeRangeInput from './components/AgeRangeInput/AgeRangeInput';
import styles from './AgeRangeSelection.module.css';
import AgeRanges from './classes/AgeRanges';

class AgeRangeSelection extends React.Component {
  static propTypes = {
    ageRanges: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    ageRanges: [],
    onChange: noop,
  };

  state = {
    ageRanges: new AgeRanges(this.props.ageRanges),
  };

  onChangeCallback = () => this.props.onChange(this.state.ageRanges.raw);

  setAgeRanges(ageRanges) {
    this.setState({ ageRanges }, this.onChangeCallback);
  }

  addAgeRange = () => {
    this.state.ageRanges.add();

    this.setAgeRanges(this.state.ageRanges);
  };

  removeAgeRange = (ageRangeToRemove) => () => {
    this.state.ageRanges.remove(ageRangeToRemove);

    this.setAgeRanges(this.state.ageRanges);
  };

  handleAgeRangeChange = (oldAgeRange) => (updatedAgeRange) => {
    const ageRange = { ...updatedAgeRange, id: oldAgeRange.id };
    this.state.ageRanges.update(ageRange);

    this.setAgeRanges(this.state.ageRanges);
  };

  render() {
    return (
      <div>
        <WarningMessage text="By targeting on age, you will exclude recipients who have not provided their birthdate." />
        <HorizontalSpacer size="small" />

        <FormLabel component="legend">Age</FormLabel>
        {this.state.ageRanges.ranges.map((ageRange) => (
          <div className={styles.ageRangeBlock} key={ageRange.id}>
            <AgeRangeInput
              ageRange={ageRange}
              onChange={this.handleAgeRangeChange(ageRange)}
            />
            {this.state.ageRanges.ranges.length > 1 ? (
              <span className={styles.removeAgeRangeButtonWrapper}>
                <IconButton
                  id="removeAgeRangeButton"
                  onClick={this.removeAgeRange(ageRange)}
                  disableRipple>
                  <DeleteIcon />
                </IconButton>
              </span>
            ) : (
              ''
            )}
          </div>
        ))}

        <Button
          id="addAgeRangeButton"
          color="primary"
          type="button"
          variant="outlined"
          onClick={this.addAgeRange}>
          Add another range
        </Button>
      </div>
    );
  }
}

export default AgeRangeSelection;
