import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import CONSTANTS from '../../../../../constants';
import AuthUserRepository from '../../../../../repository/merchant/AuthUserRepository';
import IconButtonWithConfirmation from '../../../../../shared/components/ButtonWithConfirmation/IconButtonWithConfirmation';
import HorizontalSpacer from '../../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import ButtonWithConfirmation from '../../../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import NoDataFound from '../../../../../shared/components/NoDataFound/NoDataFound';
import styles from './AuthUserOverview.module.css';

export default class AuthUserOverview extends React.Component {
  state = {
    users: {},
    isBusy: true,
    newUserEmail: '',
  };

  static propTypes = {
    merchantId: PropTypes.number,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    merchantId: -1,
    readOnly: false,
  };

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers() {
    this.setIsBusy(true);
    AuthUserRepository.findUsers(this.props.merchantId)
      .then((data) => {
        this.setState({
          ...this.state,
          users: data,
        });
      })
      .catch((error) => toastr.error('Could not load users', error.message))
      .finally(() => {
        this.setIsBusy(false);
      });
  }

  setIsBusy = (busy) => {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  };

  addUser = () => {
    this.setIsBusy(true);
    return new Promise((resolve, reject) => {
      const request = {
        email: this.state.newUserEmail,
      };
      AuthUserRepository.createAuthUser(request, this.props.merchantId)
        .then((data) => {
          toastr.success('Success', `User ${data.email} was created`);
          this.setNewUserEmail('');
          resolve();
        })
        .catch((error) => {
          toastr.error('Could not create user', this.getError(error));
          reject();
        })
        .finally(() => {
          this.setIsBusy(false);
          this.loadUsers();
        });
    });
  };

  sendPasswordForget = (authUserId) => {
    this.setIsBusy(true);
    return new Promise((resolve, reject) => {
      AuthUserRepository.sendForgetPassword(authUserId, this.props.merchantId)
        .then((data) => {
          toastr.success('Success', `Reset password mail was sent`);
          resolve();
        })
        .catch((error) => {
          toastr.error(
            'Could not send reset password mail',
            this.getError(error),
          );
          reject();
        })
        .finally(() => {
          this.setIsBusy(false);
        });
    });
  };

  getError(error) {
    let message = '';

    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) {
          if (error.response.data.error.description) {
            return error.response.data.error.description;
          } else {
            return error.message;
          }
        } else {
          if (error.response.data.errorMessage) {
            message = error.response.data.errorMessage;
            if (error.response.data.fieldErrors) {
              error.response.data.fieldErrors.forEach((e) => {
                message += ` ${e.field}: ${e.message}`;
              });
            }
          } else {
            message = error.message;
          }
        }
      } else {
        message = error.response;
      }
    } else {
      message = error.message;
    }

    return message;
  }

  setNewUserEmail = (value) => {
    this.setState({
      ...this.state,
      newUserEmail: value,
    });
  };

  newEmailChanged = (event) => {
    this.setNewUserEmail(event.target.value);
  };

  deleteUser = (authUserId) => {
    this.setIsBusy(true);

    AuthUserRepository.deleteAuthUser(authUserId, this.props.merchantId)
      .then(() => {
        toastr.success('Success', `User was deleted`);
      })
      .catch((error) => toastr.error('Could not delete user', error.message))
      .finally(() => {
        this.setIsBusy(false);
        this.loadUsers();
      });
  };

  render() {
    return (
      <div className={styles.buttonBar}>
        {!this.props.readOnly ? this.addUserButton() : <div />}
        <Paper className={styles.tableWrapper} id="usersOverview">
          {this.state.isBusy ? (
            <CircularProgress />
          ) : this.state.users.length ? (
            this.userTable()
          ) : (
            <NoDataFound />
          )}
        </Paper>
      </div>
    );
  }

  userTable() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>LastLogin</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {user.created ? moment(user.created).format('llll') : ''}
              </TableCell>
              <TableCell>
                {user.lastLogin ? moment(user.lastLogin).format('llll') : ''}
              </TableCell>
              <TableCell className={styles.actionColumn}>
                <div className={styles.actionBar}>
                  {!this.props.readOnly ? (
                    <div className={styles.action}>
                      {this.deleteButton(user)}
                    </div>
                  ) : (
                    <div />
                  )}
                  {!this.props.readOnly ? (
                    <div className={styles.action}>
                      {this.sendPasswordForgetButton(user)}
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  addUserButton() {
    return (
      <HasPermission
        permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS_AUTH_USER]}>
        <ButtonWithConfirmation
          buttonVariant="text"
          buttonText="Add"
          onConfirm={this.addUser}
          cancelButtonText="Cancel"
          icon={<AddIcon />}
          confirmButtonText="Add"
          title="Add user"
          id="AddUser"
          message="Fill in the email of the user you like to add:">
          <TextField
            className={styles.newEmailInput}
            type="email"
            placeholder="john@doe.com"
            value={this.state.newUserEmail}
            onChange={this.newEmailChanged}
            id="newUserEmail"
          />
          <Alert severity={'info'}>
            A mail will be sent to this user to set his password
          </Alert>
        </ButtonWithConfirmation>
      </HasPermission>
    );
  }

  sendPasswordForgetButton(user) {
    return (
      <HasPermission
        permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS_AUTH_USER]}>
        <IconButtonWithConfirmation
          onConfirm={this.sendPasswordForget}
          param={user.id}
          message="Send a forgot password email"
          title="Password forget"
          confirmButtonText="Send"
          cancelButtonText="Cancel"
          id="sendPasswordForget"
          icon={<EmailIcon />}>
          {this.userInfo(user)}
          <HorizontalSpacer size={'small'} />
          <Alert severity={'info'}>
            A mail will be sent to the email of the user with instructions to
            reset the password. (no password will be reset without user
            interaction)
          </Alert>
        </IconButtonWithConfirmation>
      </HasPermission>
    );
  }

  deleteButton(user) {
    return (
      <HasPermission
        permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS_AUTH_USER]}>
        <IconButtonWithConfirmation
          onConfirm={this.deleteUser}
          param={user.id}
          message="Are you sure that you want to delete this user?"
          title="Delete"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          id={'deleteUser'}
          icon={<DeleteIcon />}>
          {this.userInfo(user)}
          {this.state.users.length === 1 ? this.lastUserWarning() : ''}
        </IconButtonWithConfirmation>
      </HasPermission>
    );
  }

  userInfo(user) {
    return (
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell className={styles.tableHeaderDialog}>Email</TableCell>
            <TableCell>{user.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.tableHeaderDialog}>
              Created on
            </TableCell>
            <TableCell>{moment(user.created).format('llll')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  lastUserWarning() {
    return (
      <div>
        <HorizontalSpacer size={'small'} />
        <Alert severity={'warning'}>
          This is the last user, if you remove it the merchant will not be able
          to login.
        </Alert>
      </div>
    );
  }
}
