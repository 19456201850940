import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import ActionsMenu from '../../../../../shared/components/ActionsMenu/ActionsMenu';
import NavigationService from '../../../../../shared/navigation/NavigationService';
import CONSTANTS from '../../../../../constants';
import MerchantRepository from '../../../../../repository/merchant/MerchantRepository';
import ConfirmedMenuItem from '../../../../../shared/components/ConfirmedMenuItem/ConfirmedMenuItem';
import { noop } from '../../../../../utils/utils';

export default class MerchantDetailActionMenu extends React.Component {
  static propTypes = {
    merchantId: PropTypes.number,
    canSendRegistrationEmail: PropTypes.bool,
    canRestoreMerchant: PropTypes.bool,
    canDeleteMerchant: PropTypes.bool,
    onStateChange: PropTypes.func,
  };

  static defaultProps = {
    merchantId: 0,
    canSendRegistrationEmail: false,
    canRestoreMerchant: false,
    canDeleteMerchant: false,
    onStateChange: noop,
  };

  navigateToMerchantUpdatePage = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.MERCHANT_UPDATE.path, {
      merchantId: this.props.merchantId,
    });
  };

  sendRegistrationEmail = () => {
    return MerchantRepository.sendRegistrationEmail(this.props.merchantId);
  };

  restoreMerchant = () => {
    return MerchantRepository.restore(this.props.merchantId);
  };

  deleteMerchant = () => {
    return MerchantRepository.delete(this.props.merchantId);
  };

  render() {
    return (
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}>
        <ActionsMenu>
          <HasPermission
            permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}>
            <MenuItem onClick={this.navigateToMerchantUpdatePage}>
              <ListItemIcon>{<EditIcon />}</ListItemIcon>
              <ListItemText>Edit details</ListItemText>
            </MenuItem>
          </HasPermission>

          <HasPermission
            permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}>
            {this.props.canSendRegistrationEmail ? (
              <ConfirmedMenuItem
                action={this.sendRegistrationEmail}
                label="Send registration email"
                successMessage="A registration email has been sent"
                confirmationTitle="Send an email?"
                confirmationContent="Are you sure you want to send a registration email to this merchant?"
                icon={<EmailIcon />}
              />
            ) : null}
          </HasPermission>

          <HasPermission
            permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}>
            {this.props.canRestoreMerchant ? (
              <ConfirmedMenuItem
                action={this.restoreMerchant}
                successAction={this.props.onStateChange}
                label="Restore merchant"
                successMessage="The merchant has been restored"
                confirmationTitle="Restore this merchant?"
                confirmationContent="Are you sure you want to restore this merchant?"
                icon={<UndoIcon />}
              />
            ) : null}
          </HasPermission>

          <HasPermission
            permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}>
            {this.props.canDeleteMerchant ? (
              <ConfirmedMenuItem
                action={this.deleteMerchant}
                successAction={this.props.onStateChange}
                label="Delete merchant"
                successMessage="The merchant has been deleted"
                confirmationTitle="Delete this merchant?"
                confirmationContent="Are you sure you want to delete this merchant?"
                icon={<DeleteIcon />}
              />
            ) : null}
          </HasPermission>
        </ActionsMenu>
      </HasPermission>
    );
  }
}
