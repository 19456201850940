import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadJoynMessageCampaigns } from '../../../../../store/campaigns/thunks';
import {
  getJoynMessageCampaigns,
  getJoynMessageCampaignsPageNumber,
  getJoynMessageCampaignsPageSize,
  getJoynMessageCampaignsTotalElements,
  isFetchingJoynMessageCampaigns,
} from '../../../../../store/campaigns/selectors';
import JoynMessageCampaignOverview from './JoynMessageCampaignOverview';

const mapStateToProps = (state) => ({
  campaigns: getJoynMessageCampaigns(state),
  campaignsPage: getJoynMessageCampaignsPageNumber(state),
  campaignsPageSize: getJoynMessageCampaignsPageSize(state),
  campaignsTotalElements: getJoynMessageCampaignsTotalElements(state),
  isFetching: isFetchingJoynMessageCampaigns(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadJoynMessageCampaigns,
    },
    dispatch,
  );

class JoynMessageCampaignOverviewContainer extends React.Component {
  componentDidMount() {
    this.props.loadJoynMessageCampaigns(0);
  }

  render() {
    return (
      <JoynMessageCampaignOverview
        campaigns={this.props.campaigns}
        onPageChange={this.props.loadJoynMessageCampaigns}
        page={this.props.campaignsPage}
        rowsPerPage={this.props.campaignsPageSize}
        totalElements={this.props.campaignsTotalElements}
        isFetching={this.props.isFetching}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JoynMessageCampaignOverviewContainer);
