import React from 'react';
import Button from '@material-ui/core/Button';

export default function SelectCouponButton(props) {
  const handleOnClick = () => props.onClick(props.coupon);

  return (
    <Button id="selectCouponButton" color="primary" onClick={handleOnClick}>
      Select Coupon
    </Button>
  );
}
