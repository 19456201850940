import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import CampaignCreatorRepository from '../../../../repository/campaignCreator/CampaignCreatorRepository';

export default class UserCampaignsView extends React.Component {
  state = {
    isBusy: true,
  };

  static propTypes = {
    user: PropTypes.object,
  };

  setIsBusy = (busy) => {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  };

  loadUserCampaigns() {
    this.setIsBusy(true);
    return CampaignCreatorRepository.getUserCampaigns(this.props.user.email)
      .then((data) => {
        this.setState({
          campaigns: data,
        });
      })
      .catch((error) =>
        toastr.error('Could not load user campaigns', error.message),
      )
      .finally(() => {
        this.setIsBusy(false);
      });
  }

  async componentDidMount() {
    return this.loadUserCampaigns(0);
  }

  renderTable(campaigns) {
    return (
      <Paper>
        <HorizontalSpacer size="small" />
        <Table id="visitedShopsTable">
          <TableHead>
            <TableRow>
              <TableCell>Campaign</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Shops</TableCell>
              <TableCell>Sent on</TableCell>
              <TableCell>Opened on</TableCell>
              <TableCell>Bounced</TableCell>
              <TableCell>Activated on</TableCell>
              <TableCell>Redeemed on</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign) => (
              <TableRow key={campaign.shopId} hover>
                <TableCell>{campaign.name}</TableCell>
                <TableCell>{campaign.type}</TableCell>
                <TableCell>
                  {campaign.shops
                    ? this.wrapped(campaign.shops.join(', '))
                    : ''}
                </TableCell>
                <TableCell>{this.formatDate(campaign.emailSentOn)}</TableCell>
                <TableCell>{this.formatDate(campaign.emailOpenedOn)}</TableCell>
                <TableCell>{this.formatDate(campaign.bouncedOn)} </TableCell>
                <TableCell>
                  {this.formatDate(campaign.couponActivatedOn)}
                </TableCell>
                <TableCell>
                  {this.formatDate(campaign.couponRedeemedOn)}{' '}
                  {this.wrapped(campaign.redeemedInShop)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  formatDate(date) {
    return date ? moment(date).format('DD/MM/YYYY HH:mm') : '';
  }

  wrapped(data) {
    return data && data.length > 15 ? (
      <Tooltip title={data} placement={'top'}>
        <div>{data.substring(0, 12) + '...'}</div>
      </Tooltip>
    ) : (
      data
    );
  }

  render() {
    return (
      <div>
        {this.props.isBusy ? (
          <CircularProgress />
        ) : this.state.campaigns && this.state.campaigns.length > 0 ? (
          <div>{this.renderTable(this.state.campaigns)}</div>
        ) : (
          <p>No campaigns found.</p>
        )}
      </div>
    );
  }
}
