import React from 'react';
import { path } from 'ramda';
import PartnerDetails from './PartnerDetails';

export default class PartnerDetailsContainer extends React.Component {
  render() {
    const idFromUrl = path(['match', 'params', 'partnerId'], this.props);
    return idFromUrl ? <PartnerDetails partnerId={idFromUrl} /> : '';
  }
}
