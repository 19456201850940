import React from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { getAuthUser } from '../../../store/session/selectors';

const mapStateToProps = (state) => ({
  authUser: getAuthUser(state),
});

class CurrentUserAvatar extends React.Component {
  render() {
    return (
      <>
        {this.props.authUser ? (
          <Avatar
            component={'span'}
            title={`${this.props.authUser.name} - ${this.props.authUser.roleDescription}`}>
            {this.props.authUser.getInitials()}
          </Avatar>
        ) : (
          <span>?</span>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(CurrentUserAvatar);
