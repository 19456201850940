import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import MainContent from './MainContent/MainContent';
import NavigationContainer from './Navigation/NavigationContainer';
import VersionInfo from './VersionInfo/VersionInfo';
import styles from './Shell.module.css';

export default class Shell extends React.Component {
  render() {
    return (
      <div className={styles.shell}>
        <NavigationContainer />
        <MainContent />
        <VersionInfo />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
        />
      </div>
    );
  }
}
