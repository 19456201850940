import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CONSTANTS from '../../../../../../constants';
import styles from './CancelCampaignCreationButton.module.css';

class CancelCampaignCreationButton extends React.Component {
  render() {
    return (
      <Link
        to={CONSTANTS.ROUTES.CAMPAIGN_OVERVIEW.path}
        className={styles.link}>
        <Button id="cancelButton" aria-label="Cancel">
          Cancel
        </Button>
      </Link>
    );
  }
}

export default CancelCampaignCreationButton;
