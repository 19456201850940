import React from 'react';
import PropTypes from 'prop-types';

export default class BooleanToString extends React.Component {
  static propTypes = {
    bool: PropTypes.bool,
  };

  static defaultProps = {
    bool: false,
  };

  render() {
    return this.props.bool ? 'Yes' : 'No';
  }
}
