import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { noop } from '../../../../../utils/utils';
import HorizontalSpacer from '../../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import styles from './ZipCodeManager.module.css';

class ZipCodeManager extends React.Component {
  static propTypes = {
    zipCodes: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    zipCodes: [],
    onChange: noop,
  };

  state = {
    zipCode: '',
    zipCodes: this.props.zipCodes,
  };

  onChangeCallback = () =>
    this.props.onChange({ zipCodes: this.state.zipCodes });

  setStateWithCallBack = (state) => {
    this.setState(state, this.onChangeCallback);
  };

  addZipCode = () => {
    const newZipCode = (this.state.zipCode || '').trim();

    if (newZipCode && !this.state.zipCodes.includes(newZipCode)) {
      this.setStateWithCallBack({
        zipCodes: this.state.zipCodes.concat(newZipCode),
      });
    }

    this.setState({ zipCode: '' });
  };

  removeZipCode = (zipCodeToRemove) => () => {
    this.setStateWithCallBack({
      zipCodes: this.state.zipCodes.filter(
        (zipCode) => zipCode !== zipCodeToRemove,
      ),
    });
  };

  addZipCodeOnPressEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.addZipCode();
    }
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  render() {
    return (
      <div>
        <TextField
          id="zipCodeInput"
          label="Postal Code"
          value={this.state.zipCode}
          onChange={this.handleChange('zipCode')}
          margin="normal"
          onKeyPress={this.addZipCodeOnPressEnter}
          helperText="Use ⏎ to quickly add a postal code"
        />

        <Button
          id="addZipCodeButton"
          color="primary"
          variant="outlined"
          type="button"
          className={styles.addButton}
          disabled={!this.state.zipCode}
          onClick={this.addZipCode}>
          Add
        </Button>

        <HorizontalSpacer size="small" />

        {this.state.zipCodes.length ? (
          <div>
            <Typography variant="caption">Selected postal codes</Typography>
            {this.state.zipCodes.map((zipCode) => (
              <Chip
                id={`zipCode${zipCode}`}
                key={zipCode}
                label={zipCode}
                onDelete={this.removeZipCode(zipCode)}
                className={styles.chip}
              />
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default ZipCodeManager;
