import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';
import { noop } from '../../../../../utils/utils';
import HorizontalSpacer from '../../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import styles from './UserChangeEmailCard.module.css';

const VALID_EMAIL_PATTERN = /^.*@.*\..*$/;
const EMAIL_INPUT_ID = 'emailInput';

export default class UserChangeEmailCard extends React.Component {
  static propTypes = {
    email: PropTypes.string,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    email: '',
    onSave: noop,
    onCancel: noop,
  };

  state = {
    email: this.props.email,
  };

  validateAndSubmit = (event) => {
    event.preventDefault();
    const isEmailValid = VALID_EMAIL_PATTERN.test(this.state.email);

    if (!isEmailValid) {
      const emailInput = document.getElementById(EMAIL_INPUT_ID);
      emailInput.setCustomValidity('Please enter a valid email address');
      return emailInput.reportValidity();
    }

    return this.props.onSave(this.state.email);
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  render() {
    return (
      <Paper className={styles.userChangeEmailCard}>
        <form
          id="emailForm"
          autoComplete="off"
          onSubmit={this.validateAndSubmit}>
          <TextField
            id={EMAIL_INPUT_ID}
            label="New Email Address"
            type="text"
            value={this.state.email}
            onChange={this.handleChange('email')}
            margin="normal"
            fullWidth
            required
            inputProps={{
              onInput: (event) => {
                event.target.setCustomValidity('');
              },
            }}
          />

          <HorizontalSpacer size="large" />

          <Button
            id="cancelButton"
            aria-label="Cancel"
            type="button"
            onClick={this.props.onCancel}>
            Cancel
          </Button>

          <span>&nbsp;</span>

          <Button
            id="saveButton"
            variant="contained"
            color="primary"
            type="submit">
            Save Changes
          </Button>
        </form>
      </Paper>
    );
  }
}
