import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StepContent } from '@material-ui/core';
import styles from './MergeUsers.module.css';
import UserSearch from './UserSearch/UserSearch';
import MergeConfirmation from './MergeConfirmation/MergeConfirmation';

function getSteps() {
  return [
    'Select User or Registration request to merge from',
    'Select user to merge to',
    'Confirm',
  ];
}

export default function MergeUsers() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [result1, setResult1] = React.useState({});
  const [result2, setResult2] = React.useState({});
  const [searchTerm1, setSearchTerm1] = React.useState(null);
  const [searchTerm2, setSearchTerm2] = React.useState(null);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    switch (activeStep) {
      case 1:
        setResult1({});
        break;
      case 2:
        setResult2({});
        break;
      default:
    }
  };

  const resetForm = () => {
    setSearchTerm1(null);
    setSearchTerm2(null);
    setResult1({});
    setResult2({});
    setActiveStep(0);
  };

  const selectResult1 = (result, searchTerm) => {
    setResult1(result);
    setSearchTerm1(searchTerm);
    handleNext();
  };

  const selectResult2 = (result, searchTerm) => {
    setResult2(result);
    setSearchTerm2(searchTerm);
    handleNext();
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <UserSearch
            selectUser={selectResult1}
            searchTerm={searchTerm1}
            includeRegReq={true}
          />
        );
      case 1:
        return (
          <UserSearch
            selectUser={selectResult2}
            searchTerm={searchTerm2}
            includeRegReq={false}
          />
        );
      case 2:
        return (
          <MergeConfirmation
            selection1={result1}
            selection2={result2}
            onConfirm={resetForm}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const stepLabel = (label, step) => {
    switch (step) {
      case 0:
        return result1.type
          ? `From : ${result1.email} (${result1.type})`
          : label;
      case 1:
        return result2.email ? `To : ${result2.email}` : label;
      case 2:
        return label;
      default:
        return 'Unknown step';
    }
  };

  return (
    <div>
      <div className={styles.stepperRoot}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{stepLabel(label, index)}</StepLabel>
                <StepContent>
                  <div>
                    <div>
                      <Typography component={'span'}>
                        {getStepContent(activeStep)}
                      </Typography>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}>
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>{' '}
    </div>
  );
}
