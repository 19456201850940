/* eslint-disable no-param-reassign */
import { toastr } from 'react-redux-toastr';
import { path, tap } from 'ramda';
import { wrapThunkWithLifecycle } from 'redux-thunk-lifecycle/src/helpers/wrap-thunk-with-lifecycle';
import UserRepository from '../../repository/user/UserRepository';
import { goToUserDetails } from '../navigation/users/thunks';
import {
  GET_USER,
  GET_USER_PARTNER_BALANCES,
  UPDATE_USER_EMAIL,
} from './action-types';
import { getUserId } from './selectors';
import ERRORS from './thunkErrorCodes';

class Thunks {
  loadUserById(userId) {
    return () =>
      UserRepository.getUser(userId).catch((error) => {
        error.title = 'Could not fetch user:';
        return Promise.reject(error);
      });
  }

  changeUserEmail(email) {
    return (dispatch, getState) => {
      const userId = getUserId(getState());

      const showSuccessMessage = () =>
        toastr.success('Email changed', 'Email Address changed successfully');

      const mapUpdateEmailErrorResponse = (error) => {
        const errorResponseMessage = path(
          ['response', 'data', 'errorMessage'],
          error,
        );

        const customErrorMessage =
          ERRORS[UPDATE_USER_EMAIL][errorResponseMessage];
        error.message = customErrorMessage || error.message;

        error.title = 'Could not update email:';

        return error;
      };

      return UserRepository.updateUserEmail(userId, email)
        .then(tap(() => dispatch(goToUserDetails(userId))))
        .then(tap(showSuccessMessage))
        .catch((error) => Promise.reject(mapUpdateEmailErrorResponse(error)));
    };
  }

  loadUserPartnerBalancesById(userId) {
    return () =>
      UserRepository.getPartnerBalances(userId).catch((error) => {
        error.title = 'Could not fetch partner balance:';
        return Promise.reject(error);
      });
  }
}

const thunks = new Thunks();

thunks.loadUserById = wrapThunkWithLifecycle(
  GET_USER,
  thunks.loadUserById,
  thunks,
);
thunks.changeUserEmail = wrapThunkWithLifecycle(
  UPDATE_USER_EMAIL,
  thunks.changeUserEmail,
  thunks,
);
thunks.loadUserPartnerBalancesById = wrapThunkWithLifecycle(
  GET_USER_PARTNER_BALANCES,
  thunks.loadUserPartnerBalancesById,
  thunks,
);

export default thunks;
export const {
  loadUserById,
  changeUserEmail,
  loadUserPartnerBalancesById,
} = thunks;
