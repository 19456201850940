import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { generatePath } from 'react-router-dom';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import SearchBar from '../../../shared/components/SearchBar/SearchBar';
import UserRepository from '../../../repository/user/UserRepository';
import CONSTANTS from '../../../constants';
import styles from '../PointsCoupons.module.css';
import SelectUserButton from './SelectUserButton';

export default function UserSearch(props) {
  const [user, setUser] = React.useState({});
  const [busy, setBusy] = React.useState(false);
  const [init, setInit] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(props.searchTerm);

  useEffect(() => {
    if (!init && searchTerm) {
      setInit(true);
      searchUsers(searchTerm);
    }
  }, [init, searchTerm]);

  const searchUsers = (searchTerm) => {
    setBusy(true);
    setSearchTerm(searchTerm);

    UserRepository.getUsers({
      email: searchTerm,
      userId: searchTerm,
      page_size: 2,
    })
      .then((data) => {
        if (data.content.length > 0) {
          setUser(data.content[0]);
        } else {
          setUser({});
        }
      })
      .catch((error) => toastr.error('Could not load users', error.message))
      .finally(() => {
        setBusy(false);
      });
  };

  const usersTable =
    user != null && user.mail != null ? (
      <div>
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={styles.tableCell}>Id</TableCell>
                <TableCell className={styles.tableCell}>
                  <a
                    className="MuiButtonBase-root MuiButton-textPrimary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={generatePath(CONSTANTS.ROUTES.USER_DETAILS.path, {
                      userId: user.usrId,
                    })}>
                    <>{user.usrId}</>
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.tableCell}>Email</TableCell>
                <TableCell className={styles.tableCell}>{user.mail} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.tableCell}>Name</TableCell>
                <TableCell className={styles.tableCell}>
                  {user.firstName} {user.lastName}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div>
          <SelectUserButton
            user={user}
            onClick={props.selectUser}
            searchTerm={searchTerm}
          />
        </div>
      </div>
    ) : (
      <div>
        <div>No user found</div>
        <HorizontalSpacer />
      </div>
    );

  return (
    <Paper elevation={3}>
      <div>
        <SearchBar
          placeholder="Search by email or id"
          onSearch={searchUsers}
          searchTerm={searchTerm ? searchTerm : ''}
        />
      </div>
      <HorizontalSpacer />
      {busy ? <CircularProgress /> : <div>{usersTable}</div>}
    </Paper>
  );
}
