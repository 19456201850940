import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { goToUserDetails } from '../../../../../store/navigation/users/thunks';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToUserDetails,
    },
    dispatch,
  );

class GoToUserDetailsButton extends React.Component {
  static propTypes = {
    userId: PropTypes.number,
  };

  static defaultProps = {
    userId: 0,
  };

  handleOnClick = () => this.props.goToUserDetails(this.props.userId);

  render() {
    return (
      <Button
        id="goToUserDetailsButton"
        color="secondary"
        onClick={this.handleOnClick}>
        Details
      </Button>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoToUserDetailsButton);
