export default class AgeRange {
  from = 0;
  to = 160;
  id;

  constructor({ from = 0, to = 160, id = Math.random() } = {}) {
    this.from = from;
    this.to = to;
    this.id = id;
  }

  get raw() {
    return {
      from: this.from,
      to: this.to,
    };
  }
}
