import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { patchCampaign } from '../../../../../store/campaigns/actions';
import { getCampaignType } from '../../../../../store/campaigns/selectors';
import CampaignTypeDropDown from './CampaignTypeDropDown';

const mapStateToProps = (state) => ({
  campaignType: getCampaignType(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchCampaign,
    },
    dispatch,
  );

class CampaignTypeDropDownContainer extends React.Component {
  handleChange = (event) => {
    this.props.patchCampaign({ type: event.target.value });
  };

  render() {
    return (
      <CampaignTypeDropDown
        value={this.props.campaignType}
        onChange={this.handleChange}
        margin="normal"
        fullWidth
        required
        disabled
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignTypeDropDownContainer);
