import { createSelector } from 'reselect';
import { createLoadingSelector } from '../lifecycle/selectors';
import name from './name';
import {
  GET_USER,
  GET_USER_PARTNER_BALANCES,
  UPDATE_USER_EMAIL,
} from './action-types';

export const getSubState = (state) => state[name];

export const getUser = createSelector([getSubState], (state) => state.user);

export const getUserId = createSelector([getUser], (user = {}) => user.id);

export const getUserFirstName = createSelector(
  [getUser],
  (user = {}) => user.firstName,
);

export const getUserLastName = createSelector(
  [getUser],
  (user = {}) => user.lastName,
);

export const getUserGender = createSelector(
  [getUser],
  (user = {}) => user.gender,
);

export const getUserEmail = createSelector(
  [getUser],
  (user = {}) => user.email,
);

export const getUserBirthDate = createSelector(
  [getUser],
  (user = {}) => user.birthday,
);

export const getUserIntegrations = createSelector(
  [getUser],
  (user = {}) => user.integrations,
);

export const getPartnerBalances = createSelector(
  [getUserIntegrations],
  (userIntegrations = {}) => userIntegrations.partnerBalances,
);

export const getActivePaymentTypes = createSelector(
  [getPartnerBalances],
  (partnerBalances = []) =>
    partnerBalances.flatMap((partnerBalance) =>
      partnerBalance.balances.map((balance) => {
        return {
          type: balance.type,
          description: balance.description,
          icon: balance.icon,
          backgroundColor: balance.color,
          fontColor: balance.fontColor,
          partner: partnerBalance.partner,
        };
      }),
    ),
);

export const isFetchingUser = createLoadingSelector([GET_USER]);
export const isUpdatingEmail = createLoadingSelector([UPDATE_USER_EMAIL]);
export const isFetchingPartnerBalances = createLoadingSelector([
  GET_USER_PARTNER_BALANCES,
]);
