import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import CategoryRepository from '../../repository/categories/CategoryRepository';
import { noop } from '../../utils/utils';
import styles from './CategoriesOverview.module.css';

export default class SubCategories extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.element,
  };

  static defaultProps = {
    value: '',
    onChange: noop,
  };

  state = {
    isBusy: false,
    subCategories: [],
  };

  componentDidMount() {
    this.loadSubCategories();
  }

  loadSubCategories = () => {
    this.setState({
      ...this.state,
      isBusy: true,
    });
    CategoryRepository.getSubCategories().then((data) => {
      this.setState({
        ...this.state,
        subCategories: data,
        isBusy: false,
      });
    });
  };

  render() {
    return (
      <>
        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <FormControl margin="normal" fullWidth>
            <InputLabel>Subcategory</InputLabel>
            <Select
              id="sub-category"
              value={this.props.value}
              onChange={this.props.onChange}
              error={this.props.error}
              className={styles.subCategoryItem}>
              {this.state.subCategories.map((c) => (
                <MenuItem value={c.id}>{c.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </>
    );
  }
}
