import React from 'react';
import { path } from 'ramda';
import MerchantDetails from './MerchantDetails';

export default class MerchantDetailsContainer extends React.Component {
  render() {
    const merchantIdFromUrl = path(
      ['match', 'params', 'merchantId'],
      this.props,
    );
    const merchantId = parseInt(merchantIdFromUrl, 10);

    return merchantIdFromUrl ? <MerchantDetails merchantId={merchantId} /> : '';
  }
}
