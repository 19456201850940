import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styles from './LinkButton.module.css';

class LinkButton extends React.Component {
  static propTypes = {
    linkTo: PropTypes.string,
    text: PropTypes.string,
  };

  static defaultProps = {
    linkTo: '',
    text: 'Details',
  };

  render() {
    return (
      <Link to={this.props.linkTo} className={styles.link}>
        <Button color="secondary">{this.props.text}</Button>
      </Link>
    );
  }
}

export default withRouter(LinkButton);
