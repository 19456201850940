import initialState from './state';
import { SET_AUTH_USER, SET_LOADING_SESSION } from './action-types';

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        authUser: action.authUser,
      };
    case SET_LOADING_SESSION:
      return {
        ...state,
        loadingSession: action.loadingSession,
      };
    default:
      return state;
  }
}

export default reducer;
