import React from 'react';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import CONSTANTS from '../../../constants';
import HasPermission from '../../../shared/components/User/HasPermission';
import ButtonWithConfirmation from '../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import UserRepository from '../../../repository/user/UserRepository';

export default function AwardConfirmation(props) {
  const [busy, setBusy] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);

  const handleConfirmation = () => {
    setBusy(true);

    UserRepository.giveAward(props.user.usrId, {
      shopId: props.shop.id,
      awardType: props.award.type,
      awardValue: props.award.value,
      sendCouponMail: props.award.sendCouponMail,
      subject: props.award.subject,
      content: props.award.content,
    })
      .then((data) => {
        setConfirmed(true);
        toastr.success('Done', data.successMessage);
      })
      .catch((error) => toastr.error('Could not perform action', error.message))
      .finally(() => {
        setBusy(false);
      });
  };

  const confirmationButton = (
    <div>
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
        <ButtonWithConfirmation
          onConfirm={handleConfirmation}
          title="Confirm the selection"
          message=""
          confirmButtonText="Confirm"
          buttonText="Confirm"
          cancelButtonText="Cancel"
          buttonVariant="contained"
          id={'confirmAward'}>
          <div>User: {props.user.mail}</div>
          <div>Shop: {props.shop.name}</div>
          <div>{props.award.description}</div>
        </ButtonWithConfirmation>
      </HasPermission>
    </div>
  );
  if (busy) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  if (confirmed) {
    return <div>Action confirmed</div>;
  }
  return confirmationButton;
}
