import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import CONSTANTS from '../../../../constants';
import HasPermission from '../../../../shared/components/User/HasPermission';
import { noop } from '../../../../utils/utils';
import ActionsMenu from '../../../../shared/components/ActionsMenu/ActionsMenu';
import MenuItemWithConfirmation from '../../../../shared/components/MenuItemWithConfirmation/MenuItemWithConfirmation';
import styles from './UserActionsMenu.module.css';

class UserActionsMenu extends React.Component {
  static propTypes = {
    changeEmail: PropTypes.func,
    editDetails: PropTypes.func,
    restoreUser: PropTypes.func,
    deleteUser: PropTypes.func,
    deleted: PropTypes.bool,
  };

  static defaultProps = {
    changeEmail: noop,
    editDetails: noop,
    restoreUser: noop,
    deleteUser: noop,
    deleted: false,
  };

  render() {
    return (
      <div className={styles.userActionsMenu}>
        <HasPermission
          permissions={[
            CONSTANTS.PERMISSIONS.OP_UPDATE_USERS,
            CONSTANTS.PERMISSIONS.OP_CHANGE_USER_EMAIL,
          ]}>
          <ActionsMenu>
            <HasPermission
              permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
              <MenuItem onClick={this.props.editDetails}>Edit details</MenuItem>
            </HasPermission>
            <HasPermission
              permissions={[CONSTANTS.PERMISSIONS.OP_CHANGE_USER_EMAIL]}>
              <MenuItem onClick={this.props.changeEmail}>
                Change email address
              </MenuItem>
            </HasPermission>
            {this.props.deleted ? (
              <HasPermission
                permissions={[CONSTANTS.PERMISSIONS.OP_RESTORE_USER]}>
                <MenuItemWithConfirmation
                  onConfirm={this.props.restoreUser}
                  message="Please confirm"
                  title="Restore user"
                  buttonText="Restore User"
                  confirmButtonText="Restore"
                  cancelButtonText="Cancel"
                />
              </HasPermission>
            ) : (
              <HasPermission
                permissions={[CONSTANTS.PERMISSIONS.OP_DELETE_USER]}>
                <MenuItemWithConfirmation
                  onConfirm={this.props.deleteUser}
                  message="Please confirm"
                  title="Delete user"
                  buttonText="Delete User"
                  confirmButtonText="Delete"
                  cancelButtonText="Cancel"
                />
              </HasPermission>
            )}
          </ActionsMenu>
        </HasPermission>
      </div>
    );
  }
}

export default UserActionsMenu;
