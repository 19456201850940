import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CONSTANTS from '../../../../constants';
import styles from './BackToCampaignOverviewButton.module.css';

class BackToCampaignOverviewButton extends React.Component {
  static propTypes = {
    align: PropTypes.oneOf(['none', 'left', 'right']),
  };

  static defaultProps = {
    align: 'none',
  };

  render() {
    return (
      <Link
        to={CONSTANTS.ROUTES.CAMPAIGN_OVERVIEW.path}
        className={styles.link}>
        <Button
          id="backToOverviewButton"
          color="secondary"
          variant="outlined"
          type="button"
          className={styles[this.props.align]}>
          Back to overview
        </Button>
      </Link>
    );
  }
}

export default BackToCampaignOverviewButton;
