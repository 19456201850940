import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  loadCampaignById,
  updateCampaignFromStore,
} from '../../../store/campaigns/thunks';
import {
  getCampaignTargetRecipientCount,
  getCampaignTargetRecipientLimit,
  getCampaignTargetZipCodes,
  getCampaignTargetGender,
  getCampaignTargetAgeRanges,
  isLoadingCampaign,
  isUpdatingCampaign,
  getCampaignTargetUserType,
  getCampaignTargetLanguages,
  getCampaignTargetUserAppUsagePeriod,
} from '../../../store/campaigns/selectors';
import { patchCampaign, resetCampaign } from '../../../store/campaigns/actions';
import CampaignTarget from './CampaignTarget';
import styles from './CampaignTarget.module.css';

const mapStateToProps = (state) => ({
  recipientLimit: getCampaignTargetRecipientLimit(state),
  recipientCount: getCampaignTargetRecipientCount(state),
  campaignTargetZipCodes: getCampaignTargetZipCodes(state),
  campaignTargetLanguages: getCampaignTargetLanguages(state),
  campaignTargetGender: getCampaignTargetGender(state),
  campaignTargetAgeRanges: getCampaignTargetAgeRanges(state),
  campaignTargetUserType: getCampaignTargetUserType(state),
  campaignTargetUserAppUsagePeriod: getCampaignTargetUserAppUsagePeriod(state),
  isLoadingCampaign: isLoadingCampaign(state),
  isUpdatingCampaign: isUpdatingCampaign(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetCampaign,
      patchCampaign,
      loadCampaignById,
      updateCampaignFromStore,
    },
    dispatch,
  );

class CampaignTargetContainer extends React.Component {
  componentDidMount() {
    const campaignIdFromUrl = this.props.match.params.campaignId;
    this.props.loadCampaignById(campaignIdFromUrl);
  }

  componentWillUnmount() {
    this.props.resetCampaign();
  }

  updateCampaign = (filters) => {
    this.props.patchCampaign({ targetFilter: filters });
    this.props.updateCampaignFromStore();
  };

  render() {
    return this.props.isLoadingCampaign ? (
      <CircularProgress className={styles.containerSpinner} />
    ) : (
      <CampaignTarget
        onFilterChange={this.updateCampaign}
        recipientLimit={this.props.recipientLimit}
        recipientCount={this.props.recipientCount}
        zipCodes={this.props.campaignTargetZipCodes}
        languages={this.props.campaignTargetLanguages}
        gender={this.props.campaignTargetGender}
        ageRanges={this.props.campaignTargetAgeRanges}
        userType={this.props.campaignTargetUserType}
        userAppUsagePeriod={this.props.campaignTargetUserAppUsagePeriod}
        isLoadingRecipientCount={this.props.isUpdatingCampaign}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignTargetContainer);
