import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { noop } from '../../../utils/utils';

export default class ConfirmationDialog extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    dialogTitle: PropTypes.string,
    dialogContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    icon: PropTypes.element,
  };

  static defaultProps = {
    isOpen: false,
    dialogTitle: '',
    dialogContent: 'Are you sure?',
    onConfirm: noop,
    onClose: noop,
    onCancel: noop,
    confirmButtonText: 'Yes, do the thing',
    cancelButtonText: 'Nope',
    icon: <HelpOutlineIcon />,
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {this.props.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="cancelButton"
            onClick={this.props.onCancel}
            color="primary">
            {this.props.cancelButtonText}
          </Button>
          <Button
            id="confirmButton"
            onClick={this.props.onConfirm}
            color="primary"
            autoFocus>
            {this.props.icon}
            {this.props.confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
