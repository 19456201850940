import React from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { path } from 'ramda';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import BackIconButton from '../../shared/components/BackIconButton/BackIconButton';
import CONSTANTS from '../../constants';
import HorizontalSpacer from '../../shared/components/HorizontalSpacer/HorizontalSpacer';
import NoDataFound from '../../shared/components/NoDataFound/NoDataFound';
import CategoryRepository from '../../repository/categories/CategoryRepository';
import EntityForm from '../../shared/components/EntityForm/EntityForm';
import NavigationService from '../../shared/navigation/NavigationService';
import ButtonWithConfirmation from '../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import SubCategories from './SubCategories';
import styles from './CategoriesOverview.module.css';
import CategoryImageSuggestions from './CategorySuggestions';

class CategoryDetail extends React.Component {
  state = {
    isBusy: false,
    category: {},
    subCategories: [],
    fieldErrors: {},
  };

  componentDidMount() {
    const categoryId = path(['match', 'params', 'categoryId'], this.props);

    if (categoryId !== '-1') {
      this.loadCategory(categoryId);
    } else {
      this.setState({
        ...this.state,
        category: { id: -1, enName: 'New Category' },
      });
    }
  }

  updateCategoryField = (prop, value) => {
    this.setState({
      category: {
        ...this.state.category,
        [prop]: value === '' ? undefined : value,
      },
    });
  };
  setFieldError = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [key]: value,
      },
    }));
  };

  save = () => {
    this.setState({
      ...this.state,
      isBusy: true,
    });
    const handleUpdateFailure = (error) => {
      const { response } = error;
      if (response.status === 409 && response.data.error) {
        toastr.error(
          response.data.error.title,
          response.data.error.description,
        );
      } else if (response.status === 400 && response.data.fieldErrors) {
        response.data.fieldErrors.forEach((fieldError) =>
          this.setFieldError(fieldError.field, fieldError.message),
        );
      } else {
        toastr.error('Could not save Category', error.message);
      }
    };

    if (this.state.category.id === -1) {
      CategoryRepository.createCategory(this.state.category)
        .then((data) =>
          this.setState({
            ...this.state,
            category: data,
          }),
        )
        .catch((error) => handleUpdateFailure(error))
        .finally(() => {
          this.setState({
            ...this.state,
            isBusy: false,
          });
        });
    } else {
      CategoryRepository.updateCategory(
        this.state.category.id,
        this.state.category,
      )
        .then((data) => this.backToOverview())
        .catch((error) => handleUpdateFailure(error))
        .finally(() => {
          this.setState({
            ...this.state,
            isBusy: false,
          });
        });
    }
  };

  handleFieldChange = (prop) => (event) => {
    this.updateCategoryField(prop, event.target.value);
    this.setFieldError(prop, null);
  };

  loadCategory = (categoryId) => {
    this.setState({
      ...this.state,
      isBusy: true,
    });

    CategoryRepository.getCategory(categoryId)
      .then((category) => {
        this.setState({
          ...this.state,
          category,
        });
      })
      .catch((error) => toastr.error('Could not load category', error.message))
      .finally(() => {
        this.setState({
          ...this.state,
          isBusy: false,
        });
      });
  };

  backToOverview = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.CATEGORIES_OVERVIEW.path);
  };

  deleteCategory = () => {
    CategoryRepository.deleteCategory(this.state.category.id)
      .then(() => this.backToOverview())
      .catch((error) =>
        toastr.error('Could not delete category', error.message),
      );
  };

  render() {
    const categoryDetail = (
      <div>
        <Paper>
          <div className={styles.cardHeader}>
            <BackIconButton
              linkTo={CONSTANTS.ROUTES.CATEGORIES_OVERVIEW.path}
            />
            <Typography variant="h5" className={styles.title}>
              <i
                className={`fa-duotone fa-${this.state.category.iconName} fa-xl`}
                title={this.state.category.iconName}></i>
              &nbsp;&nbsp;
              {this.state.category.enName}
            </Typography>
          </div>
        </Paper>
        <HorizontalSpacer />
        <div className={styles.deleteButton}>
          <ButtonWithConfirmation
            id={'deleteButtonCat'}
            disabled={!this.state.category.canBeDeleted}
            onConfirm={this.deleteCategory}
            title={'Are you sure?'}
            message={'Delete category'}
            icon={<DeleteIcon />}
            confirmButtonText={'Yes delete'}
            buttonText={'Delete'}
            showIconOnButton={true}>
            <i
              className={`fa-duotone fa-${this.state.category.iconName} fa-xl`}
              title={this.state.category.iconName}></i>
            &nbsp;{this.state.category.enName}
          </ButtonWithConfirmation>
        </div>
        <EntityForm onCancel={this.backToOverview} onSave={this.save}>
          <TextField
            label="English Name"
            type="text"
            value={this.state.category.enName}
            onChange={this.handleFieldChange('enName')}
            margin="normal"
            error={!!this.state.fieldErrors.enName}
            fullWidth
          />
          <TextField
            label="Dutch Name"
            type="text"
            value={this.state.category.nlName}
            error={!!this.state.fieldErrors.nlName}
            onChange={this.handleFieldChange('nlName')}
            margin="normal"
            fullWidth
          />
          <TextField
            label="French Name"
            type="text"
            value={this.state.category.frName}
            error={!!this.state.fieldErrors.frName}
            onChange={this.handleFieldChange('frName')}
            margin="normal"
            fullWidth
          />
          <TextField
            label="German Name"
            type="text"
            value={this.state.category.deName}
            error={!!this.state.fieldErrors.deName}
            onChange={this.handleFieldChange('deName')}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Icon Name"
            type="text"
            value={this.state.category.iconName}
            onChange={this.handleFieldChange('iconName')}
            error={!!this.state.fieldErrors.iconName}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Icon Unicode"
            type="text"
            value={this.state.category.iconUnicode}
            error={!!this.state.fieldErrors.iconUnicode}
            onChange={this.handleFieldChange('iconUnicode')}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Synonyms"
            type="text"
            value={this.state.category.synonyms}
            error={!!this.state.fieldErrors.synonyms}
            onChange={this.handleFieldChange('synonyms')}
            margin="normal"
            fullWidth
          />
          <SubCategories
            value={this.state.category.subCategoryId}
            onChange={this.handleFieldChange('subCategoryId')}
            error={!!this.state.fieldErrors.subCategoryId}
          />
        </EntityForm>

        <CategoryImageSuggestions categoryId={this.state.category.id} />
      </div>
    );

    return (
      <div className={styles.categoryDetail}>
        <HorizontalSpacer size="small" />

        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>{this.state.category.id ? categoryDetail : <NoDataFound />}</div>
        )}
      </div>
    );
  }
}

export default withRouter(CategoryDetail);
