import {
  PATCH_CAMPAIGN_CTA,
  PATCH_CAMPAIGN,
  RESET_CAMPAIGN,
} from './action-types';

export function patchCampaign(partialCampaign) {
  return { type: PATCH_CAMPAIGN, payload: partialCampaign };
}

export function patchCampaignCallToAction(campaignCallToAction) {
  return { type: PATCH_CAMPAIGN_CTA, payload: campaignCallToAction };
}

export function resetCampaign() {
  return { type: RESET_CAMPAIGN };
}
