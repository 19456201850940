import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAuthUser } from '../../store/session/selectors';
import Navigation from './Navigation';

const mapStateToProps = (state) => ({
  authUser: getAuthUser(state),
});

class NavigationContainer extends React.Component {
  render() {
    return <Navigation />;
  }
}

export default connect(mapStateToProps)(withRouter(NavigationContainer));
