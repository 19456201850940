import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonWithConfirmation from '../../../../../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import ShopRepository from '../../../../../../../repository/shop/ShopRepository';
import getError from '../../../../../../../utils/utils';

class AddPosButton extends React.Component {
  static propTypes = {
    shopId: PropTypes.number,
    onPosAdded: PropTypes.func,
  };

  state = {
    isBusy: false,
  };

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  addPos = () => {
    this.setIsBusy(true);
    return new Promise((resolve, reject) => {
      const request = {
        description: this.state.newPosInput,
        shopId: this.props.shopId,
      };
      ShopRepository.addPos(request, this.props.shopId)
        .then((data) => {
          toastr.success('Success', `POS created`);
          this.props.onPosAdded(data);
        })
        .catch((error) => {
          toastr.error('Could not create POS', getError(error));
          reject();
        })
        .finally(() => {
          this.setIsBusy(false);
        });
    });
  };

  posInputChanged = (event) => {
    const description = event.target.value;
    this.setState({
      ...this.state,
      newPosInput: description,
    });
  };

  render() {
    const button = (
      <ButtonWithConfirmation
        buttonVariant="text"
        buttonText="Add POS"
        onConfirm={this.addPos}
        cancelButtonText="Cancel"
        icon={<AddIcon />}
        confirmButtonText="Add"
        title="Add POS"
        id="AddPos"
        message="Fill in a pos description:">
        <TextField
          type="text"
          placeholder="description"
          id="newPosInput"
          onChange={this.posInputChanged}
        />
      </ButtonWithConfirmation>
    );
    return this.state.isBusy ? <CircularProgress /> : button;
  }
}

export default AddPosButton;
