import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getAuthUser } from '../../../store/session/selectors';

const mapStateToProps = (state) => ({
  authUser: getAuthUser(state),
});

class HasPermission extends React.Component {
  static propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string),
  };

  render() {
    return this.isPermitted() ? <div> {this.props.children}</div> : <div></div>;
  }

  isPermitted() {
    if (!this.props.authUser) {
      return false;
    }
    return !this.props.permissions
      ? true
      : this.props.authUser.hasSomePermission(this.props.permissions);
  }
}

export default connect(mapStateToProps)(HasPermission);
