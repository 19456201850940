import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCampaignSubject } from '../../../../store/campaigns/selectors';
import { patchCampaign } from '../../../../store/campaigns/actions';
import CampaignTitleInput from './CampaignTitleInput';

const mapStateToProps = (state) => ({
  campaignSubject: getCampaignSubject(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchCampaign,
    },
    dispatch,
  );

class CampaignSubjectInputContainer extends React.Component {
  handleChange = (event) => {
    this.props.patchCampaign({ subject: event.target.value });
  };

  render() {
    return (
      <CampaignTitleInput
        value={this.props.campaignSubject}
        onChange={this.handleChange}
        helperText="This field will also be used as the push notification content"
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignSubjectInputContainer);
