import { JoynV2Api } from '../api/joyn/JoynApi';

export default new (class AuthUserRepository {
  findUsers = (merchantId) =>
    JoynV2Api.get(`/merchants/${merchantId}/auth-users`);

  createAuthUser = (createUserRequest, merchantId) =>
    JoynV2Api.post(`/merchants/${merchantId}/auth-users`, {
      ...createUserRequest,
    });

  deleteAuthUser = (authUserId, merchantId) =>
    JoynV2Api.delete(`/merchants/${merchantId}/auth-users/${authUserId}`);

  sendForgetPassword = (authUserId, merchantId) =>
    JoynV2Api.put(
      `/merchants/${merchantId}/auth-users/${authUserId}/forgot-password`,
    );
})();
