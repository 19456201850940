import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/Typography';
import ErrorContainer from '../../../../../../shared/components/ErrorContainer/ErrorContainer';
import styles from './RecipientCountDisplay.module.css';

class RecipientCountDisplay extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    value: 0,
    errorMessage: '',
  };

  render() {
    return (
      <Typography variant="subtitle1" paragraph>
        Currently selected recipients:{' '}
        <span className={styles.numeric}>{this.props.value} users</span>
        <ErrorContainer errorMessage={this.props.errorMessage} />
      </Typography>
    );
  }
}

export default RecipientCountDisplay;
