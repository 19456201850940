import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import LinkButton from '../../../../../shared/components/LinkButton/LinkButton';
import CONSTANTS from '../../../../../constants';
import BooleanToString from '../../../../../shared/components/BooleanToString/BooleanToString';
import styles from './MerchantOverviewTable.module.css';

export default class MerchantOverviewTable extends React.Component {
  static propTypes = {
    merchants: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    merchants: [],
  };

  render() {
    return (
      <Paper className={styles.tableWrapper} id="merchantOverviewTable">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>VAT number</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Deleted</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.merchants.map((m) => (
              <TableRow key={m.id} hover>
                <TableCell>{m.id}</TableCell>
                <TableCell>{m.name}</TableCell>
                <TableCell>{m.vatNumber}</TableCell>
                <TableCell>{m.email}</TableCell>
                <TableCell>
                  <BooleanToString bool={m.deleted} />
                </TableCell>
                <TableCell align="right">
                  <LinkButton
                    text="Details"
                    linkTo={generatePath(
                      CONSTANTS.ROUTES.MERCHANT_DETAILS.path,
                      {
                        merchantId: m.id,
                      },
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
