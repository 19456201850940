import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import styles from './NavBarItem.module.css';

export default class NavBarItem extends React.Component {
  static propTypes = {
    permission: PropTypes.string,
  };

  render() {
    return (
      <HasPermission permissions={[this.props.permission]}>
        <NavLink
          {...this.props}
          className={styles.navBarItem}
          activeClassName={styles.active}
        />
      </HasPermission>
    );
  }
}
