import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import CONSTANTS from '../../constants';
import { GuardedRoute } from '../../shared/components/User/GuardedRoute';
import MerchantDetailsContainer from './details/MerchantDetailsContainer';
import MerchantOverview from './overview/MerchantOverview';
import MerchantDetailsUpdate from './update/MerchantDetailsUpdate';
import MerchantCreate from './create/MerchantCreate';

class Merchants extends React.Component {
  render() {
    return (
      <Switch>
        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_READ_MERCHANTS]}
          exact
          path={CONSTANTS.ROUTES.MERCHANT_OVERVIEW.path}
          component={MerchantOverview}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}
          exact
          path={CONSTANTS.ROUTES.MERCHANT_CREATE.path}
          component={MerchantCreate}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_READ_MERCHANTS]}
          exact
          path={CONSTANTS.ROUTES.MERCHANT_DETAILS.path}
          component={MerchantDetailsContainer}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}
          exact
          path={CONSTANTS.ROUTES.MERCHANT_UPDATE.path}
          component={MerchantDetailsUpdate}
        />

        <Redirect to={CONSTANTS.ROUTES.FOUR_OH_FOUR.path} />
      </Switch>
    );
  }
}

export default withRouter(Merchants);
