import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import EdenredRepository from '../../../repository/integrations/edenred/EdenredRepository';
import EdenredReportForm from './ReportForm/EdenredReportForm';

export default class EdenredIntegration extends React.Component {
  state = {
    busy: false,
    diff: null,
    downloadReportLink: null,
  };

  async componentDidMount() {
    const downloadReportLink = await EdenredRepository.getReportDownloadLink();
    this.setState({ downloadReportLink });
  }

  upload = async (file) => {
    this.setState({ diff: null });
    try {
      this.setState({ busy: true });
      const diff = await EdenredRepository.uploadReport(file);
      this.setState({
        diff,
      });
      toastr.success('Successfully uploaded report');
    } catch (error) {
      toastr.error('Could not upload report', error.message);
    } finally {
      this.setState({ busy: false });
    }
  };

  render() {
    if (this.state.busy) {
      return <CircularProgress />;
    }

    return (
      <EdenredReportForm
        downloadReportLink={this.state.downloadReportLink}
        onUpload={this.upload}
        diff={this.state.diff}
      />
    );
  }
}
