import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper/Paper';
import { FormControlLabel, Switch } from '@material-ui/core';
import { noop } from '../../../../utils/utils';
import styles from '../../../../shared/components/SearchBar/SearchBar.module.css';

class SearchBar extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onSearch: PropTypes.func,
  };

  static defaultProps = {
    placeholder: 'Search',
    searchTerm: '',
    onSearch: noop,
  };

  state = {
    searchTerm: '',
    includeDeleted: true,
  };

  componentDidMount() {
    let previousSearch = sessionStorage.getItem('merchant_search');
    if (previousSearch != null) {
      let json = JSON.parse(previousSearch);
      this.setState({
        ...this.state,
        searchTerm: json.searchTerm,
        includeDeleted: json.includeDeleted,
      });
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ ...this.state, [prop]: event.target.value });
  };

  includeDeletedChanged = (event) => {
    this.setState(
      {
        ...this.state,
        includeDeleted: event.target.checked,
      },
      () =>
        this.searchAndStoreParams(
          this.state.searchTerm,
          this.state.includeDeleted,
        ),
    );
  };

  searchOnPressEnter = (event) => {
    if (event.key === 'Enter') {
      const searchTerm = (this.state.searchTerm || '').trim();
      event.preventDefault();
      this.searchAndStoreParams(searchTerm);
    }
  };

  searchAndStoreParams(searchTerm) {
    sessionStorage.setItem(
      'merchant_search',
      JSON.stringify({
        searchTerm: searchTerm,
        includeDeleted: this.state.includeDeleted,
      }),
    );
    this.props.onSearch(searchTerm, this.state.includeDeleted);
  }

  render() {
    return (
      <>
        <Paper className={styles.searchBar}>
          <SearchIcon className={styles.searchIcon} color="action" />
          <Input
            id="searchInput"
            className={styles.searchInput}
            placeholder={this.props.placeholder}
            type="search"
            value={this.state.searchTerm}
            onChange={this.handleChange('searchTerm')}
            onKeyPress={this.searchOnPressEnter}
            disableUnderline
          />
        </Paper>
        <FormControlLabel
          className={styles.checkBoxContainer}
          control={
            <Switch
              checked={this.state.includeDeleted}
              onChange={this.includeDeletedChanged}
              name="includeDeleted"
              color="primary"
            />
          }
          label="Include deleted"
        />
      </>
    );
  }
}

export default SearchBar;
