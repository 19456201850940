export const APP_USAGE_PERIODS = Object.freeze({
  ONE_MONTH: {
    key: 'P1M',
    label: 'month',
  },
  THREE_MONTHS: {
    key: 'P3M',
    label: '3 months',
  },
  SIX_MONTHS: {
    key: 'P6M',
    label: '6 months',
  },
});

export const SUPPORTED_APP_USAGE_PERIODS = Object.values(APP_USAGE_PERIODS).map(
  (usagePeriod) => usagePeriod.key,
);
