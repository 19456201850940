import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import { noop } from '../../../utils/utils';
import styles from './EntityForm.module.css';

export default class EntityForm extends React.Component {
  static propTypes = {
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onSaveText: PropTypes.string,
  };

  static defaultProps = {
    onSave: noop,
    onCancel: noop,
    onSaveText: 'Save Changes',
  };

  wrappedOnSave = (event) => {
    event.preventDefault();
    return this.props.onSave();
  };

  render() {
    return (
      <Paper className={styles.entityForm}>
        <form id="emailForm" autoComplete="off" onSubmit={this.wrappedOnSave}>
          {this.props.children}

          <HorizontalSpacer size="large" />

          {this.props.onCancel === noop ? (
            undefined
          ) : (
            <Button
              id="cancelButton"
              aria-label="Cancel"
              type="button"
              onClick={this.props.onCancel}>
              Cancel
            </Button>
          )}
          <span>&nbsp;</span>

          {this.props.onSave === noop ? (
            undefined
          ) : (
            <Button
              id="saveButton"
              variant="contained"
              color="primary"
              type="submit">
              {this.props.onSaveText}
            </Button>
          )}
        </form>
      </Paper>
    );
  }
}
