import React from 'react';
import Fab from '@material-ui/core/Fab';
import logo from '../../static/images/logo.svg';
import HorizontalSpacer from '../../shared/components/HorizontalSpacer/HorizontalSpacer';
import keycloakClient from '../../shared/keycloak/keycloakClient';
import styles from './Home.module.css';

export default class Home extends React.Component {
  state = {
    userName: `${keycloakClient.getName()} (${keycloakClient.getEmail()})`,
    clicks: 0,
  };

  onClick = () => {
    this.setState({
      clicks: this.state.clicks + 1,
    });
  };

  render() {
    const sammyNameExplanation = (
      <div>
        <h2>Did you know?</h2>
        <p>
          The acronym
          <strong> SAMMY </strong>
          stands for
          <strong> Service And Maintenance Manager (of) joYn</strong>
        </p>
      </div>
    );

    return (
      <div className={styles.app}>
        <header className={styles.appHeader}>
          <img src={logo} className={styles.appLogo} alt="logo" />
          <h1 className={styles.appTitle}>
            Hi {this.state.userName}, welcome to SAMMY!
          </h1>
        </header>
        <HorizontalSpacer size="medium" />
        <Fab
          id="clicksButton"
          onClick={this.onClick}
          variant="extended"
          color="primary"
          size="large">
          Internal clicks - {this.state.clicks}
        </Fab>

        {this.state.clicks === 5 ? sammyNameExplanation : ''}

        <HorizontalSpacer size="medium" />
        <iframe
          title="Joyn Statistics"
          src="https://metabase.reporting.joyn.be/public/dashboard/ba6fd80f-ce09-4660-ba76-3b13c39b81ad"
          frameBorder="0"
          width="900"
          height="600"
          className={styles.statistics}
        />
      </div>
    );
  }
}
