import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSideBar } from '../../../store/navigation/actions';
import keycloakClient from '../../../shared/keycloak/keycloakClient';
import AppBarHeader from './AppBarHeader';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showSideBar,
    },
    dispatch,
  );

class AppBarHeaderContainer extends React.Component {
  render() {
    return (
      <AppBarHeader
        onMenuIconClick={this.props.showSideBar}
        onLogout={keycloakClient.logout}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppBarHeaderContainer);
