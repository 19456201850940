import React from 'react';
import Button from '@material-ui/core/Button';
import CancelCampaignCreationButton from './components/CancelCampaignCreationButton/CancelCampaignCreationButton';
import styles from './CancelAndContinue.module.css';

export default class CancelAndContinue extends React.Component {
  render() {
    return (
      <div className={styles.buttonsWrapper}>
        <CancelCampaignCreationButton />
        <span>&nbsp;</span>
        <Button
          id="continueButton"
          variant="contained"
          color="primary"
          type="submit">
          Continue
        </Button>
      </div>
    );
  }
}
