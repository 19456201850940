export const CAMPAIGN_TYPES = Object.freeze({
  MESSAGE: {
    key: 'JOYN_MESSAGE',
    label: 'Joyn Message',
  },
});

export const SUPPORTED_CAMPAIGN_TYPES = Object.values(CAMPAIGN_TYPES).map(
  (campaignType) => campaignType.key,
);
