import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/Typography';
import Switch from '@material-ui/core/Switch/Switch';
import { noop } from '../../../../../utils/utils';
import AgeRangeSelection from './components/AgeRangeSelection/AgeRangeSelection';
import AgeRange from './components/AgeRangeSelection/classes/AgeRange';

class AgeTarget extends React.Component {
  static propTypes = {
    ageRanges: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    ageRanges: [],
    onChange: noop,
  };

  state = {
    ageRanges: this.props.ageRanges,
    showAgeRangeSection: !!this.props.ageRanges.length,
  };

  onChangeCallback = () =>
    this.props.onChange({
      ageRanges: this.state.showAgeRangeSection
        ? this.state.ageRanges
        : AgeTarget.defaultProps.ageRanges,
    });

  onUpdateAgeRanges = (ageRanges) => {
    this.setStateWithCallBack({ ageRanges });
  };

  setStateWithCallBack = (state) => {
    this.setState(state, this.onChangeCallback);
  };

  toggleAgeRangeSectionVisibility = (event) => {
    const showAgeRangeSection = event.target.checked;
    const noAgeRangesYet = !this.state.ageRanges.length;

    if (showAgeRangeSection && noAgeRangesYet) {
      const defaultAgeRanges = [new AgeRange().raw];

      this.setStateWithCallBack({
        ageRanges: defaultAgeRanges,
        showAgeRangeSection,
      });
    } else {
      this.setStateWithCallBack({ showAgeRangeSection });
    }
  };

  render() {
    return (
      <div>
        <Typography variant="h6">
          Restrict to age
          <Switch
            checked={this.state.showAgeRangeSection}
            onChange={this.toggleAgeRangeSectionVisibility}
          />
        </Typography>
        {this.state.showAgeRangeSection ? (
          <AgeRangeSelection
            ageRanges={this.state.ageRanges}
            onChange={this.onUpdateAgeRanges}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default AgeTarget;
