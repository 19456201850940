import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

function ControlledAutocomplete(props) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleSelectOption = (event, option) => {
    props.onSelect(option);
  };

  const selectedOption = props.selectedOption
    ? props.options.find((option) => option.key === props.selectedOption.key)
    : null;

  return (
    <Autocomplete
      id={props.id}
      options={props.options}
      value={selectedOption}
      onChange={handleSelectOption}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option.value}
      getOptionSelected={(option, value) => option.key === value.key}
      loading={props.isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.isLoading && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
            style: { borderBottom: '1px solid black' },
          }}
        />
      )}
    />
  );
}

export default ControlledAutocomplete;
