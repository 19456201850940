import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import CONSTANTS from '../../constants';
import { GuardedRoute } from '../../shared/components/User/GuardedRoute';
import ShopDetailsContainer from './details/ShopDetailsContainer';
import ShopDetailsUpdate from './update/ShopDetailsUpdate';
import ShopDetailsCreate from './create/ShopDetailsCreate';

class Shops extends React.Component {
  render() {
    return (
      <Switch>
        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}
          exact
          path={CONSTANTS.ROUTES.SHOP_CREATE.path}
          component={ShopDetailsCreate}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_READ_SHOPS]}
          exact
          path={CONSTANTS.ROUTES.SHOP_DETAILS.path}
          component={ShopDetailsContainer}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}
          exact
          path={CONSTANTS.ROUTES.SHOP_UPDATE.path}
          component={ShopDetailsUpdate}
        />

        <Redirect to={CONSTANTS.ROUTES.FOUR_OH_FOUR.path} />
      </Switch>
    );
  }
}

export default withRouter(Shops);
