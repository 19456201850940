import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { noop } from '../../../../../utils/utils';

class SendTestCampaignButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: noop,
  };

  render() {
    return (
      <Button
        id="sendTestCampaignButton"
        color="primary"
        type="button"
        variant="outlined"
        onClick={this.props.onClick}>
        Send Test
      </Button>
    );
  }
}

export default SendTestCampaignButton;
