import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import HorizontalSpacer from '../../../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import styles from './UserIntegration.module.css';

export default class UserIntegration extends React.Component {
  static propTypes = {
    activePaymentTypes: PropTypes.arrayOf(PropTypes.object),
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    activePaymentTypes: [],
    isFetching: false,
  };

  paymentTypes() {
    return this.props.activePaymentTypes || [];
  }

  render() {
    const paymentTypesRows = (
      <div>
        {this.paymentTypes().length ? (
          this.paymentTypes().map((paymentType) => (
            <div key={paymentType.type} className={styles.paymentTypeRow}>
              <img src={`${paymentType.icon}?w=30&h=30&fmt=png`} alt="logo" />
              <Typography
                variant="body1"
                className={styles.badge}
                style={{
                  backgroundColor: `${paymentType.backgroundColor}`,
                  color: `${paymentType.fontColor}`,
                }}>
                {paymentType.description} ({paymentType.partner})
              </Typography>
            </div>
          ))
        ) : (
          <p>User has no active payment types</p>
        )}
      </div>
    );

    return (
      <div className={styles.integration}>
        <Typography variant="h6">Active payment types</Typography>
        <HorizontalSpacer size="small" />

        {this.props.isFetching ? <CircularProgress /> : paymentTypesRows}
      </div>
    );
  }
}
