import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import CONSTANTS from '../../../constants';
import { getAuthUser } from '../../../store/session/selectors';

const mapStateToProps = (state) => ({
  authUser: getAuthUser(state),
});

const BaseGuardedRoute = ({
  component: Component,
  permissions,
  authUser,
  ...rest
}) => {
  const isPermitted = () => {
    if (!permissions) {
      return true;
    }

    return authUser.hasSomePermission(permissions);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authUser) {
          return <CircularProgress />;
        }

        return isPermitted() ? (
          <Component {...props} />
        ) : (
          <Redirect to={CONSTANTS.ROUTES.HOME.path} />
        );
      }}
    />
  );
};

export const GuardedRoute = connect(mapStateToProps)(BaseGuardedRoute);
