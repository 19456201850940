import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import CONSTANTS from '../../../../../constants';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import { noop } from '../../../../../utils/utils';
import { CAMPAIGN_STATUS } from '../../../constants/campaign-status';
import { CAMPAIGN_TYPES } from '../../../constants/campaign-types';
import styles from '../../CampaignOverview.module.css';
import EditCampaignButton from '../EditCampaignButton/EditCampaignButton';

const TITLE_ID = 'JoynMessageCampaignsTitle';

export default class JoynMessageCampaignOverview extends React.Component {
  static propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.object),
    onPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    totalElements: PropTypes.number,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    campaigns: [],
    onPageChange: noop,
    page: 0,
    rowsPerPage: 0,
    totalElements: 0,
    isFetching: false,
  };

  handleChangePage = (event, page) => {
    this.scrollToTop();
    this.props.onPageChange(page);
  };

  scrollToTop = () => {
    const titleElement = document.getElementById(TITLE_ID);
    titleElement.scrollIntoView();
  };

  render() {
    const campaignsTable = (
      <Paper className={styles.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status Text</TableCell>
              <HasPermission
                permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_CAMPAIGNS]}>
                <TableCell />
              </HasPermission>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.campaigns.map((campaign) => (
              <TableRow key={campaign.id}>
                <TableCell>{campaign.name}</TableCell>
                <TableCell>{campaign.status}</TableCell>
                <TableCell>{campaign.type}</TableCell>
                <TableCell>
                  {`${campaign.statusText} ${moment(campaign.statusDate).format(
                    'DD/MM/YYYY',
                  )}`}
                </TableCell>

                <HasPermission
                  permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_CAMPAIGNS]}>
                  <TableCell align="right">
                    {campaign.status === CAMPAIGN_STATUS.DRAFT &&
                    campaign.type === CAMPAIGN_TYPES.MESSAGE.key ? (
                      <EditCampaignButton campaignId={campaign.id} />
                    ) : (
                      ''
                    )}
                  </TableCell>
                </HasPermission>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                count={this.props.totalElements}
                rowsPerPage={this.props.rowsPerPage}
                page={this.props.page}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );

    return (
      <div>
        <h1 id={TITLE_ID}>Joyn Message Campaigns</h1>

        {this.props.isFetching ? <CircularProgress /> : campaignsTable}
      </div>
    );
  }
}
