import { JoynV2Api } from '../api/joyn/JoynApi';

export default new (class CategoryRepository {
  getAllCategories = (params) => JoynV2Api.get('/sammy/categories', params);
  getSubCategories = (params) => JoynV2Api.get('/sammy/subcategories', params);
  getCategory = (categoryId) =>
    JoynV2Api.get(`/sammy/categories/${categoryId}`);
  updateCategory = (categoryId, category) =>
    JoynV2Api.put(`/sammy/categories/${categoryId}`, { ...category });
  createCategory = (category) =>
    JoynV2Api.post(`/sammy/categories`, { ...category });
  deleteCategory = (categoryId) =>
    JoynV2Api.delete(`/sammy/categories/${categoryId}`);
  getSuggestions = (categoryId) =>
    JoynV2Api.get(`/sammy/categories/${categoryId}/suggestions`);
})();
