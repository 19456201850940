import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCampaignName } from '../../../../store/campaigns/selectors';
import { patchCampaign } from '../../../../store/campaigns/actions';
import CampaignNameInput from './CampaignNameInput';

const mapStateToProps = (state) => ({
  campaignName: getCampaignName(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchCampaign,
    },
    dispatch,
  );

class CampaignNameInputContainer extends React.Component {
  handleChange = (event) => {
    this.props.patchCampaign({ name: event.target.value });
  };

  render() {
    return (
      <CampaignNameInput
        value={this.props.campaignName}
        onChange={this.handleChange}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignNameInputContainer);
