import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import CONSTANTS from '../../../constants';

class Thunks {
  goToCampaignOverview = () => (dispatch) =>
    dispatch(push(CONSTANTS.ROUTES.CAMPAIGN_OVERVIEW.path));

  goToCampaignInitialisation = () => (dispatch) =>
    dispatch(push(CONSTANTS.ROUTES.CAMPAIGN_INITIALISATION.path));

  goToCampaignDetails = (campaignId) => (dispatch) =>
    dispatch(
      push(
        generatePath(CONSTANTS.ROUTES.CAMPAIGN_DETAILS.path, {
          campaignId,
        }),
      ),
    );

  goToCampaignTarget = (campaignId) => (dispatch) =>
    dispatch(
      push(
        generatePath(CONSTANTS.ROUTES.CAMPAIGN_TARGET.path, {
          campaignId,
        }),
      ),
    );
}

const thunks = new Thunks();
export default thunks;
export const {
  goToCampaignOverview,
  goToCampaignInitialisation,
  goToCampaignDetails,
  goToCampaignTarget,
} = thunks;
