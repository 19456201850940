import { JoynV2Api } from '../../api/joyn/JoynApi';
import CONSTANTS from '../../../constants';

const RESOURCE = '/integration/edenred';

export default new (class EdenredRepository {
  uploadReport = (edenredReport) => {
    const formData = new FormData();
    formData.append('file', edenredReport);
    return JoynV2Api.post(`${RESOURCE}/report`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  getReportDownloadLink = () => {
    return JoynV2Api.get(
      `${CONSTANTS.ENV.API_V2}${RESOURCE}/report`,
    ).then((report) =>
      URL.createObjectURL(new Blob([report], { type: 'text/csv' })),
    );
  };
})();
