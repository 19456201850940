import { generatePath } from 'react-router-dom';
import { history } from '../../store/store';

class NavigationService {
  navigateTo(path, variables) {
    history.push(generatePath(path, variables));
  }
}

const navigationService = new NavigationService();

export default navigationService;
