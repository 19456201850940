import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import CONSTANTS from '../../../../constants';
import styles from './NavList.module.css';
import NavBarItem from './NavBarItem/NavBarItem';

class NavList extends React.Component {
  render() {
    return (
      <div className={styles.list}>
        <List component="nav">
          <NavBarItem to={CONSTANTS.ROUTES.HOME.path} exact>
            <ListItem button>{CONSTANTS.ROUTES.HOME.label}</ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.USER_OVERVIEW.path}
            permission={CONSTANTS.PERMISSIONS.OP_READ_USERS}>
            <ListItem button>{CONSTANTS.ROUTES.USER_OVERVIEW.label}</ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.POINTS_COUPONS.path}
            permission={CONSTANTS.PERMISSIONS.OP_UPDATE_USERS}>
            <ListItem button>{CONSTANTS.ROUTES.POINTS_COUPONS.label}</ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.MERGE_USERS.path}
            permission={CONSTANTS.PERMISSIONS.OP_UPDATE_USERS}>
            <ListItem button>{CONSTANTS.ROUTES.MERGE_USERS.label}</ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.REGISTRATION_REQUESTS.path}
            permission={CONSTANTS.PERMISSIONS.OP_READ_REGISTRATION_REQUESTS}>
            <ListItem button>
              {CONSTANTS.ROUTES.REGISTRATION_REQUESTS.label}
            </ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.IDENTIFIERS_OVERVIEW.path}
            permission={CONSTANTS.PERMISSIONS.OP_READ_IDENTIFIERS}>
            <ListItem button>
              {CONSTANTS.ROUTES.IDENTIFIERS_OVERVIEW.label}
            </ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.MERCHANT_OVERVIEW.path}
            permission={CONSTANTS.PERMISSIONS.OP_READ_MERCHANTS}>
            <ListItem button id="merchantOverviewMenuItem">
              {CONSTANTS.ROUTES.MERCHANT_OVERVIEW.label}
            </ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.PARTNER_OVERVIEW.path}
            permission={CONSTANTS.PERMISSIONS.OP_READ_PARTNERS}>
            <ListItem button id="partnersOverviewMenuItem">
              {CONSTANTS.ROUTES.PARTNER_OVERVIEW.label}
            </ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.CATEGORIES_OVERVIEW.path}
            permission={CONSTANTS.PERMISSIONS.OP_READ_CATEGORIES}>
            <ListItem button id="categoriesOverviewMenuItem">
              {CONSTANTS.ROUTES.CATEGORIES_OVERVIEW.label}
            </ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.CAMPAIGN_OVERVIEW.path}
            permission={CONSTANTS.PERMISSIONS.OP_READ_CAMPAIGNS}>
            <ListItem button>
              {CONSTANTS.ROUTES.CAMPAIGN_OVERVIEW.label}
            </ListItem>
          </NavBarItem>
          <NavBarItem
            to={CONSTANTS.ROUTES.EDENRED_INTEGRATION.path}
            permission={CONSTANTS.PERMISSIONS.OP_EDENRED}>
            <ListItem button>
              {CONSTANTS.ROUTES.EDENRED_INTEGRATION.label}
            </ListItem>
          </NavBarItem>
        </List>
        <Divider />
      </div>
    );
  }
}

export default NavList;
