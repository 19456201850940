export default class AuthUser {
  email;
  name;
  givenName;
  familyName;
  permissions;
  roleDescription;
  constructor(email, name, givenName, familyName) {
    this.email = email;
    this.name = name;
    this.givenName = givenName;
    this.familyName = familyName;
  }

  getInitials() {
    return this.givenName.charAt(0) + this.familyName.charAt(0);
  }

  setPermissions(permissions) {
    this.permissions = permissions;
  }

  setRoleDescription(roleDescription) {
    this.roleDescription = roleDescription;
  }

  hasPermission(permission) {
    return !this.permissions ? false : this.permissions.includes(permission);
  }

  hasSomePermission(permissions) {
    if (!this.permissions) {
      return false;
    }

    return permissions.some((permission) => this.hasPermission(permission));
  }
}
