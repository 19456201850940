import { v4 } from 'uuid';
import { JoynV1Api, JoynV2Api } from '../api/joyn/JoynApi';

class UserRepository {
  getUsers = (params) => JoynV1Api.get('/api/v2/support/users', params);

  getUser = (userId) => JoynV2Api.get(`/sammy/users/${userId}`);

  getUserCoupons = (userId, params) =>
    JoynV2Api.get(`/sammy/users/${userId}/coupons`, params);

  getRewardPromotionCodes = (userId, params) =>
    JoynV2Api.get(`/sammy/users/${userId}/promotion-codes`, params);

  activateUserCoupon = (userId, serialNumber) =>
    JoynV2Api.post(`/sammy/users/${userId}/coupons/${serialNumber}`);

  getUserIdentifiers = (userId) =>
    JoynV2Api.get(`/sammy/users/${userId}/identifiers`);

  getActivity = (userId, params) =>
    JoynV1Api.get(`/api/v2/support/users/${userId}/activity`, params);

  updateUserEmail = (userId, email) =>
    JoynV2Api.put(`/sammy/users/${userId}/email`, { newEmail: email });

  updateUserDetails = (userId, details) =>
    JoynV2Api.put(`/sammy/users/${userId}`, details);

  getPartnerBalances = (userId) =>
    JoynV2Api.get(`/api/v2/users/${userId}/integrations`);

  changePoints = (partyId, shopId, points) =>
    JoynV2Api.put(`/api/v2/transactions/correction/${v4()}`, {
      shopId: shopId,
      partyId: partyId,
      origin: 'sammy',
      points: points,
    });

  restoreUser = (userId) => JoynV2Api.post(`/sammy/users/${userId}/restore`);

  deleteUser = (userId) => JoynV2Api.delete(`/sammy/users/${userId}`);

  requestResetPasswordLink = (userId) =>
    JoynV1Api.get(`/api/v2/support/users/${userId}/reset-password-link`);

  giveAward = (userId, request) =>
    JoynV2Api.post(`/sammy/users/${userId}/award`, request);

  merge = (request) => JoynV2Api.post(`sammy/merge`, request);
}

export default new UserRepository();
