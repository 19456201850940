import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { Chip } from '@material-ui/core';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import CONSTANTS from '../../../../../constants';
import CopyToClipboard from '../../../../../shared/components/Clipboard/Clipboard';
import HorizontalSpacer from '../../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import AddPosButton from './components/AddPosButton/AddPosButton';
import AddPartnerButton from './components/AddPartnerButton/AddPartnerButton';
import styles from './ShopIntegrations.module.css';
import AddApiKeyButton from './components/AddApiKeyButton/AddApiKeyButton';

class ShopIntegrations extends React.Component {
  static propTypes = {
    paymentMethods: PropTypes.arrayOf(Object).isRequired,
    thirdPartyReferences: PropTypes.arrayOf(Object).isRequired,
    pointOfSales: PropTypes.arrayOf(Object).isRequired,
    apiKeys: PropTypes.arrayOf(Object).isRequired,
    shop: PropTypes.object,
  };

  static defaultProps = {
    thirdPartyReferences: [],
    pointOfSales: [],
    apiKeys: [],
  };

  state = {
    thirdPartyReferences: this.props.thirdPartyReferences,
    pointOfSales: this.props.pointOfSales,
    apiKeys: this.props.apiKeys,
  };

  paymentMethods() {
    return (
      <div id="shopPaymentOptions">
        {this.props.paymentMethods.length ? (
          this.props.paymentMethods.map((paymentMethod) => (
            <Chip
              label={paymentMethod}
              color={'secondary'}
              className={styles.paymentMethodChip}
            />
          ))
        ) : (
          <Paper>
            <Typography align="left" style={{ padding: '7px' }}>
              No payment methods have been configured
            </Typography>
          </Paper>
        )}
      </div>
    );
  }

  onPartnerAdded(newPartner) {
    this.setState((prevState) => ({
      ...prevState,
      thirdPartyReferences: [...prevState.thirdPartyReferences, newPartner],
    }));
  }

  partnerClipboardString(reference) {
    return `Shop id: ${this.props.shop.id}
Shop name: ${this.props.shop.name}
Shop city: ${this.props.shop.city}
PartnerKey: ${reference.partnerKey}
ShopReference: ${reference.externalReference}`;
  }

  partnerReferences() {
    return (
      <div className={styles.buttonBar}>
        <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}>
          <AddPartnerButton
            shopId={this.props.shop.id}
            onPartnerAdded={(reference) => this.onPartnerAdded(reference)}
          />
        </HasPermission>
        <Paper className={styles.tableWrapper}>
          {this.state.thirdPartyReferences.length > 0 ? (
            <Table id="shopThirdPartyReferences">
              <TableHead>
                <TableRow>
                  <TableCell>Partner</TableCell>
                  <TableCell>Reference</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.thirdPartyReferences.map((reference) => (
                  <TableRow
                    key={reference.partnerKey}
                    id={reference.partnerKey}>
                    <TableCell>{reference.partnerKey}</TableCell>
                    <TableCell>{reference.externalReference}</TableCell>
                    <TableCell className={styles.actionColumn}>
                      <CopyToClipboard
                        text={this.partnerClipboardString(reference)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography align="left" style={{ padding: '7px' }}>
              No Partner references have been configured
            </Typography>
          )}
        </Paper>
      </div>
    );
  }

  onPosAdded(newPos) {
    this.setState((prevState) => ({
      ...prevState,
      pointOfSales: [...prevState.pointOfSales, newPos],
    }));
  }

  onApiKeyAdded(newApiKey) {
    this.setState((prevState) => ({
      ...prevState,
      apiKeys: [...prevState.apiKeys, newApiKey],
    }));
  }

  posClipboardString(pos, ref) {
    return `Shop id: ${this.props.shop.id}
Shop name:   ${this.props.shop.name}
Shop city:   ${this.props.shop.city}
UniquePosId: ${pos.uniqueId}
Description: ${pos.description}
Partner:     ${ref.partnerKey}
Url:         https://api-v2.joyn.eu/api/v2/shops/${ref.externalReference}/pos/${pos.uniqueId}/scan-session
`;
  }

  pos() {
    return (
      <div className={styles.buttonBar}>
        <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}>
          <AddPosButton
            shopId={this.props.shop.id}
            onPosAdded={(pos) => this.onPosAdded(pos)}
          />
        </HasPermission>
        <Paper>
          {this.state.pointOfSales.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Url</TableCell>
                  <TableCell>Partner</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.thirdPartyReferences.map((ref) =>
                  this.state.pointOfSales.map((pos) => (
                    <TableRow key={pos.uniqueId}>
                      <TableCell>{pos.description}</TableCell>
                      <TableCell>{ref.partnerKey}</TableCell>
                      <TableCell>
                        https://api-v2.joyn.eu/api/v2/shops/
                        {ref.externalReference}/pos/{pos.uniqueId}/scan-session
                      </TableCell>
                      <TableCell className={styles.actionColumn}>
                        <CopyToClipboard
                          text={this.posClipboardString(pos, ref)}
                        />
                      </TableCell>
                    </TableRow>
                  )),
                )}
              </TableBody>
            </Table>
          ) : (
            <Typography align="left" style={{ padding: '7px' }}>
              No Point of sales have been configured
            </Typography>
          )}
        </Paper>
      </div>
    );
  }

  apiKeys() {
    return (
      <div className={styles.buttonBar}>
        <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}>
          <AddApiKeyButton
            shopId={this.props.shop.id}
            onApiKeyAdded={(apiKey) => this.onApiKeyAdded(apiKey)}
          />
        </HasPermission>
        <Paper>
          {this.state.apiKeys.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Partner</TableCell>
                  <TableCell>ApiKey</TableCell>
                  <TableCell>Permissions</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.apiKeys.map((apiKey) => (
                  <TableRow key={apiKey.apiKey}>
                    <TableCell>{apiKey.partnerCode.partnerCode}</TableCell>
                    <TableCell>{apiKey.apiKey}</TableCell>
                    <TableCell>{apiKey.permissions}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography align="left" style={{ padding: '7px' }}>
              No api keys have been configured
            </Typography>
          )}
        </Paper>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h3>Payment methods</h3>
        {this.paymentMethods()}
        <HorizontalSpacer size="small" />
        <h3>Partner references</h3>
        {this.partnerReferences()}
        <HorizontalSpacer size="small" />
        <h3>Point of sales</h3>
        {this.pos()}
        <HorizontalSpacer size="small" />
        <h3>Api Keys</h3>
        {this.apiKeys()}
      </div>
    );
  }
}

export default ShopIntegrations;
