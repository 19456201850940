import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCampaignCoverImage } from '../../../../../store/campaigns/selectors';
import { patchCampaign } from '../../../../../store/campaigns/actions';
import CampaignImageUrl from './CampaignImageUrl';

const mapStateToProps = (state) => ({
  campaignImageUrl: getCampaignCoverImage(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchCampaign,
    },
    dispatch,
  );

class CampaignImageUrlContainer extends React.Component {
  handleChange = (event) => {
    this.props.patchCampaign({ coverImage: event.target.value });
  };

  render() {
    return (
      <CampaignImageUrl
        value={this.props.campaignImageUrl}
        onChange={this.handleChange}
        required
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignImageUrlContainer);
