import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { generatePath } from 'react-router-dom';
import { Button } from '@material-ui/core';
import BooleanToString from '../../../../../shared/components/BooleanToString/BooleanToString';
import LinkButton from '../../../../../shared/components/LinkButton/LinkButton';
import CONSTANTS from '../../../../../constants';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import NavigationService from '../../../../../shared/navigation/NavigationService';
import styles from './MerchantShopOverview.module.css';

export default class MerchantShopOverview extends React.Component {
  static propTypes = {
    shops: PropTypes.arrayOf(PropTypes.object),
    merchantId: PropTypes.number,
  };

  static defaultProps = {
    shops: [],
    merchantId: 0,
  };

  createNewShop = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.SHOP_CREATE.path, {
      merchantId: this.props.merchantId,
    });
  };

  addShopButton() {
    return (
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_CRM_SHOPS]}>
        <Button
          id="createShopButton"
          color="secondary"
          onClick={this.createNewShop}>
          Add
        </Button>
      </HasPermission>
    );
  }

  render() {
    return (
      <>
        <div className={styles.buttonBar}>{this.addShopButton()}</div>
        <Paper className={styles.tableWrapper} id="merchantShopOverview">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Zip code</TableCell>
                <TableCell>Deleted</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.shops.map((shop) => (
                <TableRow key={shop.id}>
                  <TableCell>{shop.id}</TableCell>
                  <TableCell>{shop.name}</TableCell>
                  <TableCell>{shop.address}</TableCell>
                  <TableCell>{shop.city}</TableCell>
                  <TableCell>{shop.zipCode}</TableCell>
                  <TableCell>
                    <BooleanToString bool={shop.deleted} />
                  </TableCell>
                  <TableCell align="right">
                    <LinkButton
                      text="Details"
                      linkTo={generatePath(CONSTANTS.ROUTES.SHOP_DETAILS.path, {
                        shopId: shop.id,
                      })}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }
}
