import initialState from './state';
import {
  GET_MESSAGE_CAMPAIGNS,
  LOAD_CAMPAIGN_BY_ID,
  PATCH_CAMPAIGN_CTA,
  PATCH_CAMPAIGN,
  RESET_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from './action-types';

function reducer(state = initialState, action) {
  switch (action.type) {
    case PATCH_CAMPAIGN:
      return {
        ...state,
        campaign: { ...state.campaign, ...action.payload },
      };
    case PATCH_CAMPAIGN_CTA:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          callToAction: {
            ...state.campaign.callToAction,
            ...action.payload,
          },
        },
      };
    case RESET_CAMPAIGN:
      return {
        ...state,
        campaign: initialState.campaign,
      };
    case `${GET_MESSAGE_CAMPAIGNS}_SUCCESS`:
      return {
        ...state,
        paginatedJoynMessageCampaigns: action.payload,
      };
    case `${LOAD_CAMPAIGN_BY_ID}_SUCCESS`:
    case `${UPDATE_CAMPAIGN}_SUCCESS`:
      return {
        ...state,
        campaign: { ...action.payload },
      };
    default:
      return state;
  }
}

export default reducer;
