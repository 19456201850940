import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true /* useNextVariants: true was added to use Typography V2 while still on version 3.X.X of Material-UI. As of v4.0.0 (Q1 2019) Typography V2 will be default */,
  },
  palette: {
    primary: blue,
    secondary: orange,
  },
});

export default theme;
