import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { noop } from '../../../../../utils/utils';
import PublishCampaignConfirmationDialog from '../PublishCampaignConfirmationDialog/PublishCampaignConfirmationDialog';

export default class PublishCampaignButton extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    validateForm: PropTypes.func,
    onConfirmPublish: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    validateForm: noop,
    onConfirmPublish: noop,
  };

  state = {
    isDialogOpen: false,
  };

  validateAndShowConfirmDialog = () => {
    const formIsValid = this.props.validateForm();

    if (formIsValid) {
      this.showConfirmDialog();
    }
  };

  showConfirmDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  hideConfirmDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  confirmPublish = () => {
    this.hideConfirmDialog();
    this.props.onConfirmPublish();
  };

  render() {
    return (
      <div>
        <Button
          id="publishButton"
          color="primary"
          variant="contained"
          type="button"
          disabled={this.props.disabled}
          onClick={this.validateAndShowConfirmDialog}>
          Save & Publish Campaign
        </Button>

        <PublishCampaignConfirmationDialog
          isDialogOpen={this.state.isDialogOpen}
          onClose={this.hideConfirmDialog}
          onCancel={this.hideConfirmDialog}
          onConfirm={this.confirmPublish}
        />
      </div>
    );
  }
}
