import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PartnerSubformHeader from './PartnerSubformHeader';

const PartnerAuthenticationSettings = ({
  partner,
  fieldErrors,
  handleFieldChange,
  disabled = false,
}) => {
  const clientIdMaxLength = 30;
  const handleClientIdChange = (event) => {
    let newValue = event.target.value.replace(/\s/g, '_').toLowerCase();
    const newEvent = {
      target: {
        value: newValue,
      },
    };
    handleFieldChange('clientId')(newEvent);
  };

  return (
    <div
      style={{ width: '100%', marginTop: '.8rem', marginBottom: '.8rem' }}
      hidden={disabled}>
      <PartnerSubformHeader title="Keycloak Client Settings" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <TextField
          style={{ width: '50%' }}
          label="Client ID"
          type="text"
          value={partner.clientId ?? ''}
          onChange={handleClientIdChange}
          error={!!fieldErrors.clientId}
          helperText={`${(partner.clientId ?? '').length}/${clientIdMaxLength}`}
          margin="normal"
          inputProps={{
            maxLength: clientIdMaxLength,
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            textAlign: 'left',
            marginLeft: '.5rem',
          }}>
          <span
            role="img"
            aria-label={'info'}
            style={{ marginRight: '0.5rem', fontSize: '0.75rem' }}>
            ℹ️
          </span>
          <Typography variant="caption">
            If you set the client id, will create the client on Keycloak. If you
            remove, will delete the client on Keycloak.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PartnerAuthenticationSettings;
