import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { noop } from '../../../../utils/utils';

export default class CampaignExternalLinkText extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    onChange: noop,
    required: false,
  };

  render() {
    return (
      <TextField
        id="ctaTextInput"
        label="Button Text"
        value={this.props.value}
        onChange={this.props.onChange}
        margin="normal"
        helperText="max. 30 characters"
        inputProps={{ maxLength: '30' }}
        fullWidth
        required={this.props.required}
      />
    );
  }
}
