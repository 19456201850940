import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { generatePath } from 'react-router-dom';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import { history } from '../../../store/store';
import styles from '../Partners.module.css';
import CONSTANTS from '../../../constants';
import SearchBar from './components/SearchBar';
import partnerOverviewService from './PartnerOverviewService';
import PartnerOverviewTable from './components/PartnerOverviewTable/PartnerOverviewTable';

export default class PartnerOverview extends React.Component {
  state = {
    partners: [],
    isBusy: false,
    searchTerm: '',
  };

  componentDidMount() {
    let previousSearch = sessionStorage.getItem('partner_search');
    if (previousSearch != null) {
      let json = JSON.parse(previousSearch);
      this.loadPartners(json.searchTerm);
    } else {
      this.loadPartners();
    }
  }

  onCreatePartner = () => {
    history.push(generatePath(CONSTANTS.ROUTES.PARTNER_CREATE.path));
  };

  loadPartners = (searchTerm) => {
    this.setState({
      ...this.state,
      isBusy: true,
      searchTerm: searchTerm,
    });

    partnerOverviewService
      .searchPartners(searchTerm)
      .then((data) => {
        this.setState({
          ...this.state,
          partners: data.content,
        });
      })
      .catch((error) => toastr.error('Could not load partners', error.message))
      .finally(() => {
        this.setState({
          ...this.state,
          isBusy: false,
        });
      });
  };

  render() {
    return (
      <div className={styles.partnerOverview}>
        <Tooltip
          title="Create Partner"
          placement="left"
          classes={{ tooltip: styles.tooltip }}>
          <Fab
            id="createPartnerButton"
            className={styles.button}
            color="primary"
            aria-label="Create"
            onClick={this.onCreatePartner}>
            <AddIcon />
          </Fab>
        </Tooltip>
        <h1>Partners</h1>
        <div className={styles.searchPartners}>
          <SearchBar
            placeholder="Search partner by code or name"
            onSearch={this.loadPartners}
          />
        </div>
        <HorizontalSpacer />

        {this.state.isBusy ? (
          <CircularProgress id="loadingSpinner" />
        ) : (
          <div>
            {this.state.partners.length ? (
              <PartnerOverviewTable partners={this.state.partners} />
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
      </div>
    );
  }
}
