import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import ButtonWithConfirmation from '../../../../../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import ShopRepository from '../../../../../../../repository/shop/ShopRepository';
import getError from '../../../../../../../utils/utils';
import partnerOverviewService from '../../../../../../Partners/overview/PartnerOverviewService';

class AddApiKeyButton extends React.Component {
  static propTypes = {
    shopId: PropTypes.number,
    onApiKeyAdded: PropTypes.func,
  };

  state = {
    isBusy: false,
    partners: [],
    permissions: [],
  };

  loadPartners = () => {
    partnerOverviewService.allPartners(null).then((data) => {
      this.setState({
        ...this.state,
        partners: data,
      });
    });
  };

  componentDidMount() {
    this.loadPartners();
  }

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  addApiKey = () => {
    this.setIsBusy(true);
    return new Promise((resolve, reject) => {
      const request = {
        partnerCode: this.state.newPartnerInput,
        permissions: this.state.permissions.join(','),
        shopId: this.props.shopId,
      };
      ShopRepository.addApiKey(request, this.props.shopId)
        .then((data) => {
          toastr.success('Success', `ApiKey created`);
          this.props.onApiKeyAdded(data);
        })
        .catch((error) => {
          toastr.error('Could not create apiKey', getError(error));
          reject();
        })
        .finally(() => {
          this.setIsBusy(false);
        });
    });
  };

  partnerKeyInputChanged = (event, value) => {
    this.setState({
      ...this.state,
      newPartnerInput: value,
    });
  };

  addPermission = (event, added) => {
    if (added) {
      this.setState({
        ...this.state,
        permissions: this.state.permissions.concat([event.target.value]),
      });
    } else {
      this.setState({
        ...this.state,
        permissions: this.state.permissions.filter(
          (e) => e !== event.target.value,
        ),
      });
    }
  };

  render() {
    const button = (
      <ButtonWithConfirmation
        buttonVariant="text"
        buttonText="Add ApiKey"
        onConfirm={this.addApiKey}
        cancelButtonText="Cancel"
        icon={<AddIcon />}
        confirmButtonText="Add"
        title="Add ApiKey"
        id="AddApiKey"
        message="Fill in the partner key of the partner you like to add:">
        <Autocomplete
          type="text"
          placeholder="partner key"
          id="newPartnerInput"
          options={this.state.partners}
          onChange={this.partnerKeyInputChanged}
          renderInput={(params) => <TextField {...params} label="Partner" />}
        />
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label="Loyalty Token"
            onChange={this.addPermission}
            value={'OP_CREATE_LOYALTY_TOKEN'}
            checked={this.state.permissions.includes('OP_CREATE_LOYALTY_TOKEN')}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Loyalty Tx"
            onChange={this.addPermission}
            value={'OP_CREATE_PARTNER_LOYALTY_TX'}
            checked={this.state.permissions.includes(
              'OP_CREATE_PARTNER_LOYALTY_TX',
            )}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Reward Tx"
            onChange={this.addPermission}
            value={'OP_CREATE_PARTNER_REWARD_TX'}
            checked={this.state.permissions.includes(
              'OP_CREATE_PARTNER_REWARD_TX',
            )}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Coupon Tx"
            onChange={this.addPermission}
            value={'OP_CREATE_PARTNER_COUPON_TX'}
            checked={this.state.permissions.includes(
              'OP_CREATE_PARTNER_COUPON_TX',
            )}
          />
        </FormGroup>
      </ButtonWithConfirmation>
    );
    return this.state.isBusy ? <CircularProgress /> : button;
  }
}

export default AddApiKeyButton;
