import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import CONSTANTS from '../../constants';
import { GuardedRoute } from '../../shared/components/User/GuardedRoute';
import UserChangeEmailContainer from './change-email/UserChangeEmailContainer';
import UpdateUserDetails from './details/updateUserDetails/UpdateUserDetails';
import UserDetails from './details/UserDetails';
import UserOverview from './overview/UserOverview';

class Users extends React.Component {
  render() {
    return (
      <Switch>
        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_READ_USERS]}
          exact
          path={CONSTANTS.ROUTES.USER_OVERVIEW.path}
          component={UserOverview}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_READ_USERS]}
          exact
          path={CONSTANTS.ROUTES.USER_DETAILS.path}
          component={UserDetails}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_CHANGE_USER_EMAIL]}
          exact
          path={CONSTANTS.ROUTES.USER_CHANGE_EMAIL.path}
          component={UserChangeEmailContainer}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}
          exact
          path={CONSTANTS.ROUTES.USER_DETAILS_UPDATE.path}
          component={UpdateUserDetails}
        />

        <Redirect to={CONSTANTS.ROUTES.FOUR_OH_FOUR.path} />
      </Switch>
    );
  }
}

export default withRouter(Users);
