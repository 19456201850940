import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { toastr } from 'react-redux-toastr';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { noop } from '../../../utils/utils';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

export default class ConfirmedMenuItem extends React.Component {
  static propTypes = {
    action: PropTypes.func,
    successAction: PropTypes.func,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    icon: PropTypes.element,
  };

  static defaultProps = {
    action: noop,
    successAction: noop,
    successMessage: 'Success',
    errorMessage: 'Something went wrong',
    confirmationTitle: 'Confirmation',
    confirmationContent: 'Are you sure you want to perform this action?',
  };

  state = {
    isDialogOpen: false,
  };

  showConfirmDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  hideConfirmDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  confirmSendRegistrationEmail = () => {
    this.hideConfirmDialog();
    return this.props
      .action()
      .then(this.onSuccess)
      .catch(this.showErrorMessage);
  };

  onSuccess = () => {
    this.props.successAction();
    toastr.success('Success', this.props.successMessage);
  };

  showErrorMessage = (error) =>
    toastr.error(this.props.errorMessage, this.getError(error));

  getError(error) {
    let message = '';

    if (error.response) {
      if (error.response.data) {
        if (error.response.data.errorMessage) {
          message = error.response.data.errorMessage;
          if (error.response.data.fieldErrors) {
            error.response.data.fieldErrors.forEach((e) => {
              message += ` ${e.field}: ${e.message}`;
            });
          }
        } else {
          message = error.message;
        }
      }
    } else {
      message = error.response;
    }

    return message;
  }

  render() {
    return (
      <div>
        <MenuItem onClick={this.showConfirmDialog}>
          <ListItemIcon>{this.props.icon}</ListItemIcon>
          <ListItemText>{this.props.label}</ListItemText>
        </MenuItem>

        <ConfirmationDialog
          isOpen={this.state.isDialogOpen}
          onClose={this.hideConfirmDialog}
          onCancel={this.hideConfirmDialog}
          onConfirm={this.confirmSendRegistrationEmail}
          dialogTitle={this.props.confirmationTitle}
          dialogContent={this.props.confirmationContent}
          confirmButtonText="Yes"
          cancelButtonText="Nope"
          icon={this.props.icon}
        />
      </div>
    );
  }
}
