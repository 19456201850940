import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { generatePath } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CategoryRepository from '../../repository/categories/CategoryRepository';
import HorizontalSpacer from '../../shared/components/HorizontalSpacer/HorizontalSpacer';
import NoDataFound from '../../shared/components/NoDataFound/NoDataFound';
import LinkButton from '../../shared/components/LinkButton/LinkButton';
import CONSTANTS from '../../constants';
import NavigationService from '../../shared/navigation/NavigationService';
import styles from './CategoriesOverview.module.css';

export default class CategoriesOverview extends React.Component {
  state = {
    categories: [],
    isBusy: false,
  };

  componentDidMount() {
    this.loadCategories();
  }

  createCategory = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.CATEGORIES_DETAIL.path, {
      categoryId: '-1',
    });
  };

  loadCategories = () => {
    this.setState({
      ...this.state,
      isBusy: true,
    });

    CategoryRepository.getAllCategories()
      .then((data) => {
        this.setState({
          ...this.state,
          categories: data,
        });
      })
      .catch((error) =>
        toastr.error('Could not load categories', error.message),
      )
      .finally(() => {
        this.setState({
          ...this.state,
          isBusy: false,
        });
      });
  };

  render() {
    return (
      <div className={styles.categoryOverview}>
        <h1>Categories</h1>
        <HorizontalSpacer />

        <div className={styles.addButton}>
          <Button
            id="createCategoryButton"
            color="secondary"
            onClick={this.createCategory}>
            <AddIcon />
            Add
          </Button>
        </div>

        {this.state.isBusy ? (
          <CircularProgress id="loadingSpinner" />
        ) : (
          <div>
            {this.state.categories.length ? (
              <Paper
                className={styles.tableWrapper}
                id="categoriesOverviewTable">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Icon</TableCell>
                      <TableCell>Super - Subcategory</TableCell>
                      <TableCell>Times used</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.categories.map((c) => (
                      <TableRow key={c.id} hover>
                        <TableCell>
                          {c.nameEnglish}
                          <p className={styles.translations}>
                            {c.translatedNames}
                          </p>
                        </TableCell>
                        <TableCell>
                          <i
                            className={`fa-duotone fa-${c.icon} fa-2xl`}
                            title={c.icon}></i>
                        </TableCell>
                        <TableCell>
                          {c.superCategory} - {c.subCategory}
                        </TableCell>
                        <TableCell>{c.timesUsed}</TableCell>
                        <TableCell align="right">
                          <LinkButton
                            text="Details"
                            linkTo={generatePath(
                              CONSTANTS.ROUTES.CATEGORIES_DETAIL.path,
                              {
                                categoryId: c.id,
                              },
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
      </div>
    );
  }
}
