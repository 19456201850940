import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { Checkbox, Chip, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import HasPermission from '../../../shared/components/User/HasPermission';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import CONSTANTS from '../../../constants';
import BackIconButton from '../../../shared/components/BackIconButton/BackIconButton';
import MerchantRepository from '../../../repository/merchant/MerchantRepository';
import Field from '../../../shared/components/Field/Field';
import styles from './MerchantDetails.module.css';
import MerchantShopOverview from './components/MerchantShopOverview/MerchantShopOverview';
import MerchantDetailActionMenu from './components/MerchantDetailActionMenu/MerchantDetailActionMenu';
import AuthUserOverview from './components/AuthUserOverview/AuthUserOverview';
import MerchantSettings from './components/MerchantSettings/MerchantSettings';

class MerchantDetails extends React.Component {
  static propTypes = {
    merchantId: PropTypes.number,
  };

  static defaultProps = {
    merchantId: 0,
  };

  state = {
    isBusy: false,
    merchant: {
      shops: [],
    },
  };

  componentDidMount() {
    this.loadMerchant();
  }

  loadMerchant = () => {
    this.setState({
      ...this.state,
      isBusy: true,
    });

    MerchantRepository.getMerchantById(this.props.merchantId)
      .then((merchant) => {
        this.setState({
          ...this.state,
          merchant,
        });
      })
      .catch((error) => toastr.error('Could not load merchant', error.message))
      .finally(() => {
        this.setState({
          ...this.state,
          isBusy: false,
        });
      });
  };

  render() {
    const merchantDetails = (
      <div>
        <Paper>
          <div className={styles.cardHeader}>
            <BackIconButton linkTo={CONSTANTS.ROUTES.MERCHANT_OVERVIEW.path} />
            <Typography variant="h5" className={styles.merchantName}>
              {this.state.merchant.name} - {this.state.merchant.id}
            </Typography>
            <HasPermission
              permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}>
              <MerchantDetailActionMenu
                merchantId={this.props.merchantId}
                canSendRegistrationEmail={!this.state.merchant.deleted}
                canRestoreMerchant={this.state.merchant.deleted}
                canDeleteMerchant={!this.state.merchant.deleted}
                onStateChange={this.loadMerchant}
              />
            </HasPermission>
            {this.state.merchant.deleted ? (
              <Chip label="Deleted" color="secondary" />
            ) : this.state.merchant.dateToBeDeleted ? (
              <Chip label="Marked to be deleted" />
            ) : null}
          </div>
          <div className={styles.merchantForm}>
            <Field label="Name" value={this.state.merchant.name} />
            <Field label="E-mail" value={this.state.merchant.email} />
            <Field label="Language" value={this.state.merchant.language} />
            <Field label="Source" value={this.state.merchant.sourceCode} />
            <Field label="Source Id" value={this.state.merchant.sourceId} />
            <Field label="VAT number" value={this.state.merchant.vatNumber} />
            <Field
              label="Loyalty program"
              value={this.state.merchant.loyaltyProgram}
            />
            <Field label="Address" value={this.getAddress()} />
            <Field label="Phone" value={this.state.merchant.phoneNumber} />
            <Field
              label="Priceplan"
              value={this.state.merchant.pricePlanName}
            />
            <Field
              label="Date to be deleted"
              value={
                this.state.merchant.dateToBeDeleted
                  ? moment(this.state.merchant.dateToBeDeleted).format(
                      'DD/MM/YYYY',
                    )
                  : null
              }
            />
            <Field
              label="Date when merchant was deleted"
              value={
                this.state.merchant.deletionDate
                  ? moment(this.state.merchant.deletionDate).format(
                      'DD/MM/YYYY HH:mm',
                    )
                  : null
              }
            />
            <FormControlLabel
              className={styles.checkBoxContainer}
              disabled
              control={<Checkbox checked={this.state.merchant.sharedLoyalty} />}
              label="Shared loyalty"
            />
            <MerchantSettings
              features={this.state.merchant.accountFeaturesByType}
              merchantId={this.state.merchant.id}
            />
          </div>
        </Paper>
        <HorizontalSpacer />
        <h2>Shops</h2>
        <MerchantShopOverview
          shops={this.state.merchant.shops}
          merchantId={this.state.merchant.id}
        />
        <HorizontalSpacer />
        <h2>Users</h2>
        {this.merchantHasUsersManagement() ? (
          <p className={styles.remark}>
            This merchant uses the new User management feature, to manage users
            go to the business portal.
          </p>
        ) : (
          <span />
        )}
        <HasPermission
          permissions={[CONSTANTS.PERMISSIONS.OP_READ_MERCHANTS_AUTH_USER]}>
          <AuthUserOverview
            merchantId={this.state.merchant.id}
            readOnly={this.merchantHasUsersManagement()}
          />
        </HasPermission>
      </div>
    );

    return (
      <div className={styles.merchantDetails}>
        <HorizontalSpacer size="small" />

        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>
            {this.state.merchant.id ? merchantDetails : <NoDataFound />}
          </div>
        )}
      </div>
    );
  }

  getAddress() {
    let address = [];
    if (this.state.merchant.address) {
      address.push(this.state.merchant.address);
    }

    if (this.state.merchant.zipCode || this.state.merchant.city) {
      let city = '';
      city += this.state.merchant.city ? this.state.merchant.city : '';
      city += this.state.merchant.zipCode
        ? ' ' + this.state.merchant.zipCode
        : '';

      address.push(city);
    }

    if (this.state.merchant.countryCode) {
      address.push(this.state.merchant.countryCode);
    }

    return address.join(', ');
  }

  merchantHasUsersManagement() {
    if (!this.state.merchant.accountFeatures) {
      return false;
    } else if (
      this.state.merchant.accountFeatures.includes('USER_MANAGEMENT')
    ) {
      return true;
    }
  }
}

export default withRouter(MerchantDetails);
