import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu/Menu';
import { withRouter } from 'react-router-dom';
import styles from './ActionsMenu.module.css';

class ActionsMenu extends React.Component {
  state = {
    anchorElement: null,
  };

  openMenu = (event) => {
    this.setState({ anchorElement: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorElement: null });
  };

  render() {
    return (
      <div className={styles.actionsMenu}>
        <IconButton id="actionsMenuToggle" onClick={this.openMenu}>
          <MoreVertIcon className={styles.icon} />
        </IconButton>

        <Menu
          id="actionsMenu"
          anchorEl={this.state.anchorElement}
          open={!!this.state.anchorElement}
          onClose={this.closeMenu}>
          {this.props.children}
        </Menu>
      </div>
    );
  }
}

export default withRouter(ActionsMenu);
