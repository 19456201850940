import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { noop } from '../../../utils/utils';

class GenderSelect extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onSelect: PropTypes.func,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    id: 'genderSelect',
    value: '',
    onSelect: noop,
    margin: 'none',
    fullWidth: false,
    required: false,
    disabled: false,
  };

  render() {
    return (
      <FormControl
        margin={this.props.margin}
        fullWidth={this.props.fullWidth}
        required={this.props.required}
        disabled={this.props.disabled}>
        <InputLabel shrink htmlFor={this.props.id}>
          Gender
        </InputLabel>
        <Select
          value={this.props.value}
          onChange={this.props.onSelect}
          input={<Input name="gender" id={this.props.id} />}
          displayEmpty
          name="gender">
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          <MenuItem value="MALE">Male</MenuItem>
          <MenuItem value="FEMALE">Female</MenuItem>
        </Select>
      </FormControl>
    );
  }
}

export default GenderSelect;
