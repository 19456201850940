import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/core/SvgIcon/SvgIcon';
import { MenuItem } from '@material-ui/core';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { noop } from '../../../utils/utils';

export default class MenuItemWithConfirmation extends React.Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    param: PropTypes.any,
    message: PropTypes.string,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    icon: PropTypes.element,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    id: PropTypes.string,
  };

  static defaultProps = {
    onConfirm: noop,
    param: noop,
    message: 'Are you sure?',
    title: 'Title',
    buttonText: 'Ok',
    icon: <CheckIcon />,
    confirmButtonText: 'Yes, looks good',
    cancelButtonText: 'Nope',
  };

  state = {
    isDialogOpen: false,
  };

  showConfirmDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  hideConfirmDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  confirmCreation = () => {
    let onConfirmResult = this.props.onConfirm(this.props.param);
    if (onConfirmResult) {
      onConfirmResult.then(() => {
        this.hideConfirmDialog();
      });
    }
  };

  render() {
    const dialogText = (
      <div>
        <h3>{this.props.message}</h3>
        <div>{this.props.children}</div>
      </div>
    );

    return (
      <div>
        {this.getButton()}
        <ConfirmationDialog
          isOpen={this.state.isDialogOpen}
          onConfirm={this.confirmCreation}
          onClose={this.hideConfirmDialog}
          onCancel={this.hideConfirmDialog}
          dialogTitle={this.props.title}
          dialogContent={dialogText}
          confirmButtonText={this.props.confirmButtonText}
          cancelButtonText={this.props.cancelButtonText}
          icon={this.props.icon}
        />
      </div>
    );
  }

  getButton() {
    return (
      <MenuItem id={this.getButtonId()} onClick={this.showConfirmDialog}>
        {this.props.buttonText}
      </MenuItem>
    );
  }

  getButtonId() {
    return this.props.id
      ? `${this.props.id}_button`
      : `${this.props.buttonText.replace(' ', '_')}_button`;
  }
}
