import MerchantRepository from '../../../repository/merchant/MerchantRepository';

class MerchantOverviewService {
  searchMerchants(searchTerm, includeDeleted) {
    return MerchantRepository.searchMerchants({
      ...(searchTerm ? { searchTerm } : {}),
      includeDeleted,
    });
  }
}

const merchantOverviewService = new MerchantOverviewService();

export default merchantOverviewService;
