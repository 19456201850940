import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import CONSTANTS from '../../../constants';

class Thunks {
  goToUserDetails = (userId) => (dispatch) =>
    dispatch(
      push(
        generatePath(CONSTANTS.ROUTES.USER_DETAILS.path, {
          userId,
        }),
      ),
    );

  goToUserChangeEmail = (userId) => (dispatch) =>
    dispatch(
      push(
        generatePath(CONSTANTS.ROUTES.USER_CHANGE_EMAIL.path, {
          userId,
        }),
      ),
    );
}

const thunks = new Thunks();
export default thunks;
export const { goToUserDetails, goToUserChangeEmail } = thunks;
