import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import { Autocomplete } from '@material-ui/lab';
import { toastr } from 'react-redux-toastr';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EntityForm from '../../../shared/components/EntityForm/EntityForm';
import styles from '../Partners.module.css';
import PartnersMPIntegrationsContainer from './PartnersMPIntegrationsContainer';
import PartnerAuthenticationSettings from './PartnerAuthenticationSettings';

const PartnerForm = ({
  partner,
  fieldErrors,
  handleFieldChange,
  stateInputChanged,
  updateIntegrations,
  onSave,
  isCreate,
  displayMerchantPortalIntegrationsContainer = true,
}) => {
  const handleOnSave = () => {
    const hasIntegrationEnabled = partner.integrations?.find(
      (integration) => integration.showInMerchantPortal === true,
    );
    if (hasIntegrationEnabled) {
      const didntSelectApiKeyPermission = partner.integrations?.find(
        (integration) =>
          !integration.permissions || integration.permissions.length === 0,
      );
      if (didntSelectApiKeyPermission) {
        toastr.error(
          'At least one Default Api-Key permission must be selected',
        );
        return;
      }
    }

    if (
      partner.isOpenApiClient &&
      (!partner.clientId || partner.clientId.trim() === '')
    ) {
      toastr.error('ClientId is required when OpenApi Client is selected.');
      return;
    }

    onSave(partner);
  };

  return (
    <div style={{ width: '100%' }}>
      <EntityForm
        onSave={handleOnSave}
        onSaveText={isCreate ? 'Create' : 'Save Changes'}>
        <div className={styles.partnerForm}>
          <TextField
            label="Code"
            type="text"
            value={partner.partnerCode ?? ''}
            onChange={handleFieldChange('partnerCode')}
            error={!!fieldErrors.partnerCode}
            helperText={fieldErrors.partnerCode}
            margin="normal"
            fullWidth
            required
            disabled={!isCreate}
          />
          <TextField
            label="Name"
            type="text"
            value={partner.name ?? ''}
            onChange={handleFieldChange('name')}
            error={!!fieldErrors.name}
            helperText={fieldErrors.name}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="Description"
            type="text"
            value={partner.description ?? ''}
            onChange={handleFieldChange('description')}
            error={!!fieldErrors.description}
            helperText={fieldErrors.description}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="Email"
            type="email"
            value={partner.email ?? ''}
            onChange={handleFieldChange('email')}
            error={!!fieldErrors.email}
            helperText={fieldErrors.email}
            margin="normal"
            fullWidth
          />
          <Autocomplete
            type="text"
            label="state"
            id="stateInput"
            value={partner.state ?? 'ACTIVE'}
            options={['ACTIVE', 'INACTIVE', 'DELETED']}
            onChange={stateInputChanged}
            required
            disableClearable
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'State * (for now has no effect)'}
              />
            )}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={partner.isOpenApiClient ?? false}
                onChange={(event) =>
                  handleFieldChange('isOpenApiClient')({
                    target: { value: event.target.checked },
                  })
                }
                color="primary"
              />
            }
            label="Is OpenApi Client"
          />

          <PartnersMPIntegrationsContainer
            integrations={partner?.integrations}
            updateIntegrations={updateIntegrations}
            disabled={!displayMerchantPortalIntegrationsContainer}
          />
          <PartnerAuthenticationSettings
            partner={partner}
            fieldErrors={fieldErrors}
            handleFieldChange={handleFieldChange}
            disabled={!displayMerchantPortalIntegrationsContainer}
          />
        </div>
      </EntityForm>
    </div>
  );
};

export default PartnerForm;
