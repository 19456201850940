import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import TextField from '@material-ui/core/TextField/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import EntityForm from '../../../shared/components/EntityForm/EntityForm';
import CONSTANTS from '../../../constants';
import MerchantRepository from '../../../repository/merchant/MerchantRepository';
import NavigationService from '../../../shared/navigation/NavigationService';
import LanguageDropDown from '../../../shared/components/LanguageDropDown/LanguageDropDown';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import CRMRepository from '../../../repository/crm/CRMRepository';
import styles from './MerchantCreate.module.css';
import OdooMerchantAutocomplete from './OdooMerchantAutocomplete';

export default class MerchantCreate extends React.Component {
  state = {
    isBusy: false,
    fieldErrors: {},
    subscriptions: [],
    merchant: {
      sourceCode: 'ODOO',
      loyaltyProgram: 'PERCENTAGE',
      country: '',
      pricePlan: 'JOYN_8',
    },
  };

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  setFieldError = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [key]: value,
      },
    }));
  };

  updateMerchantProp = (prop, value) => {
    this.setState((prevState) => ({
      ...prevState,
      merchant: {
        ...prevState.merchant,
        [prop]: value,
      },
    }));
  };

  handleFieldChange = (prop) => (event) => {
    this.updateMerchantProp(prop, event.target.value);
    this.setFieldError(prop, null);
  };

  onSelected = (merchant) => {
    this.setState({
      ...this.state,
      merchant: merchant,
    });

    CRMRepository.readMerchantSubscriptionData(merchant.id).then((data) => {
      this.setState({
        ...this.state,
        subscriptions: data.subscriptions,
      });
      this.updateMerchantProp('pricePlan', data.pricePlanGuess);
    });
  };

  toMerchantDetails = (id) => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.MERCHANT_DETAILS.path, {
      merchantId: id,
    });
  };

  backToMerchantOverview = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.MERCHANT_OVERVIEW.path);
  };

  save = () => {
    const handleUpdateFailure = (error) => {
      const { response } = error;
      if (response.status === 409 && response.data.error) {
        toastr.error(
          response.data.error.title,
          response.data.error.description,
        );
      } else if (response.status === 400 && response.data.fieldErrors) {
        response.data.fieldErrors.forEach((fieldError) =>
          this.setFieldError(fieldError.field, fieldError.message),
        );
      } else {
        toastr.error('Could not save merchant', error.message);
      }
    };

    MerchantRepository.createMerchant(this.state.merchant)
      .then((data) => this.toMerchantDetails(data.id))
      .catch((error) => handleUpdateFailure(error));
  };

  renderMerchantForm() {
    return (
      <div className={styles.merchantDetailsCardWrapper}>
        <HorizontalSpacer />
        <OdooMerchantAutocomplete onSelected={this.onSelected} />
        <HorizontalSpacer />
        <EntityForm onCancel={this.backToMerchantOverview} onSave={this.save}>
          <TextField
            label="Merchant name"
            InputLabelProps={{ shrink: true }}
            type="text"
            id="name"
            value={this.state.merchant.name}
            onChange={this.handleFieldChange('name')}
            error={!!this.state.fieldErrors.name}
            helperText={this.state.fieldErrors.name}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="E-mail"
            InputLabelProps={{ shrink: true }}
            type="email"
            id="email"
            value={this.state.merchant.email}
            onChange={this.handleFieldChange('email')}
            error={!!this.state.fieldErrors.email}
            helperText={this.state.fieldErrors.email}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="Enterprise number"
            InputLabelProps={{ shrink: true }}
            type="text"
            id="vatNumber"
            value={this.state.merchant.vatNumber}
            onChange={this.handleFieldChange('vatNumber')}
            error={!!this.state.fieldErrors.vatNumber}
            helperText={this.state.fieldErrors.vatNumber}
            margin="normal"
            fullWidth
            required
          />
          <LanguageDropDown
            label="Language"
            InputLabelProps={{ shrink: true }}
            value={this.state.merchant.language}
            id="language"
            onChange={this.handleFieldChange('language')}
            margin="normal"
            fullWidth
            required
            error={!!this.state.fieldErrors.language}
          />
          <TextField
            label="Source ID"
            InputLabelProps={{ shrink: true }}
            type="text"
            value={this.state.merchant.sourceId}
            id="sourceId"
            onChange={this.handleFieldChange('sourceId')}
            error={!!this.state.fieldErrors.sourceId}
            helperText={this.state.fieldErrors.sourceId}
            margin="normal"
            fullWidth
            required
          />
          <FormControl
            margin="normal"
            id="sourceCode"
            fullWidth
            error={!!this.state.fieldErrors.sourceCode}>
            <InputLabel shrink={true}>Source code</InputLabel>
            <Select
              value={this.state.merchant.sourceCode}
              onChange={this.handleFieldChange('sourceCode')}>
              <MenuItem id="ODOO" value="ODOO">
                ODOO
              </MenuItem>
              <MenuItem id="HUBSPOT" value="HUBSPOT">
                HUBSPOT
              </MenuItem>
              <MenuItem id="NAVISION" value="NAVISION">
                NAVISION
              </MenuItem>
              <MenuItem id="DYNAMICS" value="DYNAMICS">
                Dynamics
              </MenuItem>
              <MenuItem id="QUSTO" value="QUSTO">
                Qusto
              </MenuItem>
              <MenuItem id="ONLINE_ONBOARDING" value="ONLINE_ONBOARDING">
                ONLINE_ONBOARDING
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            margin="normal"
            id="loyaltyProgram"
            fullWidth
            error={!!this.state.fieldErrors.loyaltyProgram}>
            <InputLabel shrink={true}>Loyalty program</InputLabel>
            <Select
              displayEmpty={true}
              value={this.state.merchant.loyaltyProgram}
              onChange={this.handleFieldChange('loyaltyProgram')}>
              <MenuItem id="PERCENTAGE" value="PERCENTAGE">
                Percentage
              </MenuItem>
              <MenuItem id="FIXED" value="FIXED">
                Fixed
              </MenuItem>
              <MenuItem
                id="FIXED_WITH_MULTIPLICATION"
                value="FIXED_WITH_MULTIPLICATION">
                Fixed with multiplication
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.pricePlan}>
            <InputLabel shrink={true}>Price Plan</InputLabel>
            <Select
              displayEmpty={true}
              id="pricePlan"
              value={this.state.merchant.pricePlan}
              onChange={this.handleFieldChange('pricePlan')}>
              <MenuItem id="JOYN_5" value="JOYN_5">
                Lite 2022
              </MenuItem>
              <MenuItem id="JOYN_6" value="JOYN_6">
                Essential 2022
              </MenuItem>
              <MenuItem id="JOYN_7" value="JOYN_7">
                Connect 2022
              </MenuItem>
              <MenuItem id="JOYN_8" value="JOYN_8">
                Expert 2022
              </MenuItem>
              <MenuItem id="FREEMIUM" value="FREEMIUM">
                Freemium
              </MenuItem>
            </Select>
          </FormControl>
          <div>
            {!!this.state.subscriptions &&
            this.state.subscriptions.length > 0 ? (
              <div>
                <div>Odoo subscriptions</div>
                {this.state.subscriptions.map((s) => (
                  <div>
                    {s.startDate} {s.product} {s.tags} {s.code} {s.tags}
                  </div>
                ))}
              </div>
            ) : (
              undefined
            )}
          </div>
          <FormControl
            margin="normal"
            id="country"
            fullWidth
            error={!!this.state.fieldErrors.country}>
            <InputLabel shrink={true}>Country</InputLabel>
            <Select
              displayEmpty={true}
              value={this.state.merchant.country}
              onChange={this.handleFieldChange('country')}>
              <MenuItem id="BE" value="BE">
                Belgium
              </MenuItem>
              <MenuItem id="FR" value="FR">
                France
              </MenuItem>
              <MenuItem id="DE" value="DE">
                Germany
              </MenuItem>
              <MenuItem id="GB" value="GB">
                Great Brittan
              </MenuItem>
              <MenuItem id="LU" value="LU">
                Luxemburg
              </MenuItem>
              <MenuItem id="NL" value="NL">
                Netherlands
              </MenuItem>
              <MenuItem id="PT" value="PT">
                Portugal
              </MenuItem>
              <MenuItem value="">Other</MenuItem>
            </Select>
          </FormControl>
        </EntityForm>
      </div>
    );
  }
  render() {
    return (
      <div className={styles.merchantDetailsUpdate}>
        <h1>Edit merchant details</h1>

        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>
            {this.state.merchant ? this.renderMerchantForm() : <NoDataFound />}
          </div>
        )}
      </div>
    );
  }
}
