import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import { path } from 'ramda';
import TextField from '@material-ui/core/TextField/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import EntityForm from '../../../shared/components/EntityForm/EntityForm';
import CONSTANTS from '../../../constants';
import ShopRepository from '../../../repository/shop/ShopRepository';
import NavigationService from '../../../shared/navigation/NavigationService';
import LanguageDropDown from '../../../shared/components/LanguageDropDown/LanguageDropDown';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import { CURRENCIES } from '../../../shared/constants/currencies';
import CRMRepository from '../../../repository/crm/CRMRepository';
import styles from './ShopDetailsUpdate.module.css';
import ShopChurnReasonContainer from './components/churn/ShopChurnReasonContainer';

export default class ShopDetailsUpdate extends React.Component {
  state = {
    isBusy: false,
    fieldErrors: {},
  };

  componentDidMount() {
    const shopId = parseInt(
      path(['match', 'params', 'shopId'], this.props),
      10,
    );
    this.loadShopById(shopId);
  }

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  setShop(shop) {
    const shopToSet = {
      ...shop,
      churnReasonId: shop?.shopChurnReason?.churnReason?.id,
    };

    this.setState({
      ...this.state,
      shop: shopToSet,
    });
  }

  setFieldError = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [key]: value,
      },
    }));
  };

  updateShopProp = (prop, value) => {
    this.setState((prevState) => ({
      ...prevState,
      shop: {
        ...prevState.shop,
        [prop]: value,
      },
    }));
  };

  handleFieldChange = (prop) => (event) => {
    this.updateShopProp(prop, event.target.value);
    this.setFieldError(prop, null);
  };

  backToShopDetails = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.SHOP_DETAILS.path, {
      shopId: this.state.shop.id,
    });
  };

  handleUpdateFailure = (error) => {
    const { response } = error;
    if (response.status === 409 && response.data.error) {
      toastr.error(response.data.error.title, response.data.error.description);
    } else if (response.status === 400 && response.data.fieldErrors) {
      response.data.fieldErrors.forEach((fieldError) =>
        this.setFieldError(fieldError.field, fieldError.message),
      );
    } else {
      toastr.error('Could not save shop', error.message);
    }
  };

  save = () => {
    ShopRepository.updateShop(this.state.shop)
      .then(() => this.backToShopDetails())
      .catch((error) => this.handleUpdateFailure(error));
  };

  loadShopById(shopId) {
    this.setIsBusy(true);
    ShopRepository.getShopById(shopId)
      .then((shop) => this.setShop(shop))
      .catch((error) => toastr.error('Could not load shop', error.message))
      .finally(() => this.setIsBusy(false));
  }

  mapsUrl() {
    return (
      'https://www.google.be/maps?q=' +
      this.state.shop.latitude +
      ',' +
      this.state.shop.longitude +
      '&key=AIzaSyBUQ1cXk4eAxHV-AgVUPB-PtWc1g0v1XJ4'
    );
  }

  setLatLong = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      shop: {
        ...prevState.shop,
        latitude: data.latitude,
        longitude: data.longitude,
      },
    }));
  };

  geoLookup = () => {
    CRMRepository.geoCode({
      zip: this.state.shop.zipCode,
      city: this.state.shop.city,
      countryCode: this.state.shop.country,
      street: this.state.shop.address,
    })
      .then((data) => this.setLatLong(data))
      .catch((error) =>
        toastr.error('Could not look up lat/long', error.message),
      );
  };

  latState() {
    if (
      this.state.shop.latitude &&
      (this.state.shop.latitude > 60 || this.state.shop.latitude < 40)
    ) {
      return styles.latLongNok;
    }
    return styles.latLongOk;
  }

  longState() {
    if (
      this.state.shop.longitude &&
      (this.state.shop.longitude > 10 || this.state.shop.longitude < 0)
    ) {
      return styles.latLongNok;
    }
    return styles.latLongOk;
  }

  onSelectChurnReason = (churnReason) => {
    this.setState((prevState) => ({
      ...prevState,
      shop: {
        ...prevState.shop,
        churnReason: churnReason,
        churnReasonId: churnReason?.id,
      },
    }));
  };

  renderShopForm() {
    return (
      <div className={styles.shopDetailsCardWrapper}>
        <HorizontalSpacer />
        <EntityForm onCancel={this.backToShopDetails} onSave={this.save}>
          <TextField
            label="Name"
            type="text"
            value={this.state.shop.name}
            onChange={this.handleFieldChange('name')}
            error={!!this.state.fieldErrors.name}
            helperText={this.state.fieldErrors.name}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="E-mail"
            type="email"
            value={this.state.shop.email}
            onChange={this.handleFieldChange('email')}
            error={!!this.state.fieldErrors.email}
            helperText={this.state.fieldErrors.email}
            margin="normal"
            fullWidth
          />
          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.country}>
            <InputLabel>Country code</InputLabel>
            <Select
              value={this.state.shop.country}
              onChange={this.handleFieldChange('country')}>
              <MenuItem value="" />
              <MenuItem value="BE">BE</MenuItem>
              <MenuItem value="NL">NL</MenuItem>
              <MenuItem value="LU">LU</MenuItem>
              <MenuItem value="FR">FR</MenuItem>
              <MenuItem value="GB">GB</MenuItem>
              <MenuItem value="DE">DE</MenuItem>
            </Select>
          </FormControl>
          <LanguageDropDown
            label="Language"
            value={this.state.shop.language}
            onChange={this.handleFieldChange('language')}
            margin="normal"
            fullWidth
            required
            allowEmpty={true}
            error={!!this.state.fieldErrors.language}
          />
          <TextField
            label="Source ID"
            type="text"
            value={this.state.shop.sourceId}
            onChange={this.handleFieldChange('sourceId')}
            error={!!this.state.fieldErrors.sourceId}
            helperText={this.state.fieldErrors.sourceId}
            margin="normal"
            fullWidth
            required
          />
          <FormControl
            margin="normal"
            required
            fullWidth
            error={!!this.state.fieldErrors.sourceCode}>
            <InputLabel>Source code</InputLabel>
            <Select
              value={this.state.shop.sourceCode}
              onChange={this.handleFieldChange('sourceCode')}>
              <MenuItem id="ODOO" value="ODOO">
                ODOO
              </MenuItem>
              <MenuItem id="NAVISION" value="NAVISION">
                NAVISION
              </MenuItem>
              <MenuItem value="DYNAMICS">Dynamics</MenuItem>
              <MenuItem value="QUSTO">Qusto</MenuItem>
              <MenuItem value="ONLINE_ONBOARDING">ONLINE_ONBOARDING</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.currencyCode}>
            <InputLabel>Currency</InputLabel>
            <Select
              label="Currency"
              value={this.state.shop.currencyCode}
              margin="normal"
              onChange={this.handleFieldChange('currencyCode')}
              fullWidth
              required
              error={!!this.state.fieldErrors.currencyCode}
              displayEmpty>
              {Object.keys(CURRENCIES).map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {CURRENCIES[currency]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Phone number"
            type="tel"
            value={this.state.shop.phone}
            onChange={this.handleFieldChange('phone')}
            error={!!this.state.fieldErrors.phone}
            helperText={this.state.fieldErrors.phone}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Facebook link"
            type="text"
            value={this.state.shop.facebookLink}
            onChange={this.handleFieldChange('facebookLink')}
            error={!!this.state.fieldErrors.facebookLink}
            helperText={this.state.fieldErrors.facebookLink}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Website"
            type="text"
            value={this.state.shop.website}
            onChange={this.handleFieldChange('website')}
            error={!!this.state.fieldErrors.website}
            helperText={this.state.fieldErrors.website}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Webshop"
            type="text"
            value={this.state.shop.webshop}
            onChange={this.handleFieldChange('webshop')}
            error={!!this.state.fieldErrors.webshop}
            helperText={this.state.fieldErrors.webshop}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Address"
            type="text"
            value={this.state.shop.address}
            onChange={this.handleFieldChange('address')}
            error={!!this.state.fieldErrors.address}
            helperText={this.state.fieldErrors.address}
            margin="normal"
            fullWidth
          />
          <TextField
            label="City"
            type="text"
            value={this.state.shop.city}
            onChange={this.handleFieldChange('city')}
            error={!!this.state.fieldErrors.city}
            helperText={this.state.fieldErrors.city}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Zip code"
            type="text"
            value={this.state.shop.zipCode}
            onChange={this.handleFieldChange('zipCode')}
            error={!!this.state.fieldErrors.zipCode}
            helperText={this.state.fieldErrors.zipCode}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Latitude (±50)"
            type="number"
            value={this.state.shop.latitude}
            onChange={this.handleFieldChange('latitude')}
            error={!!this.state.fieldErrors.latitude}
            helperText={this.state.fieldErrors.latitude}
            className={this.latState()}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="Longitude (±5)"
            type="number"
            value={this.state.shop.longitude}
            onChange={this.handleFieldChange('longitude')}
            error={!!this.state.fieldErrors.longitude}
            helperText={this.state.fieldErrors.longitude}
            className={this.longState()}
            margin="normal"
            fullWidth
            required
          />
          <div>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={this.geoLookup}>
              Lookup lat/long
            </Button>
          </div>
          <a href={this.mapsUrl()} target="_blank" rel="noopener noreferrer">
            Show on map
          </a>
          <TextField
            label="Description"
            multiline={true}
            type="text"
            value={this.state.shop.description}
            onChange={this.handleFieldChange('description')}
            error={!!this.state.fieldErrors.description}
            helperText={this.state.fieldErrors.description}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Date to be deleted"
            InputLabelProps={{ shrink: true }}
            type="date"
            value={this.state.shop.dateToBeDeleted}
            onChange={this.handleFieldChange('dateToBeDeleted')}
            error={!!this.state.fieldErrors.dateToBeDeleted}
            helperText={this.state.fieldErrors.dateToBeDeleted}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Subscription start date"
            InputLabelProps={{ shrink: true }}
            type="date"
            value={this.state.shop.subscriptionStartDate}
            onChange={this.handleFieldChange('subscriptionStartDate')}
            error={!!this.state.fieldErrors.subscriptionStartDate}
            helperText={this.state.fieldErrors.subscriptionStartDate}
            margin="normal"
            fullWidth
          />
          <HorizontalSpacer />
          <ShopChurnReasonContainer
            selectedChurnReason={this.state.shop?.shopChurnReason?.churnReason}
            onSelectChurnReason={this.onSelectChurnReason}
          />
        </EntityForm>
      </div>
    );
  }
  render() {
    return (
      <div className={styles.shopDetailsUpdate}>
        <h1>Edit shop details</h1>

        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>{this.state.shop ? this.renderShopForm() : <NoDataFound />}</div>
        )}
      </div>
    );
  }
}
