import React from 'react';
import PropTypes from 'prop-types';
import styles from './Field.module.css';

export default class Field extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    label: '',
    value: '',
  };

  render() {
    return (
      <div
        id={this.props.label}
        className={styles.container}
        style={this.props.style}>
        <div className={styles.label}>{this.props.label}</div>
        <div className={styles.value}>
          {this.props.value ? this.props.value : ' '}
        </div>
      </div>
    );
  }
}
