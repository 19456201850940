import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCampaignContent } from '../../../../store/campaigns/selectors';
import { patchCampaign } from '../../../../store/campaigns/actions';
import CampaignBodyInput from './CampaignBodyInput';

const mapStateToProps = (state) => ({
  campaignBody: getCampaignContent(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchCampaign,
    },
    dispatch,
  );

class CampaignBodyInputContainer extends React.Component {
  handleChange = (event) => {
    this.props.patchCampaign({ content: event.target.value });
  };

  render() {
    return (
      <CampaignBodyInput
        value={this.props.campaignBody}
        onChange={this.handleChange}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignBodyInputContainer);
