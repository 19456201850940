import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { noop } from '../../../../utils/utils';

export default class CampaignTitleInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
  };

  static defaultProps = {
    value: '',
    onChange: noop,
    helperText: '',
  };

  render() {
    return (
      <TextField
        id="titleInput"
        label="Title"
        value={this.props.value}
        onChange={this.props.onChange}
        margin="normal"
        inputProps={{ maxLength: '128' }}
        helperText={this.props.helperText}
        fullWidth
        required
      />
    );
  }
}
