import { JoynV2Api } from '../api/joyn/JoynApi';

export default new (class MerchantRepository {
  searchMerchants = (params) => JoynV2Api.get('/sammy/merchants', params);

  getMerchantById = (merchantId) =>
    JoynV2Api.get(`/sammy/merchants/${merchantId}`);

  createMerchant = (merchant) =>
    JoynV2Api.post(`/sammy/merchants`, { ...merchant });

  updateMerchant = (merchant) =>
    JoynV2Api.post(`/sammy/merchants/${merchant.id}`, { ...merchant });

  sendRegistrationEmail = (merchantId) =>
    JoynV2Api.post(`/sammy/merchants/${merchantId}/registration-email`);

  restore = (merchantId) =>
    JoynV2Api.put(`/sammy/merchants/${merchantId}/restore`);

  delete = (merchantId) => JoynV2Api.delete(`/sammy/merchants/${merchantId}`);

  deleteMerchantSetting = (merchantId, setting) =>
    JoynV2Api.delete(`/sammy/merchants/${merchantId}/settings/${setting}`);

  createMerchantSetting = (merchantId, setting) =>
    JoynV2Api.post(`/sammy/merchants/${merchantId}/settings/${setting}`);

  availableMerchantSettings = () =>
    JoynV2Api.get(`/sammy/merchants/available-settings`);
})();
