import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSidebarIsVisible } from '../../../store/navigation/selectors';
import { hideSideBar } from '../../../store/navigation/actions';
import SideBar from './SideBar';

const mapStateToProps = (state) => ({
  sideBarIsVisible: getSidebarIsVisible(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideSideBar,
    },
    dispatch,
  );

class SideBarContainer extends React.Component {
  render() {
    return (
      <SideBar
        isOpen={this.props.sideBarIsVisible}
        onClose={this.props.hideSideBar}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContainer);
