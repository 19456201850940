import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ButtonWithConfirmation from './ButtonWithConfirmation';

export default class IconButtonWithConfirmation extends ButtonWithConfirmation {
  getButton() {
    return (
      <IconButton
        id={this.getButtonId()}
        color={'secondary'}
        onClick={this.showConfirmDialog}
        variant={this.props.buttonVariant}>
        {this.props.icon}
      </IconButton>
    );
  }
}
