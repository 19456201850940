import { JoynV2Api } from '../api/joyn/JoynApi';

export default new (class IdentifiersRepository {
  search = (serialNumber) => {
    const params = { serialNumber };
    return JoynV2Api.get('sammy/identifiers', params);
  };

  updateState = (serialNumber, userId, state) => {
    const params = { state: state };
    return JoynV2Api.patch(
      `sammy/users/${userId}/identifiers/${serialNumber}`,
      params,
    );
  };
})();
