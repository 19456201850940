import React from 'react';
import PropTypes from 'prop-types';
import Info from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography/Typography';
import styles from './InfoMessage.module.css';

class InfoMessage extends React.Component {
  static propTypes = {
    text: PropTypes.string,
  };

  static defaultProps = {
    text: '',
  };

  render() {
    return (
      <div className={styles.messageWrapper}>
        <Info className={styles.icon} />
        <Typography variant="body1">{this.props.text}</Typography>
      </div>
    );
  }
}

export default InfoMessage;
