import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper/Paper';
import { noop } from '../../../utils/utils';
import styles from './SearchBar.module.css';

class SearchBar extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    searchTerm: PropTypes.string,
    onSearch: PropTypes.func,
  };

  static defaultProps = {
    placeholder: 'Search',
    searchTerm: '',
    onSearch: noop,
  };

  state = {
    searchTerm: this.props.searchTerm,
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  searchOnPressEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const searchTerm = (this.state.searchTerm || '').trim();
      this.props.onSearch(searchTerm);
    }
  };

  render() {
    return (
      <Paper className={styles.searchBar}>
        <SearchIcon className={styles.searchIcon} color="action" />

        <Input
          id="searchInput"
          className={styles.searchInput}
          placeholder={this.props.placeholder}
          type="search"
          value={this.state.searchTerm}
          onChange={this.handleChange('searchTerm')}
          onKeyPress={this.searchOnPressEnter}
          disableUnderline
        />
      </Paper>
    );
  }
}

export default SearchBar;
