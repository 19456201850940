import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField/TextField';
import { noop } from '../../../utils/utils';
import { SUPPORTED_LANGUAGES } from '../../constants/languages';

export default class LanguageDropDown extends React.Component {
  static dropDownValues = ['', ...SUPPORTED_LANGUAGES];

  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOf(this.dropDownValues),
    onChange: PropTypes.func,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    allowAll: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    error: PropTypes.bool,
  };

  static defaultProps = {
    id: 'languageDropDown',
    label: 'Language',
    value: '',
    onChange: noop,
    margin: 'none',
    fullWidth: false,
    required: false,
    allowAll: false,
    allowEmpty: false,
  };

  menuItem = (label, value) => {
    return (
      <MenuItem value={value}>
        <em>{label}</em>
      </MenuItem>
    );
  };

  render() {
    const dropdown = (
      <FormControl
        margin={this.props.margin}
        fullWidth={this.props.fullWidth}
        error={this.props.error}
        required={this.props.required}>
        <InputLabel shrink htmlFor={this.props.id}>
          {this.props.label}
        </InputLabel>
        <Select
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}
          displayEmpty>
          {this.props.allowAll ? this.menuItem('All', '') : ''}
          {this.props.allowEmpty ? this.menuItem('None', '') : ''}
          {SUPPORTED_LANGUAGES.map((language) => (
            <MenuItem
              key={language}
              value={language}
              id={`${this.props.id}_option_${language}`}>
              {language}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

    const fallbackTextfield = (
      <TextField
        id={this.props.id}
        label={this.props.label}
        type="text"
        value={this.props.value}
        onChange={this.props.onChange}
        margin={this.props.margin}
        fullWidth={this.props.fullWidth}
        error={this.props.error}
        required={this.props.required}
      />
    );

    return LanguageDropDown.dropDownValues.includes(this.props.value)
      ? dropdown
      : fallbackTextfield;
  }
}
