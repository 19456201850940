import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import { path } from 'ramda';
import TextField from '@material-ui/core/TextField/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import EntityForm from '../../../shared/components/EntityForm/EntityForm';
import CONSTANTS from '../../../constants';
import MerchantRepository from '../../../repository/merchant/MerchantRepository';
import NavigationService from '../../../shared/navigation/NavigationService';
import LanguageDropDown from '../../../shared/components/LanguageDropDown/LanguageDropDown';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import styles from './MerchantDetailsUpdate.module.css';

export default class MerchantDetailsUpdate extends React.Component {
  state = {
    isBusy: false,
    fieldErrors: {},
  };

  componentDidMount() {
    const merchantId = parseInt(
      path(['match', 'params', 'merchantId'], this.props),
      10,
    );
    this.loadMerchantById(merchantId);
  }

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  setMerchant(merchant) {
    this.setState({
      ...this.state,
      merchant,
    });
  }

  setFieldError = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [key]: value,
      },
    }));
  };

  updateMerchantProp = (prop, value) => {
    this.setState((prevState) => ({
      ...prevState,
      merchant: {
        ...prevState.merchant,
        [prop]: value,
      },
    }));
  };

  handleFieldChange = (prop) => (event) => {
    this.updateMerchantProp(prop, event.target.value);
    this.setFieldError(prop, null);
  };

  backToMerchantDetails = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.MERCHANT_DETAILS.path, {
      merchantId: this.state.merchant.id,
    });
  };

  save = () => {
    const handleUpdateFailure = (error) => {
      const { response } = error;
      if (response.status === 409 && response.data.error) {
        toastr.error(
          response.data.error.title,
          response.data.error.description,
        );
      } else if (response.status === 400 && response.data.fieldErrors) {
        response.data.fieldErrors.forEach((fieldError) =>
          this.setFieldError(fieldError.field, fieldError.message),
        );
      } else {
        toastr.error('Could not save merchant', error.message);
      }
    };

    MerchantRepository.updateMerchant(this.state.merchant)
      .then(() => this.backToMerchantDetails())
      .catch((error) => handleUpdateFailure(error));
  };

  loadMerchantById(merchantId) {
    this.setIsBusy(true);
    MerchantRepository.getMerchantById(merchantId)
      .then((merchant) => this.setMerchant(merchant))
      .catch((error) => toastr.error('Could not load merchant', error.message))
      .finally(() => this.setIsBusy(false));
  }

  renderMerchantForm() {
    return (
      <div className={styles.merchantDetailsCardWrapper}>
        <HorizontalSpacer />
        <EntityForm onCancel={this.backToMerchantDetails} onSave={this.save}>
          <TextField
            label="Merchant name"
            type="text"
            value={this.state.merchant.name}
            onChange={this.handleFieldChange('name')}
            error={!!this.state.fieldErrors.name}
            helperText={this.state.fieldErrors.name}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="E-mail"
            type="email"
            value={this.state.merchant.email}
            onChange={this.handleFieldChange('email')}
            error={!!this.state.fieldErrors.email}
            helperText={this.state.fieldErrors.email}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="Enterprise number"
            type="text"
            value={this.state.merchant.vatNumber}
            onChange={this.handleFieldChange('vatNumber')}
            error={!!this.state.fieldErrors.vatNumber}
            helperText={this.state.fieldErrors.vatNumber}
            margin="normal"
            fullWidth
            required
          />
          <LanguageDropDown
            label="Language"
            value={this.state.merchant.language}
            onChange={this.handleFieldChange('language')}
            margin="normal"
            fullWidth
            required
            error={!!this.state.fieldErrors.language}
            allowEmpty={true}
          />
          <TextField
            label="Source ID"
            type="text"
            value={this.state.merchant.sourceId}
            onChange={this.handleFieldChange('sourceId')}
            error={!!this.state.fieldErrors.sourceId}
            helperText={this.state.fieldErrors.sourceId}
            margin="normal"
            fullWidth
            required
          />
          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.sourceCode}>
            <InputLabel>Source code</InputLabel>
            <Select
              value={this.state.merchant.sourceCode}
              onChange={this.handleFieldChange('sourceCode')}>
              <MenuItem id="ODOO" value="ODOO">
                ODOO
              </MenuItem>
              <MenuItem id="NAVISION" value="NAVISION">
                NAVISION
              </MenuItem>
              <MenuItem value="DYNAMICS">Dynamics</MenuItem>
              <MenuItem value="QUSTO">Qusto</MenuItem>
              <MenuItem value="ONLINE_ONBOARDING">ONLINE_ONBOARDING</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.loyaltyProgram}>
            <InputLabel>Loyalty program</InputLabel>
            <Select
              value={this.state.merchant.loyaltyProgram}
              onChange={this.handleFieldChange('loyaltyProgram')}>
              <MenuItem value="PERCENTAGE">Percentage</MenuItem>
              <MenuItem value="FIXED">Fixed</MenuItem>
              <MenuItem value="FIXED_WITH_MULTIPLICATION">
                Fixed with multiplication
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.pricePlan}>
            <InputLabel>Price Plan</InputLabel>
            <Select
              displayEmpty={true}
              id="pricePlan"
              value={this.state.merchant.pricePlan}
              onChange={this.handleFieldChange('pricePlan')}>
              <MenuItem id="JOYN_1" value="JOYN_1">
                Joyn Basic 2021
              </MenuItem>
              <MenuItem id="JOYN_2" value="JOYN_2">
                Joyn Plus 2021
              </MenuItem>
              <MenuItem id="JOYN_3" value="JOYN_3">
                Joyn Pro 2021
              </MenuItem>
              <MenuItem id="JOYN_4" value="JOYN_4">
                Joyn Unlimited 2021
              </MenuItem>
              <MenuItem id="JOYN_5" value="JOYN_5">
                Lite 2022
              </MenuItem>
              <MenuItem id="JOYN_6" value="JOYN_6">
                Essential 2022
              </MenuItem>
              <MenuItem id="JOYN_7" value="JOYN_7">
                Connect 2022
              </MenuItem>
              <MenuItem id="JOYN_8" value="JOYN_8">
                Expert 2022
              </MenuItem>
              <MenuItem id="FREEMIUM" value="FREEMIUM">
                Freemium
              </MenuItem>
              <MenuItem id="PRO" value="PRO">
                Pro (old)
              </MenuItem>
              <MenuItem id="BASIC" value="BASIC">
                Basic (old)
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Date to be deleted"
            InputLabelProps={{ shrink: true }}
            type="date"
            value={this.state.merchant.dateToBeDeleted}
            onChange={this.handleFieldChange('dateToBeDeleted')}
            error={!!this.state.fieldErrors.dateToBeDeleted}
            helperText={this.state.fieldErrors.dateToBeDeleted}
            margin="normal"
            fullWidth
          />
        </EntityForm>
      </div>
    );
  }
  render() {
    return (
      <div className={styles.merchantDetailsUpdate}>
        <h1>Edit merchant details</h1>

        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>
            {this.state.merchant ? this.renderMerchantForm() : <NoDataFound />}
          </div>
        )}
      </div>
    );
  }
}
