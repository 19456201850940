import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { noop } from '../../../utils/utils';
import CurrentUser from '../../../shared/components/User/CurrentUser';
import styles from './AppBarHeader.module.css';

class AppBarHeader extends React.Component {
  static propTypes = {
    onMenuIconClick: PropTypes.func,
    onLogout: PropTypes.func,
  };

  static defaultProps = {
    onMenuIconClick: noop,
    onLogout: noop,
  };

  render() {
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={this.props.onMenuIconClick}
            className={styles.menuButton}
            color="inherit"
            aria-label="Menu"
            id="menuIcon">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={styles.flex}>
            Joyn SAMMY
          </Typography>
          <Typography>
            <CurrentUser />
          </Typography>
          <Button
            color="inherit"
            onClick={this.props.onLogout}
            id="logoutButton">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
}

export default AppBarHeader;
