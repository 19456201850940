import axios from 'axios';
import * as qs from 'qs';
import * as Sentry from '@sentry/browser';
import keycloakClient from '../../../shared/keycloak/keycloakClient';

export default class HttpApi {
  constructor(baseUrl) {
    this.baseUrl = baseUrl || '';
  }

  responseSuccessHandler = (response) => response;

  responseFailureHandler = (error) => Promise.reject(error);

  initAxiosInstance(config) {
    const options = {
      baseURL: this.baseUrl,
      ...config,
    };

    const instance = axios.create(options);

    instance.interceptors.response.use(
      this.responseSuccessHandler,
      this.responseFailureHandler,
    );

    return instance;
  }

  success = ({ data }) => data;

  addTokenToConfig = (token, config) => {
    const configWithToken = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
        ...config.headers,
      },
    };

    return token ? configWithToken : config;
  };

  getParamsSerializer = (paramsToBeSerialized) =>
    qs.stringify(paramsToBeSerialized, { arrayFormat: 'repeat' });

  doHttpCall = (callType, url, params, config = {}) => {
    const context = this;
    return keycloakClient
      .refreshToken()
      .then(() =>
        context.doHttpCallWithRefreshedToken(config, callType, url, params),
      )
      .catch((e) => {
        Sentry.captureException(e);
        throw e;
      });
  };

  doHttpCallWithRefreshedToken(config, callType, url, params) {
    const token = keycloakClient.getToken();
    const configWithToken = this.addTokenToConfig(token, config);

    return this.initAxiosInstance(configWithToken)
      [callType](
        url,
        callType === 'get'
          ? {
              params,
              paramsSerializer: this.getParamsSerializer,
            }
          : params,
      )
      .then(this.success);
  }

  get = (...params) => this.doHttpCall('get', ...params);

  post = (...params) => this.doHttpCall('post', ...params);

  put = (...params) => this.doHttpCall('put', ...params);

  patch = (...params) => this.doHttpCall('patch', ...params);

  delete = (...params) => this.doHttpCall('delete', ...params);
}
