import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { GuardedRoute } from '../../shared/components/User/GuardedRoute';
import NotFound from '../../pages/404/NotFound';
import HomeContainer from '../../pages/Home/HomeContainer';
import CONSTANTS from '../../constants';
import Users from '../../pages/Users/Users';
import Merchants from '../../pages/Merchants/Merchants';
import Campaigns from '../../pages/Campaigns/Campaigns';
import IdentifiersOverview from '../../pages/Identifiers/IdentifiersOverview';
import Shops from '../../pages/Shops/Shops';
import EdenredIntegration from '../../pages/Integrations/Edenred/EdenredIntegration';
import RegistrationRequestOverview from '../../pages/RegistrationRequests/RegistrationRequestOverview';
import PointsCoupons from '../../pages/customer/PointsCoupons';
import MergeUsers from '../../pages/MergeUsers/MergeUsers';
import CategoriesOverview from '../../pages/Categories/CategoriesOverview';
import CategoryDetail from '../../pages/Categories/CategoryDetail';
import Partners from '../../pages/Partners/Partners';
import styles from './MainContent.module.css';

class MainContent extends React.Component {
  render() {
    return (
      <main className={styles.mainContent}>
        <Switch>
          <Route
            exact
            path={CONSTANTS.ROUTES.HOME.path}
            component={HomeContainer}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_USERS]}
            path={CONSTANTS.ROUTES.USER_OVERVIEW.path}
            component={Users}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}
            path={CONSTANTS.ROUTES.POINTS_COUPONS.path}
            component={PointsCoupons}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}
            path={CONSTANTS.ROUTES.MERGE_USERS.path}
            component={MergeUsers}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_IDENTIFIERS]}
            path={CONSTANTS.ROUTES.IDENTIFIERS_OVERVIEW.path}
            component={IdentifiersOverview}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_MERCHANTS]}
            path={CONSTANTS.ROUTES.MERCHANT_OVERVIEW.path}
            component={Merchants}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_CAMPAIGNS]}
            path={CONSTANTS.ROUTES.CAMPAIGN_OVERVIEW.path}
            component={Campaigns}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_PARTNERS]}
            path={CONSTANTS.ROUTES.PARTNER_OVERVIEW.path}
            component={Partners}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_EDENRED]}
            path={CONSTANTS.ROUTES.EDENRED_INTEGRATION.path}
            component={EdenredIntegration}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_SHOPS]}
            path={CONSTANTS.ROUTES.SHOP_DETAILS.path}
            component={Shops}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_REGISTRATION_REQUESTS]}
            path={CONSTANTS.ROUTES.REGISTRATION_REQUESTS.path}
            component={RegistrationRequestOverview}
          />

          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_CATEGORIES]}
            exact
            path={CONSTANTS.ROUTES.CATEGORIES_DETAIL.path}
            component={CategoryDetail}
          />
          <GuardedRoute
            permissions={[CONSTANTS.PERMISSIONS.OP_READ_CATEGORIES]}
            path={CONSTANTS.ROUTES.CATEGORIES_OVERVIEW.path}
            component={CategoriesOverview}
          />

          <Route component={NotFound} />
        </Switch>
      </main>
    );
  }
}

export default withRouter(MainContent);
