import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { noop } from '../../../../utils/utils';

export default class CampaignRecipientLimit extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    maxRecipients: PropTypes.number,
    required: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    onChange: noop,
    maxRecipients: undefined,
    required: false,
  };

  render() {
    const helperText = this.props.maxRecipients
      ? `max. ${this.props.maxRecipients} users`
      : '';

    return (
      <TextField
        id="CampaignRecipientLimitInput"
        label="User Limit"
        value={this.props.value}
        onChange={this.props.onChange}
        margin="normal"
        type="number"
        inputProps={{ min: '1', max: this.props.maxRecipients, step: '1' }}
        helperText={helperText}
        required={this.props.required}
        fullWidth
      />
    );
  }
}
