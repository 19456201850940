import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import CONSTANTS from '../../constants';
import { GuardedRoute } from '../../shared/components/User/GuardedRoute';
import PartnerOverview from './overview/PartnerOverview';
import PartnerDetailsContainer from './details/PartnerDetailsContainer';
import PartnerCreate from './create/PartnerCreate';

class Partners extends React.Component {
  render() {
    return (
      <Switch>
        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_READ_PARTNERS]}
          exact
          path={CONSTANTS.ROUTES.PARTNER_OVERVIEW.path}
          component={PartnerOverview}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_PARTNERS]}
          exact
          path={CONSTANTS.ROUTES.PARTNER_CREATE.path}
          component={PartnerCreate}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_PARTNERS]}
          exact
          path={CONSTANTS.ROUTES.PARTNER_DETAILS.path}
          component={PartnerDetailsContainer}
        />

        {/*<GuardedRoute*/}
        {/*  permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_PARTNERS]}*/}
        {/*  exact*/}
        {/*  path={CONSTANTS.ROUTES.PARTNERS_UPDATE.path}*/}
        {/*  component={MerchantDetailsUpdate}*/}
        {/*/>*/}

        <Redirect to={CONSTANTS.ROUTES.FOUR_OH_FOUR.path} />
      </Switch>
    );
  }
}

export default withRouter(Partners);
