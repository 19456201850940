import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import ButtonWithConfirmation from '../../../../../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import ShopRepository from '../../../../../../../repository/shop/ShopRepository';
import getError from '../../../../../../../utils/utils';
import partnerOverviewService from '../../../../../../Partners/overview/PartnerOverviewService';

class AddPartnerButton extends React.Component {
  static propTypes = {
    shopId: PropTypes.number,
    onPartnerAdded: PropTypes.func,
  };

  state = {
    isBusy: false,
    partners: [],
  };

  loadPartners = () => {
    partnerOverviewService.allPartners(null).then((data) => {
      this.setState({
        ...this.state,
        partners: data,
      });
    });
  };

  componentDidMount() {
    this.loadPartners();
  }

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  addPartner = () => {
    this.setIsBusy(true);
    return new Promise((resolve, reject) => {
      const request = {
        partnerKey: this.state.newPartnerInput,
        shopId: this.props.shopId,
      };
      ShopRepository.addPartner(request, this.props.shopId)
        .then((data) => {
          toastr.success('Success', `Partner created`);
          this.props.onPartnerAdded(data);
        })
        .catch((error) => {
          toastr.error('Could not create partner reference', getError(error));
          reject();
        })
        .finally(() => {
          this.setIsBusy(false);
        });
    });
  };

  partnerKeyInputChanged = (event, value) => {
    this.setState({
      ...this.state,
      newPartnerInput: value,
    });
  };

  render() {
    const button = (
      <ButtonWithConfirmation
        buttonVariant="text"
        buttonText="Add Partner"
        onConfirm={this.addPartner}
        cancelButtonText="Cancel"
        icon={<AddIcon />}
        confirmButtonText="Add"
        title="Add partner"
        id="AddPartner"
        message="Fill in the partner key of the partner you like to add:">
        <Autocomplete
          type="text"
          placeholder="partner key"
          id="newPartnerInput"
          options={this.state.partners}
          onChange={this.partnerKeyInputChanged}
          renderInput={(params) => <TextField {...params} label="Partner" />}
        />
      </ButtonWithConfirmation>
    );
    return this.state.isBusy ? <CircularProgress /> : button;
  }
}

export default AddPartnerButton;
