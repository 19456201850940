import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  loadCampaignById,
  updateAndPublishCampaign,
  updateAndSendTestCampaign,
  updateCampaignFromStore,
} from '../../../store/campaigns/thunks';
import {
  isLoadingCampaign,
  isUpdatingCampaign,
  isPublishingCampaign,
  getCampaignTargetRecipientCount,
  getCampaignTargetRecipientLimit,
  getCampaign,
} from '../../../store/campaigns/selectors';
import { resetCampaign } from '../../../store/campaigns/actions';
import CampaignDetails from './CampaignDetails';
import styles from './CampaignDetails.module.css';

const mapStateToProps = (state) => ({
  isLoadingCampaign: isLoadingCampaign(state),
  isSavingCampaign: isUpdatingCampaign(state),
  isPublishingCampaign: isPublishingCampaign(state),
  campaign: getCampaign(state),
  campaignRecipientLimit: getCampaignTargetRecipientLimit(state),
  campaignRecipientCount: getCampaignTargetRecipientCount(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetCampaign,
      loadCampaignById,
      updateCampaignFromStore,
      updateAndSendTestCampaign,
      updateAndPublishCampaign,
    },
    dispatch,
  );

class CampaignDetailsContainer extends React.Component {
  componentDidMount() {
    const campaignIdFromUrl = this.props.match.params.campaignId;
    this.props.loadCampaignById(campaignIdFromUrl);
  }

  componentWillUnmount() {
    this.props.resetCampaign();
  }

  render() {
    return this.props.isLoadingCampaign ? (
      <CircularProgress className={styles.spinner} />
    ) : (
      <CampaignDetails
        saveCampaign={this.props.updateCampaignFromStore}
        isSaving={this.props.isSavingCampaign}
        publishCampaign={this.props.updateAndPublishCampaign}
        saveAndSendTestCampaign={this.props.updateAndSendTestCampaign}
        isPublishing={this.props.isPublishingCampaign}
        campaign={this.props.campaign}
        campaignRecipientLimit={this.props.campaignRecipientLimit}
        campaignRecipientCount={this.props.campaignRecipientCount}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignDetailsContainer);
