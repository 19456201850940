import React from 'react';
import { path } from 'ramda';
import { toastr } from 'react-redux-toastr';
import TextField from '@material-ui/core/TextField/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigationService from '../../../../shared/navigation/NavigationService';
import UserRepository from '../../../../repository/user/UserRepository';
import CONSTANTS from '../../../../constants';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import EntityForm from '../../../../shared/components/EntityForm/EntityForm';
import LanguageDropDown from '../../../../shared/components/LanguageDropDown/LanguageDropDown';
import NoDataFound from '../../../../shared/components/NoDataFound/NoDataFound';
import styles from './UpdateUserDetails.module.css';

export default class UpdateUserDetails extends React.Component {
  state = {
    busy: false,
    user: null,
    fieldErrors: {},
  };

  async componentDidMount() {
    await this.loadUserDetails();
  }

  async loadUserDetails() {
    const userId = parseInt(
      path(['match', 'params', 'userId'], this.props),
      10,
    );

    this.setState({ busy: true });
    try {
      const user = await UserRepository.getUser(userId);
      this.setState({ user: this.mapUserToView(user) });
    } catch (error) {
      toastr.error('Could not load user', error.message);
    } finally {
      this.setState({ busy: false });
    }
  }

  mapUserToView(modelUser) {
    const user = { ...modelUser };
    delete user.birthDay;
    user.birthDate = modelUser.birthDay;
    return user;
  }

  setFieldError = (key, value) => {
    this.setState({
      fieldErrors: {
        ...this.state.fieldErrors,
        [key]: value,
      },
    });
  };

  updateUserField = (prop, value) => {
    this.setState({
      user: {
        ...this.state.user,
        [prop]: value === '' ? undefined : value,
      },
    });
  };

  handleFieldChange = (prop) => (event) => {
    this.updateUserField(prop, event.target.value);
    this.setFieldError(prop, null);
  };

  backToUserDetails = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.USER_DETAILS.path, {
      userId: this.state.user.id,
    });
  };

  async save() {
    try {
      this.setState({ busy: true });
      await UserRepository.updateUserDetails(
        this.state.user.id,
        this.state.user,
      );
      this.backToUserDetails();
    } catch (error) {
      const { response } = error;
      if (response.status === 409 && response.data.error) {
        toastr.error(
          response.data.error.title,
          response.data.error.description,
        );
      } else if (response.status === 400 && response.data.fieldErrors) {
        response.data.fieldErrors.forEach((fieldError) =>
          this.setFieldError(fieldError.field, fieldError.message),
        );
      } else {
        toastr.error('Could not save user', error.message);
      }
      this.setState({ busy: false });
    }
  }

  render() {
    if (this.state.busy) {
      return <CircularProgress />;
    }

    if (!this.state.user) {
      return <NoDataFound />;
    }
    return (
      <div className={styles.userDetailsCardWrapper}>
        <HorizontalSpacer />
        <EntityForm
          onCancel={this.backToUserDetails}
          onSave={() => this.save()}>
          <TextField
            label="User first name"
            type="text"
            value={this.state.user.firstName}
            onChange={this.handleFieldChange('firstName')}
            error={!!this.state.fieldErrors.firstName}
            helperText={this.state.fieldErrors.firstName}
            margin="normal"
            fullWidth
          />
          <TextField
            label="User last name"
            type="text"
            value={this.state.user.lastName}
            onChange={this.handleFieldChange('lastName')}
            error={!!this.state.fieldErrors.lastName}
            helperText={this.state.fieldErrors.lastName}
            margin="normal"
            fullWidth
          />
          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.gender}>
            <InputLabel>Gender</InputLabel>
            <Select
              id="user-gender"
              value={this.state.user.gender}
              onChange={this.handleFieldChange('gender')}>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Birthday"
            type="date"
            value={this.state.user.birthDate}
            onChange={this.handleFieldChange('birthDate')}
            error={!!this.state.fieldErrors.birthDate}
            helperText={this.state.fieldErrors.birthDate}
            margin="normal"
            fullWidth
          />
          <TextField
            label="User language"
            type="text"
            inputProps={{ maxLength: 2, minLength: 2 }}
            value={this.state.user.userLanguage}
            onChange={this.handleFieldChange('userLanguage')}
            error={!!this.state.fieldErrors.userLanguage}
            helperText={this.state.fieldErrors.userLanguage}
            margin="normal"
            fullWidth
          />
          <LanguageDropDown
            label="Notification language (used for communication)"
            value={this.state.user.notificationLanguage}
            onChange={this.handleFieldChange('notificationLanguage')}
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.notificationLanguage}
          />
          <TextField
            label="Zipcode"
            type="text"
            value={this.state.user.zipcode}
            onChange={this.handleFieldChange('zipcode')}
            error={!!this.state.fieldErrors.zipcode}
            helperText={this.state.fieldErrors.zipcode}
            margin="normal"
            fullWidth
          />
          <TextField
            label="City"
            type="text"
            value={this.state.user.city}
            onChange={this.handleFieldChange('city')}
            error={!!this.state.fieldErrors.city}
            helperText={this.state.fieldErrors.city}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Region"
            type="text"
            value={this.state.user.region}
            onChange={this.handleFieldChange('region')}
            error={!!this.state.fieldErrors.region}
            helperText={this.state.fieldErrors.region}
            margin="normal"
            fullWidth
          />
          <FormControl
            margin="normal"
            fullWidth
            error={!!this.state.fieldErrors.country}>
            <InputLabel>Country code</InputLabel>
            <Select
              id="user-country"
              value={this.state.user.country}
              onChange={this.handleFieldChange('country')}>
              <MenuItem value="" />
              <MenuItem value="BE">BE</MenuItem>
              <MenuItem value="NL">NL</MenuItem>
              <MenuItem value="LU">LU</MenuItem>
              <MenuItem value="FR">FR</MenuItem>
              <MenuItem value="GB">GB</MenuItem>
              <MenuItem value="DE">DE</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Phone"
            type="text"
            value={this.state.user.phone}
            onChange={this.handleFieldChange('phone')}
            error={!!this.state.fieldErrors.phone}
            helperText={this.state.fieldErrors.phone}
            margin="normal"
            fullWidth
          />
        </EntityForm>
      </div>
    );
  }
}
