import React from 'react';
import PropTypes from 'prop-types';
import Warning from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography/Typography';
import styles from './WarningMessage.module.css';

class WarningMessage extends React.Component {
  static propTypes = {
    text: PropTypes.string,
  };

  static defaultProps = {
    text: '',
  };

  render() {
    return (
      <div className={styles.warningWrapper}>
        <Warning className={styles.warningIcon} />
        <Typography variant="body2">{this.props.text}</Typography>
      </div>
    );
  }
}

export default WarningMessage;
