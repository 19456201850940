import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'lodash.debounce';
import { noop } from '../../../utils/utils';
import { SUPPORTED_LANGUAGES } from '../../../shared/constants/languages';
import LanguageDropDown from '../../../shared/components/LanguageDropDown/LanguageDropDown';
import CampaignRecipientLimit from '../components/CampaignRecipientLimit/CampaignRecipientLimit';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import InfoMessage from '../../../shared/components/messages/InfoMessage/InfoMessage';
import RecipientCountDisplay from '../components/RecipientInfo/components/RecipientCountDisplay/RecipientCountDisplay';
import CONSTANTS from '../../../constants';
import CampaignTargetGenderSelect from './components/CampaignTargetGenderSelect/CampaignTargetGenderSelect';
import ZipCodeManager from './components/ZipCodeManager/ZipCodeManager';
import AgeTarget from './components/AgeTarget/AgeTarget';
import BackToCampaignDetailsButtonContainer from './components/BackToCampaignDetailsButton/BackToCampaignDetailsButtonContainer';
import UserTypeDropDown from './components/UserTypeDropDown/UserTypeDropDown';
import AppUsagePeriodDropDown from './components/AppUsagePeriodDropDown/AppUsagePeriodDropDown';
import styles from './CampaignTarget.module.css';
import {
  SUPPORTED_TARGET_USER_TYPES,
  TARGET_USER_TYPES,
} from './components/UserTypeDropDown/constants/target-user-types';
import {
  APP_USAGE_PERIODS,
  SUPPORTED_APP_USAGE_PERIODS,
} from './components/AppUsagePeriodDropDown/constants/app-usage-periods';

class CampaignTarget extends React.Component {
  static propTypes = {
    onFilterChange: PropTypes.func,
    recipientLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recipientCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zipCodes: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.arrayOf(PropTypes.oneOf(['', ...SUPPORTED_LANGUAGES])),
    gender: PropTypes.string,
    ageRanges: PropTypes.arrayOf(PropTypes.object),
    userType: PropTypes.oneOf(SUPPORTED_TARGET_USER_TYPES),
    userAppUsagePeriod: PropTypes.oneOf(SUPPORTED_APP_USAGE_PERIODS),
    isLoadingRecipientCount: PropTypes.bool,
  };

  static defaultProps = {
    onFilterChange: noop,
    recipientLimit: '',
    recipientCount: 0,
    zipCodes: [],
    languages: [''],
    gender: '',
    ageRanges: [],
    userType: TARGET_USER_TYPES.ALL_USERS.key,
    userAppUsagePeriod: APP_USAGE_PERIODS.ONE_MONTH.key,
    isLoadingRecipientCount: false,
  };

  state = {
    zipCodes: this.props.zipCodes,
    language: this.props.languages[0],
    recipientLimit: this.props.recipientLimit,
    gender: this.props.gender,
    ageRanges: this.props.ageRanges,
    userType: this.props.userType,
    userAppUsagePeriod: this.props.userAppUsagePeriod,
  };

  onUpdateZipCodes = ({ zipCodes }) => {
    this.setStateWithCallBack({ zipCodes });
  };

  onUpdateGender = ({ gender }) => {
    this.setStateWithCallBack({ gender });
  };

  onUpdateAgeRanges = ({ ageRanges }) => {
    this.setStateWithCallBack({ ageRanges });
  };

  onFilterChangeCallback = () =>
    this.props.onFilterChange(this.getCurrentTargetFilters());

  setStateWithCallBack = (state) => {
    this.setState(state, this.onFilterChangeCallback);
  };

  setStateWithDebouncedCallBack = (state) => {
    this.setState(state, this.debouncedOnFilterChangeCallback);
  };

  getCurrentTargetFilters = () => ({
    zipCodes: this.state.zipCodes,
    recipientLimit: this.state.recipientLimit,
    ...(this.state.language ? { languages: [this.state.language] } : {}),
    ...(this.state.gender ? { gender: this.state.gender } : {}),
    ...(this.state.ageRanges && this.state.ageRanges.length
      ? { ageRanges: this.state.ageRanges }
      : {}),
    ...(this.state.userType ? { userType: this.state.userType } : {}),
    ...(this.state.userType === TARGET_USER_TYPES.APP_USER.key &&
    this.state.userAppUsagePeriod
      ? { userAppUsagePeriod: this.state.userAppUsagePeriod }
      : {}),
  });

  handleChangeWithCallBack = (prop) => (event) => {
    this.setStateWithCallBack({ [prop]: event.target.value });
  };

  handleChangeWithDebouncedCallBack = (prop) => (event) => {
    this.setStateWithDebouncedCallBack({ [prop]: event.target.value });
  };

  debouncedOnFilterChangeCallback = debounce(
    this.onFilterChangeCallback,
    CONSTANTS.DEBOUNCE.SLOW,
  );

  render() {
    const userAppUsageTarget = (
      <div>
        <HorizontalSpacer size="medium" />
        <Typography variant="body2">
          Users should have used the app within the last
        </Typography>
        <AppUsagePeriodDropDown
          value={this.state.userAppUsagePeriod}
          onChange={this.handleChangeWithCallBack('userAppUsagePeriod')}
          margin="dense"
          required
        />
      </div>
    );

    return (
      <div className={styles.CampaignTarget}>
        <h1>Campaign Target</h1>

        <Paper className={styles.formWrapper}>
          <form id="campaignTargetForm" autoComplete="off">
            {this.props.isLoadingRecipientCount ? (
              <CircularProgress className={styles.spinner} />
            ) : (
              <RecipientCountDisplay value={this.props.recipientCount} />
            )}

            <HorizontalSpacer size="large" />

            <Typography variant="h6">Maximum amount of recipients</Typography>
            <CampaignRecipientLimit
              value={this.state.recipientLimit}
              onChange={this.handleChangeWithDebouncedCallBack(
                'recipientLimit',
              )}
            />

            <HorizontalSpacer size="large" />
            <Divider />
            <HorizontalSpacer size="large" />

            <Typography variant="h6">Restrict to postal codes</Typography>
            <HorizontalSpacer size="small" />
            <InfoMessage text="Enter postal codes in order of importance" />
            <ZipCodeManager
              zipCodes={this.state.zipCodes}
              onChange={this.onUpdateZipCodes}
            />

            <HorizontalSpacer size="large" />
            <Divider />
            <HorizontalSpacer size="large" />

            <Typography variant="h6">Restrict to Language</Typography>
            <LanguageDropDown
              value={this.state.language}
              onChange={this.handleChangeWithCallBack('language')}
              margin="normal"
              allowAll
            />

            <HorizontalSpacer size="large" />
            <Divider />
            <HorizontalSpacer size="large" />

            <CampaignTargetGenderSelect
              value={this.state.gender}
              onChange={this.onUpdateGender}
            />

            <HorizontalSpacer size="large" />
            <Divider />
            <HorizontalSpacer size="large" />

            <AgeTarget
              ageRanges={this.state.ageRanges}
              onChange={this.onUpdateAgeRanges}
            />

            <HorizontalSpacer size="large" />
            <Divider />
            <HorizontalSpacer size="large" />

            <Typography variant="h6">Restrict to User Type</Typography>
            <UserTypeDropDown
              value={this.state.userType}
              onChange={this.handleChangeWithCallBack('userType')}
              margin="normal"
              required
            />
            {this.state.userType === TARGET_USER_TYPES.APP_USER.key
              ? userAppUsageTarget
              : ''}

            <HorizontalSpacer size="XL" />

            {this.props.isLoadingRecipientCount ? (
              <CircularProgress />
            ) : (
              <BackToCampaignDetailsButtonContainer />
            )}
          </form>
        </Paper>
      </div>
    );
  }
}

export default CampaignTarget;
