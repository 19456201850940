import PartnerRepository from '../../../repository/partner/PartnerRepository';

class PartnerOverviewService {
  searchPartners(searchTerm) {
    return PartnerRepository.searchPartners({
      sort: 'partnerCode,ASC',
      ...(searchTerm ? { searchTerm } : {}),
    });
  }

  allPartners() {
    return PartnerRepository.allPartnerCodes();
  }
  getPartnerApiKeys(partnerId) {
    return PartnerRepository.getPartnerApiKeys(partnerId);
  }

  createApiKey(partnerId, permissions) {
    return PartnerRepository.createApiKey(partnerId, {
      permissions: permissions,
    });
  }

  deleteApiKey(partnerId, apiKey) {
    return PartnerRepository.deletePartnerApiKey(partnerId, apiKey);
  }
}

const partnerOverviewService = new PartnerOverviewService();

export default partnerOverviewService;
