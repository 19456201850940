import React from 'react';
import PropTypes from 'prop-types';
import styles from './HorizontalSpacer.module.css';

export default class HorizontalSpacer extends React.Component {
  static propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large', 'XL']),
  };

  static defaultProps = {
    size: 'medium',
  };

  render() {
    return <div className={styles[this.props.size]} />;
  }
}
