import React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './NoDataFound.module.css';

export default class NoDataFound extends React.Component {
  render() {
    return (
      <div className={styles.noDataFound} id="noDataFound">
        <Typography className={styles.content} variant="h4">
          No Data Found
        </Typography>
      </div>
    );
  }
}
