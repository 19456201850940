import React from 'react';
import Button from '@material-ui/core/Button';

export default function SelectUserButton(props) {
  const handleOnClick = () => props.onClick(props.user, props.searchTerm);

  return (
    <Button id="editCampaignButton" color="primary" onClick={handleOnClick}>
      Select
    </Button>
  );
}
