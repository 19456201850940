import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StepContent } from '@material-ui/core';
import styles from './PointsCoupons.module.css';
import ShopSearch from './ShopSearch/ShopSearch';
import PointsAndCouponSelection from './PointsAndCouponSelection/PointsAndCouponSelection';
import UserSearch from './UserSearch/UserSearch';
import AwardConfirmation from './AwardConfirmation/AwardConfirmation';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Select shop', 'Select coupon/points', 'Select user', 'Confirm'];
}

export default function PointsCoupons() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [user, setUser] = React.useState({});
  const [shop, setShop] = React.useState({});
  const [award, setAward] = React.useState({});
  const [searchTermShop, setSearchTermShop] = React.useState(null);
  const [searchTermUser, setSearchTermUser] = React.useState(null);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    switch (activeStep) {
      case 1:
        setShop({});
        break;
      case 2:
        award.description = null;
        break;
      case 3:
        setUser({});
        break;
      default:
    }
  };

  const selectShop = (shop, searchTerm) => {
    setShop(shop);
    setSearchTermShop(searchTerm);
    handleNext();
  };

  const selectAward = (award) => {
    setAward(award);
    handleNext();
  };

  const selectUser = (user, searchTerm) => {
    setUser(user);
    setSearchTermUser(searchTerm);
    handleNext();
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ShopSearch
            selectShop={selectShop}
            shopName={shop.name}
            searchTerm={searchTermShop}
          />
        );
      case 1:
        return (
          <PointsAndCouponSelection
            shop={shop}
            selectAward={selectAward}
            award={award}
          />
        );
      case 2:
        return (
          <UserSearch selectUser={selectUser} searchTerm={searchTermUser} />
        );
      case 3:
        return <AwardConfirmation shop={shop} user={user} award={award} />;
      default:
        return 'Unknown step';
    }
  };

  const stepLabel = (label, step) => {
    switch (step) {
      case 0:
        return shop.name == null ? label : shop.name;
      case 1:
        return award.description == null ? label : award.description;
      case 2:
        return user.mail == null ? label : user.mail;
      case 3:
        return label;
      default:
        return 'Unknown step';
    }
  };

  return (
    <div>
      <div className={styles.stepperRoot}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{stepLabel(label, index)}</StepLabel>
                <StepContent>
                  <div>
                    <div>
                      <Typography
                        className={classes.instructions}
                        component={'span'}>
                        {getStepContent(activeStep)}
                      </Typography>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}>
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>{' '}
    </div>
  );
}
