import React from 'react';
import { path } from 'ramda';
import ShopDetails from './ShopDetails';

export default class ShopDetailsContainer extends React.Component {
  render() {
    const shopIdFromUrl = path(['match', 'params', 'shopId'], this.props);
    const shopId = parseInt(shopIdFromUrl, 10);

    return shopIdFromUrl ? <ShopDetails shopId={shopId} /> : null;
  }
}
