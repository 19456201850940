import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import HasPermission from '../../../shared/components/User/HasPermission';
import IconButtonWithConfirmation from '../../../shared/components/ButtonWithConfirmation/IconButtonWithConfirmation';
import SearchBar from '../../../shared/components/SearchBar/SearchBar';
import styles from '../RegistrationRequestOverview.module.css';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import CONSTANTS from '../../../constants';

export default class RrOverviewView extends React.Component {
  static propTypes = {
    registrationRequests: PropTypes.array,
    defaultSelectedState: PropTypes.string,
    onSearch: PropTypes.func,
    onDelete: PropTypes.func,
    onRegistrationRequestStateChanged: PropTypes.func,
    isBusy: PropTypes.bool,
  };

  searchBar = () => (
    <div className={styles.searchRR}>
      <SearchBar
        placeholder="Search for registrationRequests"
        onSearch={this.props.onSearch}
      />
      <Select
        label="State"
        value={this.props.defaultSelectedState}
        onChange={this.props.onRegistrationRequestStateChanged}
        className={styles.stateDropdown}>
        <MenuItem value="" disabled>
          State
        </MenuItem>
        <MenuItem value={'PENDING'} selected={true}>
          PENDING
        </MenuItem>
        <MenuItem value={'COMPLETED'}>COMPLETED</MenuItem>
      </Select>
    </div>
  );

  renderResults(rrs) {
    return (
      <Paper>
        <Table id="rrResultsTable">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Shop</TableCell>
              <TableCell>Created on</TableCell>
              <HasPermission
                permissions={[
                  CONSTANTS.PERMISSIONS.OP_DELETE_REGISTRATION_REQUESTS,
                ]}>
                <TableCell />
              </HasPermission>
            </TableRow>
          </TableHead>
          <TableBody>
            {rrs.map((rr) => (
              <TableRow key={rr.id} hover>
                <TableCell>{rr.email}</TableCell>
                <TableCell className={styles.codeColumn} title={rr.state}>
                  {rr.code}
                </TableCell>
                <TableCell>
                  <span title={rr.shopId}>{rr.shopName}</span>
                </TableCell>
                <TableCell>
                  {moment(rr.created).format('DD/MM/YYYY HH:mm')}
                </TableCell>
                <HasPermission
                  permissions={[
                    CONSTANTS.PERMISSIONS.OP_DELETE_REGISTRATION_REQUESTS,
                  ]}>
                  <TableCell align="right">{this.deleteButton(rr)}</TableCell>
                </HasPermission>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  deleteButton(registrationRequest) {
    return (
      <IconButtonWithConfirmation
        onConfirm={this.props.onDelete}
        param={registrationRequest.id}
        message="Are you sure that you want to delete this Registration Request?"
        title="Delete"
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        id={'deleteRegistrationRequest'}
        icon={<DeleteIcon />}>
        {this.rrInfo(registrationRequest)}
      </IconButtonWithConfirmation>
    );
  }

  rrInfo(rr) {
    return (
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell className={styles.tableHeaderDialog}>Email</TableCell>
            <TableCell>{rr.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.tableHeaderDialog}>Code</TableCell>
            <TableCell>{rr.code}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.tableHeaderDialog}>State</TableCell>
            <TableCell>{rr.state}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={styles.tableHeaderDialog}>
              Created on
            </TableCell>
            <TableCell>
              {moment(rr.created).format('DD/MM/YYYY HH:mm')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <div className={styles.rrOverview}>
        <h1>Registration Requests</h1>
        {this.searchBar()}
        <HorizontalSpacer />
        {this.props.isBusy ? (
          <CircularProgress />
        ) : (
          <div>
            {this.props.registrationRequests &&
            this.props.registrationRequests.length ? (
              this.renderResults(this.props.registrationRequests)
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
      </div>
    );
  }
}
