import React from 'react';
import Button from '@material-ui/core/Button';
import BugReportIcon from '@material-ui/icons/BugReport';
import HorizontalSpacer from '../../shared/components/HorizontalSpacer/HorizontalSpacer';
import styles from './NotFound.module.css';

const reportBugEmail = 'mailto:hello@joyn.be';

export default class NotFound extends React.Component {
  openMailTo = () => {
    window.location.assign(reportBugEmail);
  };

  render() {
    return (
      <div className={styles.notFound}>
        <div className={styles.content}>
          <h1 className={styles.title}>404</h1>

          <h2 className={styles.subtitle}>
            The page you are looking for does not exist.
          </h2>

          <p className={styles.reportBugDescription}>
            If you think something is broken, please report a bug.
          </p>

          <HorizontalSpacer size="medium" />

          <Button
            id="reportProblemButton"
            onClick={this.openMailTo}
            variant="contained"
            color="primary">
            Report Bug
            <BugReportIcon className={styles.bugIcon} />
          </Button>
        </div>
      </div>
    );
  }
}
