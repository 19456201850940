import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import UserConsentRepository from '../../../../repository/user/UserConsentRepository';
import UserConsentView from './UserConsentView';

export default class UserConsent extends React.Component {
  static propTypes = {
    userId: PropTypes.number,
  };

  state = {
    userConsentList: [],
    isBusy: true,
  };

  setIsBusy = (busy) => {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  };

  giveConsent = (consent) => {
    this.setIsBusy(true);
    UserConsentRepository.giveConsent(this.props.userId, consent.about)
      .then(() => {
        this.getAllUserConsent();
      })
      .catch((error) =>
        toastr.error('Could not give user consent', error.message),
      )
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  declineConsent = (consent) => {
    this.setIsBusy(true);
    UserConsentRepository.declineConsent(this.props.userId, consent.about)
      .then(() => {
        this.getAllUserConsent();
      })
      .catch((error) =>
        toastr.error('Could not decline user consent', error.message),
      )
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  getAllUserConsent() {
    this.setIsBusy(true);
    UserConsentRepository.getAllConsent(this.props.userId)
      .then((data) => {
        this.setState({
          ...this.state,
          userConsentList: data,
        });
      })
      .catch((error) =>
        toastr.error('Could not load user consent', error.message),
      )
      .finally(() => {
        this.setIsBusy(false);
      });
  }

  componentDidMount() {
    this.getAllUserConsent();
  }

  render() {
    return (
      <UserConsentView
        giveConsent={this.giveConsent}
        declineConsent={this.declineConsent}
        isBusy={this.state.isBusy}
        userConsentList={this.state.userConsentList}
      />
    );
  }
}
