import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { generatePath } from 'react-router-dom';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import CONSTANTS from '../../../constants';
import { history } from '../../../store/store';
import styles from './MerchantOverview.module.css';
import MerchantOverviewService from './MerchantOverviewService';
import MerchantOverviewTable from './components/MerchantOverviewTable/MerchantOverviewTable';
import SearchBar from './components/SearchBar';

export default class MerchantOverview extends React.Component {
  state = {
    merchants: [],
    isBusy: false,
    searchTerm: '',
  };

  componentDidMount() {
    let previousSearch = sessionStorage.getItem('merchant_search');
    if (previousSearch != null) {
      let json = JSON.parse(previousSearch);
      this.loadMerchants(json.searchTerm, json.includeDeleted);
    } else {
      this.loadMerchants();
    }
  }

  onCreateMerchant = () => {
    history.push(generatePath(CONSTANTS.ROUTES.MERCHANT_CREATE.path));
  };

  loadMerchants = (searchTerm, includeDeleted) => {
    this.setState({
      ...this.state,
      isBusy: true,
      searchTerm: searchTerm,
    });

    MerchantOverviewService.searchMerchants(searchTerm, includeDeleted)
      .then((data) => {
        this.setState({
          ...this.state,
          merchants: data.content,
        });
      })
      .catch((error) => toastr.error('Could not load merchants', error.message))
      .finally(() => {
        this.setState({
          ...this.state,
          isBusy: false,
        });
      });
  };

  render() {
    return (
      <div className={styles.merchantOverview}>
        <Tooltip
          title="Create Merchant"
          placement="left"
          classes={{ tooltip: styles.tooltip }}>
          <Fab
            id="createMerchantButton"
            className={styles.button}
            color="primary"
            aria-label="Create"
            onClick={this.onCreateMerchant}>
            <AddIcon />
          </Fab>
        </Tooltip>
        <h1>Merchants</h1>
        <div className={styles.searchMerchants}>
          <SearchBar
            placeholder="Search merchants by name"
            onSearch={this.loadMerchants}
          />
        </div>
        <HorizontalSpacer />

        {this.state.isBusy ? (
          <CircularProgress id="loadingSpinner" />
        ) : (
          <div>
            {this.state.merchants.length ? (
              <MerchantOverviewTable merchants={this.state.merchants} />
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
      </div>
    );
  }
}
