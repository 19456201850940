import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField/TextField';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash.debounce';
import CONSTANTS from '../../../constants';
import CRMRepository from '../../../repository/crm/CRMRepository';

class OdooMerchantAutocomplete extends React.Component {
  static propTypes = {
    onSelected: PropTypes.func,
  };

  state = {
    selectedId: null,
    options: [],
  };

  inputChanged = (event, value) => {
    if (!!value) {
      this.setState((prevState) => ({
        ...prevState,
        selectedId: value,
      }));
      this.props.onSelected(value);
    }
  };

  onSearch = (event, value) => {
    if (!!value && value.trim().length > 0) {
      CRMRepository.searchMerchants(value).then((data) => {
        this.setState((prevState) => ({
          ...prevState,
          options: data,
        }));
      });
    }
  };

  debouncedSearch = debounce(this.onSearch, CONSTANTS.DEBOUNCE.NORMAL);

  render() {
    return (
      <div>
        <Autocomplete
          type="text"
          label="state"
          id="stateInput"
          value={this.state.selectedId}
          options={this.state.options}
          onChange={this.inputChanged}
          onInputChange={this.debouncedSearch}
          renderInput={(params) => (
            <TextField {...params} label="Odoo search" />
          )}
          renderOption={(props, option) => <div {...props}>{props.name}</div>}
          filterOptions={(a) => a}
          getOptionLabel={(option) => option.name}
        />
      </div>
    );
  }
}

export default withRouter(OdooMerchantAutocomplete);
