import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import UserIntegrationContainer from './components/UserIntegration/UserIntegrationContainer';
import styles from './UserIntegrations.module.css';

export default class UserIntegrations extends React.Component {
  static propTypes = {
    userId: PropTypes.number,
  };

  static defaultProps = {
    userId: 0,
  };

  render() {
    return (
      <Paper className={styles.userIntegrations}>
        <HorizontalSpacer size="small" />

        <UserIntegrationContainer userId={this.props.userId} />
      </Paper>
    );
  }
}
