import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { goToUserChangeEmail } from '../../../../store/navigation/users/thunks';
import NavigationService from '../../../../shared/navigation/NavigationService';
import CONSTANTS from '../../../../constants';
import UserRepository from '../../../../repository/user/UserRepository';
import { noop } from '../../../../utils/utils';
import UserActionsMenu from './UserActionsMenu';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToUserChangeEmail,
    },
    dispatch,
  );

class UserActionsMenuContainer extends React.Component {
  static propTypes = {
    userId: PropTypes.number,
    deleted: PropTypes.bool,
    reloadUser: PropTypes.func,
  };

  static defaultProps = {
    userId: 0,
    deleted: false,
    reloadUser: noop(),
  };

  goToChangeEmail = () => this.props.goToUserChangeEmail(this.props.userId);

  restoreUser = () => {
    return UserRepository.restoreUser(this.props.userId)
      .then((data) => {
        toastr.success('User restored', data.successMessage);
      })
      .catch((error) => toastr.error('Could not restore user', error.message))
      .finally(() => {
        this.props.reloadUser();
      });
  };

  deleteUser = () => {
    return UserRepository.deleteUser(this.props.userId)
      .then((data) => {
        toastr.success('User deleted', data.successMessage);
      })
      .catch((error) => toastr.error('Could not delete user', error.message))
      .finally(() => {
        this.props.reloadUser();
      });
  };

  goToEditDetails = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.USER_DETAILS_UPDATE.path, {
      userId: this.props.userId,
    });
  };

  render() {
    return (
      <UserActionsMenu
        changeEmail={this.goToChangeEmail}
        editDetails={this.goToEditDetails}
        restoreUser={this.restoreUser}
        deleteUser={this.deleteUser}
        deleted={this.props.deleted}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserActionsMenuContainer);
