import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { toastr } from 'react-redux-toastr';
import PartnerRepository from '../../../repository/partner/PartnerRepository';

class PartnerApiKeyPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionsList: [],
      selectedPermissions: new Set(),
    };
  }

  async componentDidMount() {
    await this.fetchPermissions();
    const initialSelectedPermissions =
      this.props.initialSelectedPermissions || [];
    let selectedPermissions = new Set(initialSelectedPermissions);
    this.setState({ selectedPermissions });
  }

  fetchPermissions = async () => {
    try {
      const permissionsList = await PartnerRepository.getAllPartnerApiKeysPermissions();
      this.setState({ permissionsList });
    } catch (error) {
      toastr.error('Error fetching permissions');
    }
  };

  handleCheckboxChange = (permission) => (event) => {
    let selectedPermissions = new Set(this.state.selectedPermissions);
    if (event.target.checked) {
      selectedPermissions.add(permission);
    } else {
      selectedPermissions.delete(permission);
    }
    this.setState({ selectedPermissions });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedPermissions !== this.state.selectedPermissions) {
      this.props.onPermissionsChange([...this.state.selectedPermissions]);
    }
  }

  render() {
    const { disabled } = this.props;
    const { selectedPermissions } = this.state;

    return (
      <div
        style={{
          textAlign: 'left',
          marginTop: '.8rem',
          marginBottom: '.8rem',
          display: 'flex',
          flexDirection: 'row',
        }}>
        <div style={{ flex: 1, width: '30%', marginRight: '.5rem' }}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 'bold', color: '#808080' }}>
            Default Api-Key permissions *
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'left',
            }}>
            <span
              role="img"
              aria-label={'info'}
              style={{ marginRight: '0.5rem', fontSize: '0.75rem' }}>
              ℹ️
            </span>
            <Typography variant="caption">
              Default Api-Key permissions will be set on Api-Keys generated when
              partner integration is activated in Merchant Portal.
            </Typography>
          </div>
        </div>

        <div style={{ flex: 1, display: 'flex', justifyContent: 'left' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {this.state.permissionsList.map((permission) => (
              <FormControlLabel
                key={permission}
                control={
                  <Checkbox
                    checked={selectedPermissions.has(permission)}
                    onChange={this.handleCheckboxChange(permission)}
                    disabled={disabled}
                  />
                }
                label={permission}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

PartnerApiKeyPermissions.propTypes = {
  required: PropTypes.bool,
  initialSelectedPermissions: PropTypes.arrayOf(PropTypes.string),
  onPermissionsChange: PropTypes.func.isRequired,
};

PartnerApiKeyPermissions.defaultProps = {
  required: false,
  initialSelectedPermissions: [],
};

export default PartnerApiKeyPermissions;
