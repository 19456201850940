import React, { useEffect } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import SearchBar from '../../../shared/components/SearchBar/SearchBar';
import styles from '../PointsCoupons.module.css';
import ShopRepository from '../../../repository/shop/ShopRepository';
import SelectShopButton from './SelectShopButton';

export default function ShopSearch(props) {
  const [shops, setShops] = React.useState([]);
  const [busy, setBusy] = React.useState(false);
  const [init, setInit] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(props.searchTerm);

  useEffect(() => {
    if (!init && searchTerm) {
      setInit(true);
      searchShops(searchTerm);
    }
  }, [init, searchTerm]);

  const searchShops = (searchTerm) => {
    if (searchTerm.length < 3) {
      toastr.warning('Type at least 3 characters');
      return;
    }
    setBusy(true);
    setSearchTerm(searchTerm);

    ShopRepository.search({ searchTerm: searchTerm })
      .then((data) => {
        setShops(data.content);
      })
      .catch((error) => toastr.error('Could not load users', error.message))
      .finally(() => {
        setBusy(false);
      });
  };

  const shopsTable = (
    <Table>
      <TableBody>
        {shops.map((shop) => (
          <TableRow key={shop.id} hover>
            <TableCell align="left" className={styles.tableCell}>
              {shop.id}
            </TableCell>
            <TableCell align="left" className={styles.tableCell}>
              {shop.name}
            </TableCell>
            <TableCell align="right" className={styles.tableCell}>
              {shop.id != null ? (
                <SelectShopButton
                  shop={shop}
                  searchTerm={searchTerm}
                  onClick={props.selectShop}
                />
              ) : (
                ''
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Paper elevation={3}>
      <SearchBar
        placeholder="Shop name or id"
        onSearch={searchShops}
        searchTerm={props.searchTerm ? props.searchTerm : ''}
      />
      <HorizontalSpacer />

      {busy ? <CircularProgress /> : shopsTable}
    </Paper>
  );
}
