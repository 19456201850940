import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getCampaignCallToActionText,
  getCampaignCallToActionAddress,
} from '../../../../store/campaigns/selectors';
import { patchCampaignCallToAction } from '../../../../store/campaigns/actions';
import CampaignExternalLinkAddress from './CampaignExternalLinkAddress';

const mapStateToProps = (state) => ({
  campaignCTAText: getCampaignCallToActionText(state),
  campaignCTAAddress: getCampaignCallToActionAddress(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchCampaignCallToAction,
    },
    dispatch,
  );

class CampaignExternalLinkAddressContainer extends React.Component {
  handleChange = (event) => {
    this.props.patchCampaignCallToAction({ url: event.target.value });
  };

  render() {
    return (
      <CampaignExternalLinkAddress
        value={this.props.campaignCTAAddress}
        onChange={this.handleChange}
        required={
          !!this.props.campaignCTAText || !!this.props.campaignCTAAddress
        }
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignExternalLinkAddressContainer);
