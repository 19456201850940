import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import CONSTANTS from '../../../constants';
import BackIconButton from '../../../shared/components/BackIconButton/BackIconButton';
import PartnerRepository from '../../../repository/partner/PartnerRepository';
import styles from '../Partners.module.css';
import NavigationService from '../../../shared/navigation/NavigationService';
import PartnerForm from '../components/PartnerForm';
import ApiKeys from './components/ApiKeys/ApiKeys';

class PartnerDetails extends React.Component {
  static propTypes = {
    partnerId: PropTypes.string,
  };

  static defaultProps = {
    partnerId: '',
  };

  state = {
    isBusy: false,
    fieldErrors: {},
    partner: {
      shops: [],
      integrations: [],
    },
  };

  componentDidMount() {
    this.loadPartner();
  }

  loadPartner = () => {
    this.setState({
      ...this.state,
      isBusy: true,
    });

    PartnerRepository.getById(this.props.partnerId)
      .then((partner) => {
        this.setState({
          ...this.state,
          partner,
        });
      })
      .catch((error) => toastr.error('Could not load partner', error.message))
      .finally(() => {
        this.setState({
          ...this.state,
          isBusy: false,
        });
      });
  };

  handleFieldChange = (prop) => (event) => {
    this.updatePartnerProp(prop, event.target.value);
    this.setFieldError(prop, null);
  };

  updateIntegrations = (integrations) => {
    let newState = {
      ...this.state,
      partner: {
        ...this.state.partner,
        integrations,
      },
    };
    this.setState(newState);
  };

  setFieldError = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [key]: value,
      },
    }));
  };

  updatePartnerProp = (prop, value) => {
    this.setState((prevState) => ({
      ...prevState,
      partner: {
        ...prevState.partner,
        [prop]: value,
      },
    }));
  };

  stateInputChanged = (event, value) => {
    this.updatePartnerProp('state', value);
    this.setFieldError('state', null);
  };

  handleSaved = (id) => {
    toastr.success('Updated');
    NavigationService.navigateTo(CONSTANTS.ROUTES.PARTNER_OVERVIEW.path);
  };

  save = () => {
    const handleUpdateFailure = (error) => {
      const { response } = error;
      if (response.status === 409 && response.data.error) {
        toastr.error(
          response.data.error.title,
          response.data.error.description,
        );
      } else if (response.status === 400 && response.data.fieldErrors) {
        response.data.fieldErrors.forEach((fieldError) =>
          this.setFieldError(fieldError.field, fieldError.message),
        );
      } else {
        toastr.error(
          'Could not save partner',
          response.data.errorMessage
            ? response.data.errorMessage
            : error.message,
        );
      }
    };

    PartnerRepository.update(this.state.partner)
      .then((data) => this.handleSaved(data.id))
      .catch((error) => handleUpdateFailure(error));
  };

  isMerchantPortalDynamicIntegrationEnabled = (partnerCode) => {
    if (!partnerCode) return false;
    const partnerCodesToDisableDynamicIntegrationList = [
      'MONIZZE',
      'PAYCONIQ',
      'lightspeed',
      'EDENRED',
      'WOOCOMMERCE',
      'KMO_SHOPS',
      'bc-matic',
      'plugandpos',
      'lambrechts',
      'touchsystems',
    ].map((value) => value.toUpperCase());
    return !partnerCodesToDisableDynamicIntegrationList.includes(
      partnerCode.toUpperCase(),
    );
  };

  render() {
    const partnerDetails = (
      <div>
        <Paper>
          <div className={styles.cardHeader}>
            <BackIconButton linkTo={CONSTANTS.ROUTES.PARTNER_OVERVIEW.path} />
            <Typography variant="h5" className={styles.partnerName}>
              {this.state.partner.name}
            </Typography>
          </div>

          <PartnerForm
            partner={this.state.partner}
            fieldErrors={this.state.fieldErrors}
            handleFieldChange={this.handleFieldChange}
            stateInputChanged={this.stateInputChanged}
            updateIntegrations={this.updateIntegrations}
            onSave={this.save}
            isCreate={false}
            displayMerchantPortalIntegrations={this.isMerchantPortalDynamicIntegrationEnabled(
              this.state.partner?.partnerCode,
            )}
          />
        </Paper>
        <HorizontalSpacer />
        <h2>Api keys</h2>
        <p>Only shows apiKeys of type PARTNER</p>
        <ApiKeys partnerId={this.state.partner.id} />
      </div>
    );

    return (
      <div className={styles.partnerDetails}>
        <HorizontalSpacer size="small" />

        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>{this.state.partner.id ? partnerDetails : <NoDataFound />}</div>
        )}
      </div>
    );
  }
}

export default withRouter(PartnerDetails);
