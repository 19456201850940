export const TARGET_USER_TYPES = Object.freeze({
  ALL_USERS: {
    key: '',
    label: 'All Users',
  },
  APP_USER: {
    key: 'APP_USER',
    label: 'App Users',
  },
});

export const SUPPORTED_TARGET_USER_TYPES = Object.values(TARGET_USER_TYPES).map(
  (userType) => userType.key,
);
