import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/Typography';
import styles from './RecipientLimitDisplay.module.css';

class RecipientLimitDisplay extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    value: 0,
  };

  render() {
    return (
      <Typography variant="subtitle1" paragraph>
        Max. amount of recipients:{' '}
        <span className={styles.numeric}>
          {this.props.value || 'All'} users
        </span>
      </Typography>
    );
  }
}

export default RecipientLimitDisplay;
