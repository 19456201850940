import { JoynV2Api } from '../api/joyn/JoynApi';

export default new (class RegistrationRequestRepository {
  search = (searchTerm, state) => {
    const params = { searchTerm, state };
    return JoynV2Api.post('/registration-requests/search', params);
  };

  delete = (id) => {
    return JoynV2Api.delete(`/registration-requests/${id}`);
  };
})();
