import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import ActionsMenu from '../../../../../shared/components/ActionsMenu/ActionsMenu';
import ConfirmedMenuItem from '../../../../../shared/components/ConfirmedMenuItem/ConfirmedMenuItem';
import { noop } from '../../../../../utils/utils';
import shopRepository from '../../../../../repository/shop/ShopRepository';
import NavigationService from '../../../../../shared/navigation/NavigationService';
import CONSTANTS from '../../../../../constants';

export default class ShopDetailActionMenu extends React.Component {
  static propTypes = {
    shopId: PropTypes.number,
    canRestoreShop: PropTypes.bool,
    canDeleteShop: PropTypes.bool,
    onStateChange: PropTypes.func,
  };

  static defaultProps = {
    shopId: 0,
    canRestoreShop: false,
    onStateChange: noop,
    canDeleteShop: false,
  };

  navigateToShopUpdatePage = () => {
    NavigationService.navigateTo(CONSTANTS.ROUTES.SHOP_UPDATE.path, {
      shopId: this.props.shopId,
    });
  };

  restoreShop = () => {
    return shopRepository.restore(this.props.shopId);
  };

  deleteShop = () => {
    return shopRepository.delete(this.props.shopId);
  };

  render() {
    return (
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}>
        <ActionsMenu>
          <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}>
            <MenuItem onClick={this.navigateToShopUpdatePage}>
              <ListItemIcon>{<EditIcon />}</ListItemIcon>
              <ListItemText>Edit details</ListItemText>
            </MenuItem>
          </HasPermission>

          <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}>
            {this.props.canRestoreShop ? (
              <ConfirmedMenuItem
                action={this.restoreShop}
                successAction={this.props.onStateChange}
                label="Restore shop"
                successMessage="The shop has been restored"
                confirmationTitle="Restore this shop?"
                confirmationContent="Are you sure you want to restore this shop?"
                icon={<UndoIcon />}
              />
            ) : null}
          </HasPermission>

          <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_SHOPS]}>
            {this.props.canDeleteShop ? (
              <ConfirmedMenuItem
                action={this.deleteShop}
                successAction={this.props.onStateChange}
                label="Delete shop"
                successMessage="The shop has been deleted"
                confirmationTitle="Delete this shop?"
                confirmationContent="Are you sure you want to delete this shop?"
                icon={<DeleteIcon />}
              />
            ) : null}
          </HasPermission>
        </ActionsMenu>
      </HasPermission>
    );
  }
}
