import { JoynV2Api } from '../api/joyn/JoynApi';

class UserConsentRepository {
  getAllConsent = (userId) => {
    return JoynV2Api.get(`/sammy/users/${userId}/user-consent`);
  };

  giveConsent = (userId, about) => {
    return JoynV2Api.post(`/sammy/users/${userId}/user-consent/${about}`);
  };

  declineConsent = (userId, about) => {
    return JoynV2Api.delete(`/sammy/users/${userId}/user-consent/${about}`);
  };
}
export default new UserConsentRepository();
