import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadUserById, changeUserEmail } from '../../../store/users/thunks';
import { goToUserDetails } from '../../../store/navigation/users/thunks';
import {
  getUserEmail,
  isFetchingUser,
  isUpdatingEmail,
} from '../../../store/users/selectors';
import UserChangeEmail from './UserChangeEmail';

const mapStateToProps = (state) => ({
  email: getUserEmail(state),
  isFetchingUser: isFetchingUser(state),
  isUpdatingEmail: isUpdatingEmail(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadUserById,
      changeUserEmail,
      goToUserDetails,
    },
    dispatch,
  );

class UserChangeEmailContainer extends React.Component {
  state = {
    userId: this.props.match.params.userId,
  };

  componentDidMount() {
    this.props.loadUserById(this.state.userId);
  }

  goToUserDetails = () => this.props.goToUserDetails(this.state.userId);

  render() {
    return (
      <UserChangeEmail
        email={this.props.email}
        onSave={this.props.changeUserEmail}
        onCancel={this.goToUserDetails}
        isLoading={this.props.isFetchingUser || this.props.isUpdatingEmail}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserChangeEmailContainer);
