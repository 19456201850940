import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import LinkButton from '../../../../../shared/components/LinkButton/LinkButton';
import CONSTANTS from '../../../../../constants';
import styles from '../../../Partners.module.css';

export default class PartnerOverviewTable extends React.Component {
  static propTypes = {
    partners: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    partners: [],
  };

  render() {
    return (
      <Paper className={styles.tableWrapper} id="merchantOverviewTable">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.partners.map((p) => (
              <TableRow key={p.partnerCode} hover>
                <TableCell>{p.partnerCode}</TableCell>
                <TableCell>{p.name}</TableCell>
                <TableCell>{p.description}</TableCell>
                <TableCell>{p.email}</TableCell>
                <TableCell>{p.state}</TableCell>
                <TableCell align="right">
                  <LinkButton
                    text="Details"
                    linkTo={generatePath(
                      CONSTANTS.ROUTES.PARTNER_DETAILS.path,
                      {
                        partnerId: p.id,
                      },
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
