import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Button, Checkbox, Chip, FormControlLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import BackIconButton from '../../../shared/components/BackIconButton/BackIconButton';
import CONSTANTS from '../../../constants';
import getError, { noop } from '../../../utils/utils';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import Field from '../../../shared/components/Field/Field';
import fieldStyles from '../../../shared/components/Field/Field.module.css';
import UserRepository from '../../../repository/user/UserRepository';
import styles from './UserDetails.module.css';
import UserActionsMenuContainer from './UserActionsMenu/UserActionsMenuContainer';

export default class UserGeneralCard extends React.Component {
  static defaultProps = {
    user: noop,
    reloadUser: noop,
  };

  state = {
    resetPasswordLink: null,
  };

  requestLink = () => {
    UserRepository.requestResetPasswordLink(this.props.user.id)
      .then((data) => {
        this.setState({
          ...this.state,
          resetPasswordLink: data.passwordResetLink.substring(0, 40) + '...',
        });
        this.copyToClipboard(data.passwordResetLink);
      })
      .catch((error) => {
        toastr.error('Could not request resetPassword link', getError(error));
      });
  };

  copyToClipboard = (link) => {
    const tempElement = document.createElement('textarea');
    tempElement.value = link;
    tempElement.setAttribute('readonly', '');
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);

    toastr.success('Success', `Copied to clipboard`);
  };

  render() {
    const user = this.props.user;
    if (!user) {
      return null;
    }
    return (
      <div id="userGeneralCard">
        <Paper>
          <div className={styles.cardHeader}>
            <BackIconButton linkTo={CONSTANTS.ROUTES.USER_OVERVIEW.path} />
            <UserActionsMenuContainer
              userId={user.id}
              deleted={user.deleted}
              reloadUser={this.props.reloadUser}
            />

            <Typography variant="h5" className={styles.userName}>
              {user.firstName} {user.lastName}
            </Typography>
            {user.deleted ? <Chip label="Deleted" color="secondary" /> : null}
          </div>
          <div className={styles.userForm}>
            <div className={styles.userFormCol}>
              <Field label="First name" disabled value={user.firstName} />
              <Field
                label="Last name"
                disabled
                value={user.lastName}
                margin="normal"
              />
              <Field label="Email" disabled value={user.email} />
              <Field label="Gender" disabled value={user.gender} />
              <Field
                label="Birthday"
                disabled
                value={
                  user.birthDay
                    ? moment(user.birthDay).format('MMMM Do, YYYY')
                    : ''
                }
              />
              <Field label="User language" disabled value={user.userLanguage} />
              <Field
                label="Notification language"
                disabled
                value={user.notificationLanguage}
              />
              <Field label="Zipcode" disabled value={user.zipcode} />
              <Field label="City" disabled value={user.city} />
              <Field label="Region" disabled value={user.region} />
              <Field label="Country" disabled value={user.country} />
              <Field label="Phone" disabled value={user.phone} />
              <Field label="User origin" disabled value={user.origin} />
              <div id="Password Reset Link" className={fieldStyles.container}>
                <div className={fieldStyles.label}>Password Reset Link</div>
                <div>
                  {this.state.resetPasswordLink ? (
                    this.state.resetPasswordLink
                  ) : (
                    <Button
                      className={styles.button}
                      variant="text"
                      color="secondary"
                      onClick={this.requestLink}>
                      Request link
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <FormControlLabel
              className={styles.checkBox}
              disabled
              control={<Checkbox checked={user.emailVerified} />}
              label="Email verified"
            />
            <FormControlLabel
              className={styles.checkBox}
              disabled
              control={<Checkbox checked={user.adminUser} />}
              label="Is admin"
            />
          </div>
          <div className={styles.formHelperText}>
            {user.deleted ? (
              <Typography variant="caption" paragraph>
                User was deleted on{' '}
                {moment(user.deletionDate).format('DD/MM/YYYY HH:mm')}, will be
                erased on{' '}
                {moment(user.deletionDate)
                  .add(30, 'days')
                  .format('DD/MM/YYYY')}
              </Typography>
            ) : null}
            <Typography variant="caption" paragraph>
              User was created on{' '}
              {moment(user.dateJoined).format('DD/MM/YYYY HH:mm')}
            </Typography>
            <HorizontalSpacer size={'small'} />
          </div>
        </Paper>
      </div>
    );
  }
}
