import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/core/SvgIcon/SvgIcon';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { noop } from '../../../utils/utils';

export default class ButtonWithConfirmation extends React.Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    param: PropTypes.any,
    message: PropTypes.string,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    buttonVariant: PropTypes.string,
    icon: PropTypes.element,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    id: PropTypes.string,
    showIconOnButton: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onConfirm: noop,
    param: noop,
    message: 'Are you sure?',
    title: 'Title',
    buttonText: 'Ok',
    buttonVariant: 'text',
    icon: <CheckIcon />,
    confirmButtonText: 'Yes, looks good',
    cancelButtonText: 'Nope',
    showIconOnButton: false,
    disabled: false,
  };

  state = {
    isDialogOpen: false,
  };

  showConfirmDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  hideConfirmDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  confirmCreation = () => {
    let onConfirmResult = this.props.onConfirm(this.props.param);
    if (onConfirmResult) {
      onConfirmResult.then(() => {
        this.hideConfirmDialog();
      });
    }
  };

  render() {
    const dialogText = (
      <div>
        <h3>{this.props.message}</h3>
        <div>{this.props.children}</div>
      </div>
    );

    return (
      <div>
        {this.getButton()}
        <ConfirmationDialog
          isOpen={this.state.isDialogOpen}
          onConfirm={this.confirmCreation}
          onClose={this.hideConfirmDialog}
          onCancel={this.hideConfirmDialog}
          dialogTitle={this.props.title}
          dialogContent={dialogText}
          confirmButtonText={this.props.confirmButtonText}
          cancelButtonText={this.props.cancelButtonText}
          icon={this.props.icon}
        />
      </div>
    );
  }

  getButton() {
    return (
      <Button
        id={this.getButtonId()}
        color={'secondary'}
        onClick={this.showConfirmDialog}
        variant={this.props.buttonVariant}
        disabled={this.props.disabled}>
        {this.props.showIconOnButton ? this.props.icon : null}
        {this.props.buttonText}
      </Button>
    );
  }

  getButtonId() {
    return this.props.id
      ? `${this.props.id}_button`
      : `${this.props.buttonText.replace(' ', '_')}_button`;
  }
}
