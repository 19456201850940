import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getCampaignCallToActionText,
  getCampaignCallToActionAddress,
} from '../../../../store/campaigns/selectors';
import { patchCampaignCallToAction } from '../../../../store/campaigns/actions';
import CampaignExternalLinkText from './CampaignExternalLinkText';

const mapStateToProps = (state) => ({
  campaignCTAText: getCampaignCallToActionText(state),
  campaignCTAAddress: getCampaignCallToActionAddress(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchCampaignCallToAction,
    },
    dispatch,
  );

class CampaignExternalLinkTextContainer extends React.Component {
  handleChange = (event) => {
    this.props.patchCampaignCallToAction({ text: event.target.value });
  };

  render() {
    return (
      <CampaignExternalLinkText
        value={this.props.campaignCTAText}
        onChange={this.handleChange}
        required={
          !!this.props.campaignCTAText || !!this.props.campaignCTAAddress
        }
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignExternalLinkTextContainer);
