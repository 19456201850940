import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { noop } from '../../../../utils/utils';

export default class CampaignNameInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: '',
    onChange: noop,
  };

  render() {
    return (
      <TextField
        id="campaignNameInput"
        label="Campaign Name"
        value={this.props.value}
        onChange={this.props.onChange}
        margin="normal"
        inputProps={{ maxLength: '128' }}
        fullWidth
        required
      />
    );
  }
}
