import React, { useEffect } from 'react';
import { Button, Chip, FormControl, MenuItem, Select } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import CONSTANTS from '../../../../../constants';
import HasPermission from '../../../../../shared/components/User/HasPermission';
import MerchantRepository from '../../../../../repository/merchant/MerchantRepository';
import { getAuthUser } from '../../../../../store/session/selectors';
import styles from './MerchantSettings.module.css';

const mapStateToProps = (state) => ({
  authUser: getAuthUser(state),
});

function MerchantSettings(props) {
  const [newSetting, setNewSetting] = React.useState(null);
  const [availableSettings, setAvailableSettings] = React.useState([]);
  const [merchantFeatures, setMerchantFeatures] = React.useState({});

  const handleNewSettingChange = (event) => {
    setNewSetting(event.target.value);
  };

  const createNewSetting = () => {
    MerchantRepository.createMerchantSetting(props.merchantId, newSetting)
      .then(() => {
        toastr.success('Setting created');
      })
      .catch((error) =>
        toastr.error(
          'Could not create setting',
          error.response.data.errorMessage,
        ),
      )
      .finally(() => {
        reloadSettings();
      });
  };

  const handleDelete = (chipToDelete) => {
    return MerchantRepository.deleteMerchantSetting(
      props.merchantId,
      chipToDelete,
    )
      .then(() => {
        toastr.success('Setting deleted');
      })
      .catch((error) => toastr.error('Could not delete setting', error.message))
      .finally(() => {
        reloadSettings();
      });
  };

  const reloadSettings = () => {
    MerchantRepository.getMerchantById(props.merchantId)
      .then((merchant) => {
        setMerchantFeatures(merchant.accountFeaturesByType);
      })
      .catch((error) =>
        toastr.error('Could not reload settings', error.message),
      );
  };

  const loadAvailableSettings = () => {
    MerchantRepository.availableMerchantSettings().then((result) => {
      setAvailableSettings(result);
    });
  };

  const merchantFeaturesChips = () => {
    return Object.keys(merchantFeatures)
      .sort()
      .flatMap((type) =>
        merchantFeatures[type].sort().map((feature) => {
          return {
            key: `${type}_${feature}`,
            name: feature,
            type: type,
            title: getChipTooltip(type),
            canDelete:
              type === 'merchantSetting' &&
              props.authUser.hasSomePermission([
                CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS,
              ]),
          };
        }),
      );
  };

  const getChipTooltip = (key) => {
    switch (key) {
      case 'pricePlan':
        return 'Because included in price plan';
      case 'merchantSetting':
        return 'Because configured in the database';
      case 'country':
        return 'Because of country';
      case 'onlineOnboarding':
        return 'Because of onboarding via OMOB';
      default:
        return key;
    }
  };

  useEffect(() => {
    loadAvailableSettings();
    setMerchantFeatures(props.features);
  }, [props.features]);

  return (
    <div>
      <div>
        {merchantFeaturesChips().map((chip) => (
          <Chip
            key={chip.key}
            label={chip.name}
            color={'default'}
            className={styles[chip.type]}
            title={chip.title}
            onDelete={chip.canDelete ? () => handleDelete(chip.name) : null}
          />
        ))}
      </div>

      <div>
        <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_MERCHANTS]}>
          <FormControl className={styles.formControl}>
            <Select
              labelId="addSettingLabel"
              id="addSettingSelect"
              value={newSetting}
              onChange={handleNewSettingChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableSettings.map((setting) => (
                <MenuItem value={setting}>{setting}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Button color="secondary" onClick={createNewSetting}>
              Add
            </Button>
          </FormControl>
        </HasPermission>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(MerchantSettings);
