import { JoynV2Api } from '../api/joyn/JoynApi';

export default new (class PartnerRepository {
  searchPartners = (params) => JoynV2Api.get('/sammy/partners', params);

  allPartnerCodes = (params) => JoynV2Api.get('/sammy/partners/codes', params);

  getById = (id) => JoynV2Api.get(`/sammy/partners/${id}`);

  update = (partner) =>
    JoynV2Api.post(`/sammy/partners/${partner.id}`, { ...partner });

  create = (partner) => JoynV2Api.post(`/sammy/partners`, { ...partner });

  createApiKey = (partnerId, params) =>
    JoynV2Api.put(`/sammy/partners/${partnerId}/api-keys`, params);

  getPartnerApiKeys = (partnerId) =>
    JoynV2Api.get(`/sammy/partners/${partnerId}/api-keys`);

  getAllPartnerApiKeysPermissions = () =>
    JoynV2Api.get(`/sammy/partners/api-keys/permissions`);

  deletePartnerApiKey = (partnerId, apiKey) =>
    JoynV2Api.delete(`/sammy/partners/${partnerId}/api-keys/${apiKey}`);
})();
