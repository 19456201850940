import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCampaignLanguage } from '../../../../store/campaigns/selectors';
import { patchCampaign } from '../../../../store/campaigns/actions';
import LanguageDropDown from '../../../../shared/components/LanguageDropDown/LanguageDropDown';
import { LANGUAGES } from '../../../../shared/constants/languages';

const mapStateToProps = (state) => ({
  campaignLanguage: getCampaignLanguage(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchCampaign,
    },
    dispatch,
  );

class CampaignLanguageDropDownContainer extends React.Component {
  componentDidMount() {
    if (!this.props.campaignLanguage) {
      this.patchCampaignLanguage(LANGUAGES.NL);
    }
  }

  handleChange = (event) => {
    this.patchCampaignLanguage(event.target.value);
  };

  patchCampaignLanguage = (language) => {
    this.props.patchCampaign({ templateLanguage: language });
  };

  render() {
    return (
      <LanguageDropDown
        id="campaignLanguageDropDown"
        label="Campaign Language"
        value={this.props.campaignLanguage}
        onChange={this.handleChange}
        margin="normal"
        fullWidth
        required
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignLanguageDropDownContainer);
