export const CONSTANTS = Object.freeze({
  ROUTES: {
    FOUR_OH_FOUR: { path: '/404' },
    LOGIN: { path: '/login', label: 'Login' },
    HOME: { path: '/', label: 'Home' },
    MERCHANT_OVERVIEW: { path: '/merchants', label: 'Merchants' },
    MERCHANT_DETAILS: { path: '/merchants/:merchantId' },
    MERCHANT_UPDATE: { path: '/merchants/:merchantId/update' },
    MERCHANT_CREATE: { path: '/merchants/create' },
    SHOP_DETAILS: { path: '/shops/:shopId' },
    SHOP_UPDATE: { path: '/shops/:shopId/update' },
    SHOP_CREATE: { path: '/shops/:merchantId/create' },
    USER_OVERVIEW: { path: '/users', label: 'Users' },
    POINTS_COUPONS: { path: '/points-coupons', label: 'Points/Coupons' },
    PARTNER_OVERVIEW: { path: '/partners', label: 'Partners' },
    PARTNER_DETAILS: { path: '/partners/:partnerId' },
    PARTNER_CREATE: { path: '/partners/create' },
    MERGE_USERS: { path: '/merge-users', label: 'Merge Users' },
    REGISTRATION_REQUESTS: {
      path: '/registration-requests',
      label: 'Registration Requests',
    },
    USER_DETAILS: { path: '/users/:userId' },
    USER_DETAILS_UPDATE: { path: '/users/:userId/update' },
    USER_CHANGE_EMAIL: { path: '/users/:userId/change-email' },
    IDENTIFIERS_OVERVIEW: { path: '/identifiers', label: 'Identifiers' },
    CAMPAIGN_OVERVIEW: { path: '/campaigns', label: 'Campaigns' },
    CAMPAIGN_INITIALISATION: { path: '/campaigns/initialisation' },
    CAMPAIGN_DETAILS: { path: '/campaigns/details/:campaignId' },
    CAMPAIGN_TARGET: { path: '/campaigns/target/:campaignId' },
    EDENRED_INTEGRATION: {
      path: '/integration/edenred',
      label: 'Edenred integration',
    },
    AUTH_CALLBACK: { path: '/callback' },
    CATEGORIES_OVERVIEW: { path: '/categories', label: 'Categories' },
    CATEGORIES_DETAIL: { path: '/categories/:categoryId' },
  },
  VERSION_INFO: {
    APP_VERSION: process.env.REACT_APP_VERSION,
    BUILD_NUMBER: process.env.REACT_APP_BUILD_NUMBER,
  },
  ENV: {
    NAME: process.env.REACT_APP_ENV_NAME,
    API_V1: process.env.REACT_APP_API_V1,
    API_V2: process.env.REACT_APP_API_V2,
    SAMMY_API: process.env.REACT_APP_SAMMY_API,
    API_JOYN_CAMPAIGN: process.env.REACT_APP_API_JOYN_CAMPAIGN,
    API_JOYN_CRM: process.env.REACT_APP_API_JOYN_CRM,
    KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
    KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM,
    KEYCLOAK_CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
    SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  },
  ENVIRONMENTS: {
    DEV: 'development',
    INT: 'int',
    ACC: 'acc',
    PRD: 'prd',
  },
  DEBOUNCE: {
    FAST: 150,
    NORMAL: 300,
    SLOW: 600,
  },
  PERMISSIONS: {
    OP_READ_USERS: 'OP_READ_USERS',
    OP_UPDATE_USERS: 'OP_UPDATE_USERS',
    OP_CHANGE_USER_EMAIL: 'OP_CHANGE_USER_EMAIL',
    OP_RESTORE_USER: 'OP_RESTORE_USER',
    OP_DELETE_USER: 'OP_DELETE_USER',
    OP_READ_REGISTRATION_REQUESTS: 'OP_READ_REGISTRATION_REQUESTS',
    OP_DELETE_REGISTRATION_REQUESTS: 'OP_DELETE_REGISTRATION_REQUESTS',
    OP_READ_IDENTIFIERS: 'OP_READ_IDENTIFIERS',
    OP_READ_MERCHANTS: 'OP_READ_MERCHANTS',
    OP_WRITE_MERCHANTS: 'OP_WRITE_MERCHANTS',
    OP_READ_SHOPS: 'OP_READ_SHOPS',
    OP_WRITE_SHOPS: 'OP_WRITE_SHOPS',
    OP_READ_PARTNER_BALANCES: 'OP_READ_PARTNER_BALANCES',
    OP_READ_CAMPAIGNS: 'OP_READ_CAMPAIGNS',
    OP_WRITE_CAMPAIGNS: 'OP_WRITE_CAMPAIGNS',
    OP_READ_CRM_SHOPS: 'OP_READ_CRM_SHOPS',
    OP_WRITE_CRM_SHOPS: 'OP_WRITE_CRM_SHOPS',
    OP_EDENRED: 'OP_EDENRED',
    OP_READ_MERCHANTS_AUTH_USER: 'OP_READ_MERCHANTS_AUTH_USER',
    OP_WRITE_MERCHANTS_AUTH_USER: 'OP_WRITE_MERCHANTS_AUTH_USER',
    OP_READ_CATEGORIES: 'OP_READ_CATEGORIES',
    OP_READ_PARTNERS: 'OP_READ_PARTNERS',
    OP_UPDATE_PARTNERS: 'OP_UPDATE_PARTNERS',
  },
});

export default CONSTANTS;
