import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CategoryRepository from '../../repository/categories/CategoryRepository';
import HorizontalSpacer from '../../shared/components/HorizontalSpacer/HorizontalSpacer';
import styles from './CategoriesOverview.module.css';

export default class CategorySuggestions extends React.Component {
  static propTypes = {
    categoryId: PropTypes.string,
  };

  state = {
    isBusy: false,
    coverImages: [],
    rewards: [],
  };

  componentDidMount() {
    this.loadSuggestions();
  }

  loadSuggestions = () => {
    this.setState({
      ...this.state,
      isBusy: true,
    });
    CategoryRepository.getSuggestions(this.props.categoryId).then((data) => {
      this.setState({
        ...this.state,
        coverImages: data.coverImages,
        rewards: data.rewards,
        isBusy: false,
      });
    });
  };

  render() {
    return (
      <>
        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>
            <HorizontalSpacer size="XL" />
            <Typography variant={'h5'}>Image Suggestions</Typography>
            <Paper>
              {this.state.coverImages.length ? (
                this.state.coverImages.map((i) => (
                  <img className={styles.suggestionImage} src={i} alt={i} />
                ))
              ) : (
                <p>No images found</p>
              )}
            </Paper>

            <HorizontalSpacer size="XL" />
            <Typography variant={'h5'}>Reward Suggestions</Typography>
            {this.state.rewards.length ? (
              this.state.rewards.map((i) => (
                <div className={styles.suggestionReward}>
                  <div>
                    <img src={i.image + '?fm=png'} alt={i.image} />
                  </div>
                  <b>{i.cost}</b>
                  {i.title
                    .filter((t) => t.language === 'en')
                    .map((t) => (
                      <div className={styles.suggestionRewardName}>
                        {t.content}
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <p>No rewards found</p>
            )}
          </div>
        )}
      </>
    );
  }
}
