import React from 'react';
import CONSTANTS from '../../../constants';
import HasPermission from '../../../shared/components/User/HasPermission';
import styles from './CampaignOverview.module.css';
import CreateCampaignButtonContainer from './components/CreateCampaignButton/CreateCampaignButtonContainer';
import JoynMessageCampaignOverviewContainer from './components/JoynMessageCampaignOverview/JoynMessageCampaignOverviewContainer';

export default class CampaignOverview extends React.Component {
  render() {
    return (
      <div className={styles.campaignOverview}>
        <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_CAMPAIGNS]}>
          <CreateCampaignButtonContainer />
        </HasPermission>

        <JoynMessageCampaignOverviewContainer />
      </div>
    );
  }
}
