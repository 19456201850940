import React from 'react';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { generatePath, Link } from 'react-router-dom';
import SearchBar from '../../shared/components/SearchBar/SearchBar';
import HorizontalSpacer from '../../shared/components/HorizontalSpacer/HorizontalSpacer';
import IdentifiersRepository from '../../repository/identifiers/IdentifiersRepository';
import NoDataFound from '../../shared/components/NoDataFound/NoDataFound';
import CONSTANTS from '../../constants';
import styles from './IdentifiersOverview.module.css';

export default class IdentifiersOverview extends React.Component {
  state = {
    identifiers: [],
    isBusy: false,
  };

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  searchIdentifiers = (serialNumber) => {
    if (serialNumber && serialNumber.length < 3) {
      toastr.warning('Type at least 3 characters');
      return;
    }
    this.setIsBusy(true);

    IdentifiersRepository.search(serialNumber.toUpperCase())
      .then((data) => {
        this.setState({
          ...this.state,
          identifiers: data,
        });
      })
      .catch((error) =>
        toastr.error('Could not load identifiers', error.message),
      )
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  searchBar = () => (
    <div className={styles.searchIdentifiers}>
      <SearchBar
        placeholder="Search for identifiers by serialNumber"
        onSearch={this.searchIdentifiers}
      />
    </div>
  );

  renderResults(identifiers) {
    return (
      <Paper>
        <Table id="identifierResultsTable">
          <TableHead>
            <TableRow>
              <TableCell>Serial</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>First used at</TableCell>
              <TableCell>Linked to user</TableCell>
              <TableCell>Link date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {identifiers.map((i) => (
              <TableRow key={i.serialNumber} hover>
                <TableCell>
                  <span title={i.code}>{i.serialNumber}</span>
                </TableCell>
                <TableCell>{i.state}</TableCell>
                <TableCell>{i.type}</TableCell>
                <TableCell>
                  <span title={i.firstUsedAtMerchantId}>
                    {i.firstUsedAtMerchantName}
                  </span>
                </TableCell>
                <TableCell>{this.renderUserMail(i)}</TableCell>
                <TableCell>{i.linkDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderUserMail(identifier) {
    if (identifier.linkedUserId) {
      return (
        <Link
          to={generatePath(CONSTANTS.ROUTES.USER_DETAILS.path, {
            userId: identifier.linkedUserId,
          })}>
          {identifier.linkedUserEmail}
        </Link>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={styles.identifiersOverview}>
        <h1>Identifiers</h1>
        {this.searchBar()}
        <HorizontalSpacer />
        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>
            {this.state.identifiers && this.state.identifiers.length ? (
              this.renderResults(this.state.identifiers)
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
      </div>
    );
  }
}
