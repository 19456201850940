import { JoynV2Api } from '../api/joyn/JoynApi';

export default new (class ShopRepository {
  getShopById = (shopId) => JoynV2Api.get(`/sammy/shops/${shopId}`);

  updateShop = (shop) => JoynV2Api.post(`/sammy/shops/${shop.id}`, shop);

  createShop = (shop) => JoynV2Api.post(`/sammy/shops`, shop);

  addPartner = (params) =>
    JoynV2Api.post(`/sammy/shops/${params.shopId}/partner`, params);

  addApiKey = (params) =>
    JoynV2Api.post(`/sammy/shops/${params.shopId}/api-key`, params);

  addPos = (params) =>
    JoynV2Api.post(`/sammy/shops/${params.shopId}/pos`, params);

  restore = (shopId) => JoynV2Api.put(`/sammy/shops/${shopId}/restore`);

  delete = (shopId) => JoynV2Api.delete(`/sammy/shops/${shopId}`);

  deleteCustomer = (shopId, userId) =>
    JoynV2Api.delete(`/sammy/shops/${shopId}/users/${userId}`);

  search = (params) => JoynV2Api.get(`/sammy/shops`, params);
})();
