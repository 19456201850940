/* eslint-disable no-param-reassign */
import { path, tap } from 'ramda';
import { toastr } from 'react-redux-toastr';
import { wrapThunkWithLifecycle } from 'redux-thunk-lifecycle/src/helpers/wrap-thunk-with-lifecycle';
import {
  goToCampaignDetails,
  goToCampaignOverview,
} from '../navigation/campaigns/thunks';
import CampaignCreatorRepository from '../../repository/campaignCreator/CampaignCreatorRepository';
import { getAuthUserEmail } from '../session/selectors';
import ERRORS from './thunkErrorCodes';
import {
  CREATE_CAMPAIGN,
  GET_MESSAGE_CAMPAIGNS,
  LOAD_CAMPAIGN_BY_ID,
  PUBLISH_CAMPAIGN,
  SEND_TEST_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from './action-types';
import { getCampaign } from './selectors';

class Thunks {
  loadJoynMessageCampaigns(page) {
    return () =>
      CampaignCreatorRepository.getCampaigns(page).catch((error) => {
        error.title = 'Could not fetch Joyn Message campaigns:';
        return Promise.reject(error);
      });
  }

  loadCampaignById(campaignId) {
    return () =>
      CampaignCreatorRepository.getCampaignById(campaignId).catch((error) => {
        error.title = 'Could not fetch campaign:';
        return Promise.reject(error);
      });
  }

  createCampaign(campaign) {
    return (dispatch) =>
      CampaignCreatorRepository.createCampaign(campaign)
        .then(
          tap((savedCampaign) =>
            dispatch(goToCampaignDetails(savedCampaign.id)),
          ),
        )
        .catch((error) => {
          error.title = 'Could not create campaign:';
          return Promise.reject(error);
        });
  }

  createCampaignFromStore = () => (dispatch, getState) => {
    const campaign = getCampaign(getState());

    return dispatch(this.createCampaign(campaign));
  };

  updateCampaign(campaign) {
    const showSuccessMessage = () =>
      toastr.success('Campaign Updated', 'The campaign was saved');

    return () =>
      CampaignCreatorRepository.updateCampaign(campaign.id, campaign)
        .then(tap(showSuccessMessage))
        .catch((error) => {
          error.title = 'Could not update campaign:';
          return Promise.reject(error);
        });
  }

  updateCampaignFromStore = () => (dispatch, getState) => {
    const campaign = getCampaign(getState());

    return dispatch(this.updateCampaign(campaign));
  };

  sendTestCampaign(recipients, campaignId, campaignLanguage) {
    const showSuccessMessage = () =>
      toastr.success('Test Sent', 'A test campaign has been sent');

    return () =>
      CampaignCreatorRepository.sendTestCampaign(
        recipients,
        campaignId,
        campaignLanguage,
      )
        .then(tap(showSuccessMessage))
        .catch((error) => {
          error.title = 'Could not send test campaign:';
          return Promise.reject(error);
        });
  }

  updateAndSendTestCampaign = () => (dispatch, getState) => {
    const authenticatedUser = getAuthUserEmail(getState());
    const recipients = [authenticatedUser];

    return dispatch(this.updateCampaignFromStore()).then((campaign) =>
      dispatch(
        this.sendTestCampaign(
          recipients,
          campaign.id,
          campaign.templateLanguage,
        ),
      ),
    );
  };

  publishCampaign(campaignId, campaignLanguage) {
    const showSuccessMessage = () => toastr.success('Campaign Published');

    const mapUpdateErrorResponse = (error) => {
      const errorResponseMessage = path(
        ['response', 'data', 'errorMessage'],
        error,
      );

      const fieldErrorMessage = path(
        ['response', 'data', 'fieldErrors', '0', 'message'],
        error,
      );

      const customErrorMessage = ERRORS[PUBLISH_CAMPAIGN][fieldErrorMessage];

      error.message = customErrorMessage || error.message;
      error.title = errorResponseMessage || 'Could not publish campaign:';

      return error;
    };

    return (dispatch) =>
      CampaignCreatorRepository.publishCampaign(campaignId, campaignLanguage)
        .then(tap(() => dispatch(goToCampaignOverview())))
        .then(tap(showSuccessMessage))
        .catch((error) => Promise.reject(mapUpdateErrorResponse(error)));
  }

  updateAndPublishCampaign = () => (dispatch) =>
    dispatch(this.updateCampaignFromStore()).then((campaign) =>
      dispatch(this.publishCampaign(campaign.id, campaign.templateLanguage)),
    );
}

const thunks = new Thunks();

thunks.loadJoynMessageCampaigns = wrapThunkWithLifecycle(
  GET_MESSAGE_CAMPAIGNS,
  thunks.loadJoynMessageCampaigns,
  thunks,
);
thunks.loadCampaignById = wrapThunkWithLifecycle(
  LOAD_CAMPAIGN_BY_ID,
  thunks.loadCampaignById,
  thunks,
);
thunks.createCampaign = wrapThunkWithLifecycle(
  CREATE_CAMPAIGN,
  thunks.createCampaign,
  thunks,
);
thunks.updateCampaign = wrapThunkWithLifecycle(
  UPDATE_CAMPAIGN,
  thunks.updateCampaign,
  thunks,
);
thunks.sendTestCampaign = wrapThunkWithLifecycle(
  SEND_TEST_CAMPAIGN,
  thunks.sendTestCampaign,
  thunks,
);
thunks.publishCampaign = wrapThunkWithLifecycle(
  PUBLISH_CAMPAIGN,
  thunks.publishCampaign,
  thunks,
);

export default thunks;
export const {
  loadJoynMessageCampaigns,
  loadCampaignById,
  createCampaign,
  createCampaignFromStore,
  updateCampaign,
  updateCampaignFromStore,
  sendTestCampaign,
  updateAndSendTestCampaign,
  publishCampaign,
  updateAndPublishCampaign,
} = thunks;
