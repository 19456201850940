import { JoynCampaignApi } from '../api/joyn/JoynApi';
import { LANGUAGES } from '../../shared/constants/languages';

export const CAMPAIGN_SCOPE = 'JOYN';

export default new (class CampaignCreatorRepository {
  getCampaigns = (page) => {
    // TODO: discuss with BE: /campaigns should not be required
    const url = `/campaigns`;
    const params = {
      page,
      size: 10,
      sort: 'createdDate,DESC',
      scope: CAMPAIGN_SCOPE,
    };
    const config = { headers: { 'Accept-Language': LANGUAGES.EN } };

    return JoynCampaignApi.get(url, params, config);
  };

  getCampaignById = (campaignId) => {
    const url = `/campaigns/${campaignId}`;

    return JoynCampaignApi.get(url);
  };

  getUserCampaigns = (email) => {
    const url = `support/user-campaigns?email=${encodeURIComponent(email)}`;

    return JoynCampaignApi.get(url);
  };

  createCampaign = (campaign) => {
    const url = `/campaigns`;
    const params = { ...campaign, scope: CAMPAIGN_SCOPE };

    return JoynCampaignApi.post(url, params);
  };

  updateCampaign = (campaignId, campaign) => {
    const url = `/campaigns/${campaignId}`;
    const params = { ...campaign };

    return JoynCampaignApi.put(url, params);
  };

  sendTestCampaign = (recipients, campaignId, templateLanguage) => {
    const url = `/campaigns/${campaignId}/test`;
    const params = { recipients, templateLanguage };

    return JoynCampaignApi.post(url, params);
  };

  publishCampaign = (campaignId, templateLanguage) => {
    const url = `/campaigns/${campaignId}/publish`;
    const params = { templateLanguage };

    return JoynCampaignApi.post(url, params);
  };
})();
