import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import keycloakClient from './shared/keycloak/keycloakClient';
import store, { history } from './store/store';
import ShellContainer from './shell/ShellContainer';
import theme from './theme';

class App extends Component {
  state = {
    isLoading: true,
  };

  setIsLoading = (isLoading) => {
    this.setState({ isLoading });
  };

  async componentDidMount() {
    try {
      const isAuthenticated = await keycloakClient.authenticate();

      if (!isAuthenticated) {
        window.location.reload();
      } else {
        this.setIsLoading(false);
      }
    } catch {
      Sentry.captureMessage(
        'Failed to authenticate through keycloak',
        Sentry.Severity.Error,
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    // TODO: maybe extract all providers (incl. configuration logic) to an appProvider component?
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <ShellContainer />
            </MuiThemeProvider>
          </StylesProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
