import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'ramda';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { noop } from '../../../../../utils/utils';
import {
  CAMPAIGN_TYPES,
  SUPPORTED_CAMPAIGN_TYPES,
} from '../../../constants/campaign-types';

export default class CampaignTypeDropDown extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOf(SUPPORTED_CAMPAIGN_TYPES),
    onChange: PropTypes.func,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    id: 'campaignTypeDropDown',
    label: 'Campaign Type',
    value: CAMPAIGN_TYPES.MESSAGE.key,
    onChange: noop,
    margin: 'none',
    fullWidth: false,
    required: false,
    disabled: false,
  };

  render() {
    return (
      <FormControl
        margin={this.props.margin}
        fullWidth={this.props.fullWidth}
        required={this.props.required}
        disabled={this.props.disabled}>
        <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
        <Select
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}>
          {values(CAMPAIGN_TYPES).map((campaignType) => (
            <MenuItem key={campaignType.key} value={campaignType.key}>
              {campaignType.label}
            </MenuItem>
          ))}
        </Select>
        {this.props.disabled ? <FormHelperText>Disabled</FormHelperText> : ''}
      </FormControl>
    );
  }
}
