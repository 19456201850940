import React, { useCallback, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import BlockIcon from '@material-ui/icons/Block';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import { Grid, TablePagination, Typography } from '@material-ui/core';
import UserRepository from '../../../../repository/user/UserRepository';
import HasPermission from '../../../../shared/components/User/HasPermission';
import CONSTANTS from '../../../../constants';
import ButtonWithConfirmation from '../../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import IdentifiersRepository from '../../../../repository/identifiers/IdentifiersRepository';
import styles from './UserIdentifiers.module.css';

export default function UserIdentifiers(props) {
  const [identifiers, setIdentifiers] = React.useState([]);
  const [init, setInit] = React.useState(true);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageContent, setPageContent] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);

  const loadUserIdentifiers = useCallback(() => {
    UserRepository.getUserIdentifiers(props.user.id)
      .then((data) => {
        setIdentifiers(data);
        setPageContent(data.slice(0, Math.min(6, data.length)));
        setTotalElements(data.length);
      })
      .catch((error) =>
        toastr.error('Could not load identifiers', error.message),
      )
      .finally(() => {
        setInit(false);
        scrollToTop();
      });
  }, [props.user.id]);

  const handleChangePage = (event, pageNumber) => {
    setPageNumber(pageNumber);
    const start = Math.min(identifiers.length, pageNumber * 6);
    const end = Math.min(identifiers.length, (pageNumber + 1) * 6);
    setPageContent(identifiers.slice(start, end));
    scrollToTop();
  };

  const blockCard = (identifier) => {
    return IdentifiersRepository.updateState(
      identifier.serialNumber,
      props.user.id,
      'SUSPENDED',
    )
      .then((data) => {
        toastr.success('Card blocked', data.successMessage);
        loadUserIdentifiers();
      })
      .catch((error) => toastr.error('Could not block card', error.message));
  };

  const unblockCard = (identifier) => {
    return IdentifiersRepository.updateState(
      identifier.serialNumber,
      props.user.id,
      'ACTIVE',
    )
      .then((data) => {
        toastr.success('Card unblocked', data.successMessage);
        loadUserIdentifiers();
      })
      .catch((error) => toastr.error('Could not unblock card', error.message));
  };

  const scrollToTop = () => {
    const titleElement = document.getElementById('IdentifierTab');
    titleElement.scrollIntoView();
  };

  useEffect(() => {
    if (init) {
      loadUserIdentifiers();
      setInit(false);
    }
  }, [init, loadUserIdentifiers]);

  const blockIdentifierButton = (identifier) => {
    return (
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
        <ButtonWithConfirmation
          buttonVariant="text"
          buttonText="Block card"
          onConfirm={blockCard}
          param={identifier}
          cancelButtonText="Cancel"
          icon={<BlockIcon />}
          confirmButtonText="Block"
          title="Block card"
          id="blockCard"
          message="Please confirm that this card will be blocked"
        />
      </HasPermission>
    );
  };

  const unblockIdentifierButton = (identifier) => {
    return (
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
        <ButtonWithConfirmation
          buttonVariant="text"
          buttonText="Unblock card"
          onConfirm={unblockCard}
          param={identifier}
          cancelButtonText="Cancel"
          icon={<RestoreFromTrashIcon />}
          confirmButtonText="Unblock"
          title="Unblock card"
          id="unblockCard"
          message="Please confirm that this card will be unblocked"
        />
      </HasPermission>
    );
  };

  const renderTable = () => {
    return identifiers && identifiers.length > 0 ? (
      <Paper id="IdentifierTab">
        <Grid container spacing={2} className={styles.root}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              {pageContent.map((identifier) => (
                <Grid key={identifier.code} item>
                  <Paper className={styles.paper}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <img
                          alt={identifier.code}
                          src={`https://qrcode.joyn.eu/?height=165&width=165&content=${identifier.code}`}
                        />
                      </Grid>
                      <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                          <Grid item xs>
                            <Typography className={styles.identifierData}>
                              {identifier.serialNumber}
                            </Typography>
                            <Typography className={styles.identifierData}>
                              Linked :{' '}
                              {moment(identifier.linkDate).format(
                                'DD/MM/YY HH:mm',
                              )}
                            </Typography>
                            <Typography className={styles.identifierData}>
                              Type : {identifier.type}
                            </Typography>
                            <Typography className={styles.identifierData}>
                              State : {identifier.state}
                            </Typography>
                          </Grid>
                          <Grid item>
                            {identifier.state === 'SUSPENDED'
                              ? unblockIdentifierButton(identifier)
                              : blockIdentifierButton(identifier)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <TablePagination
          count={totalElements}
          rowsPerPage={6}
          page={pageNumber}
          onChangePage={handleChangePage}
          rowsPerPageOptions={[]}
        />
      </Paper>
    ) : (
      <p>No identifiers found</p>
    );
  };

  return init ? <CircularProgress /> : renderTable();
}
