import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import CONSTANTS from '../../constants';
import { GuardedRoute } from '../../shared/components/User/GuardedRoute';
import CampaignDetailsContainer from './details/CampaignDetailsContainer';
import CampaignInitialisationContainer from './initialisation/CampaignInitialisationContainer';
import CampaignOverview from './overview/CampaignOverview';
import CampaignTargetContainer from './target/CampaignTargetContainer';

class Campaigns extends React.Component {
  render() {
    return (
      <Switch>
        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_READ_CAMPAIGNS]}
          exact
          path={CONSTANTS.ROUTES.CAMPAIGN_OVERVIEW.path}
          component={CampaignOverview}
        />

        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_CAMPAIGNS]}
          exact
          path={CONSTANTS.ROUTES.CAMPAIGN_INITIALISATION.path}
          component={CampaignInitialisationContainer}
        />
        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_CAMPAIGNS]}
          exact
          path={CONSTANTS.ROUTES.CAMPAIGN_DETAILS.path}
          component={CampaignDetailsContainer}
        />
        <GuardedRoute
          permissions={[CONSTANTS.PERMISSIONS.OP_WRITE_CAMPAIGNS]}
          exact
          path={CONSTANTS.ROUTES.CAMPAIGN_TARGET.path}
          component={CampaignTargetContainer}
        />
        <Redirect to={CONSTANTS.ROUTES.FOUR_OH_FOUR.path} />
      </Switch>
    );
  }
}

export default withRouter(Campaigns);
