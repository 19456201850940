import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToCampaignInitialisation } from '../../../../../store/navigation/campaigns/thunks';
import CreateCampaignButton from './CreateCampaignButton';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToCampaignInitialisation,
    },
    dispatch,
  );

class CreateCampaignButtonContainer extends React.Component {
  createNewMessageCampaign = () => {
    this.props.goToCampaignInitialisation();
  };

  render() {
    return <CreateCampaignButton onClick={this.createNewMessageCampaign} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCampaignButtonContainer);
