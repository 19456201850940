import AgeRange from './AgeRange';

export default class AgeRanges {
  ranges = [];

  constructor(ageRanges = []) {
    this.ranges = ageRanges.map((ageRange) => new AgeRange(ageRange));
  }

  add = (ageRange = {}) => {
    this.ranges = [...this.ranges, new AgeRange(ageRange)];
  };

  update = (ageRange = {}) => {
    const index = this.ranges.findIndex((range) => range.id === ageRange.id);

    if (index >= 0) {
      this.ranges[index] = new AgeRange(ageRange);
    }
  };

  remove = (ageRange = {}) => {
    this.ranges = this.ranges.filter((range) => range.id !== ageRange.id);
  };

  get raw() {
    return this.ranges.map((range) => range.raw);
  }
}
