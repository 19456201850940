import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { noop } from '../../../utils/utils';
import CancelAndContinue from '../components/CancelAndContinue/CancelAndContinue';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import CampaignNameInputContainer from '../components/CampaignNameInput/CampaignNameInputContainer';
import CampaignTypeDropDownContainer from './components/CampaignTypeDropDown/CampaignTypeDropDownContainer';
import styles from './CampaignInitialisation.module.css';

export default class CampaignInitialisation extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    isBusy: PropTypes.bool,
  };

  static defaultProps = {
    onSubmit: noop,
    isBusy: false,
  };

  safeSubmit = (event) => {
    event.preventDefault(); // This was added to prevent a full page reload in Safari. The default submit behaviour would add a question mark (?) to the end of the URL, triggering a full page reload
    return this.props.onSubmit();
  };

  render() {
    return (
      <div className={styles.campaignInitialisation}>
        <h1>Create Campaign</h1>

        {this.props.isBusy ? (
          <CircularProgress />
        ) : (
          <Paper className={styles.formWrapper}>
            <form
              id="initialisationForm"
              autoComplete="off"
              onSubmit={this.safeSubmit}>
              <CampaignTypeDropDownContainer />
              <CampaignNameInputContainer />

              <HorizontalSpacer size="large" />

              <CancelAndContinue />
            </form>
          </Paper>
        )}
      </div>
    );
  }
}
