import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { connectRouter } from 'connected-react-router';
import sessionName from './session/name';
import sessionReducer from './session/reducer';
import navigationName from './navigation/name';
import navigationReducer from './navigation/reducer';
import usersName from './users/name';
import usersReducer from './users/reducer';
import campaignsName from './campaigns/name';
import campaignsReducer from './campaigns/reducer';
import lifecycleName from './lifecycle/name';
import lifecycleReducer from './lifecycle/reducer';
import businessesName from './businesses/name';
import businessesReducer from './businesses/reducer';
import crmName from './crm/name';
import crmReducer from './crm/reducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    toastr: toastrReducer,
    [sessionName]: sessionReducer,
    [navigationName]: navigationReducer,
    [usersName]: usersReducer,
    [campaignsName]: campaignsReducer,
    [lifecycleName]: lifecycleReducer,
    [businessesName]: businessesReducer,
    [crmName]: crmReducer,
  });

export default createRootReducer;
