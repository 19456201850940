import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isCreatingCampaign } from '../../../store/campaigns/selectors';
import { createCampaignFromStore } from '../../../store/campaigns/thunks';
import { resetCampaign } from '../../../store/campaigns/actions';
import CampaignInitialisation from './CampaignInitialisation';

const mapStateToProps = (state) => ({
  isBusy: isCreatingCampaign(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetCampaign,
      createCampaignFromStore,
    },
    dispatch,
  );

class CampaignInitialisationContainer extends React.Component {
  componentWillUnmount() {
    this.props.resetCampaign();
  }

  render() {
    return (
      <CampaignInitialisation
        onSubmit={this.props.createCampaignFromStore}
        isBusy={this.props.isBusy}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignInitialisationContainer);
