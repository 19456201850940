import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import NoDataFound from '../../../shared/components/NoDataFound/NoDataFound';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import userRepository from '../../../repository/user/UserRepository';
import GoToUserDetailsButton from './components/GoToUserDetailsButton/GoToUserDetailsButton';
import styles from './UserOverview.module.css';
import UserSearch from './components/UserSearch/UserSearch';

export default class UserOverview extends React.Component {
  state = {
    users: [],
    isBusy: false,
    previousSearch: null,
  };

  componentDidMount() {
    const previousSearch = sessionStorage.getItem('user_search');
    if (previousSearch != null) {
      let json = JSON.parse(previousSearch);
      this.searchUsers(json.email, json.firstname, json.lastname);
      this.setState({
        ...this.state,
        previousSearch: json,
      });
    }
  }

  searchUsers = (email, firstname, lastname) => {
    this.setState({
      ...this.state,
      isBusy: true,
    });
    const params = {};
    if (email) {
      params['email'] = email;
    }
    if (lastname) {
      params['lastName'] = lastname;
    }
    if (firstname) {
      params['firstName'] = firstname;
    }
    userRepository.getUsers(params).then((data) => {
      this.setState({
        ...this.state,
        users: data.content,
        isBusy: false,
      });
    });
  };

  render() {
    const usersTable = (
      <Paper className={styles.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.users.map((user) => (
              <TableRow key={user.usrId} hover>
                <TableCell align="right">{user.usrId}</TableCell>
                <TableCell>{user.mail}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell align="right">
                  <GoToUserDetailsButton userId={user.usrId} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );

    return (
      <div className={styles.userOverview}>
        <h1>Users</h1>
        <UserSearch onSearch={this.searchUsers} />
        <HorizontalSpacer />

        {this.state.isBusy ? (
          <CircularProgress />
        ) : (
          <div>{this.state.users.length ? usersTable : <NoDataFound />}</div>
        )}
      </div>
    );
  }
}
