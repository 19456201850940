import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';

export default class PartnersMPDescription extends React.Component {
  languages = ['en', 'nl', 'fr', 'de'];
  defaultDescription = [
    {
      language: 'en',
      content: '',
    },
    {
      language: 'nl',
      content: '',
    },
    {
      language: 'fr',
      content: '',
    },
    {
      language: 'de',
      content: '',
    },
  ];
  state = {
    disabled: false,
    description: [],
  };

  constructor(props, context) {
    super(props, context);
    const { updateDescription, partner, disabled } = this.props;
    this.updateDescription = updateDescription;
    this.state.disabled = disabled;
    this.state.description = partner.description ?? this.defaultDescription;
  }

  findIndexByLanguage = (language) => {
    return this.state.description.findIndex(
      (description) => description.language === language,
    );
  };

  updateState = (language, content) => {
    let newState = {
      ...this.state,
      description: [
        ...this.state.description.filter(
          (description) => description.language !== language,
        ),
        {
          language,
          content,
        },
      ],
    };

    this.setState(newState);

    this.updateDescription(newState.description);
  };

  handleTextFieldChange = (language) => (event) => {
    this.updateState(language, event.target.value);
  };

  renderField = (language, key) => {
    let languageIndex = this.findIndexByLanguage(language);
    let label = `Merchant Portal description (${language.toUpperCase()})`;
    return (
      <TextField
        disabled={this.state.disabled}
        key={key}
        label={label}
        type="text"
        value={this.state.description[languageIndex]?.content ?? ''}
        onChange={this.handleTextFieldChange(language)}
        margin="normal"
        fullWidth
        required={this.props.required ?? false}
      />
    );
  };

  render() {
    return (
      <>
        {this.languages.map((language, key) => this.renderField(language, key))}
      </>
    );
  }
}
