import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/TextField';
import debounce from 'lodash.debounce';
import { noop } from '../../../../../../../../../utils/utils';
import AgeRange from '../../classes/AgeRange';
import AGE_CONSTANTS from '../../AgeRangeConstants';
import CONSTANTS from '../../../../../../../../../constants';
import styles from './AgeRangeInput.module.css';

class AgeRangeInput extends React.Component {
  static propTypes = {
    ageRange: PropTypes.instanceOf(AgeRange),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    ageRange: new AgeRange(),
    onChange: noop,
  };

  state = {
    from: this.props.ageRange.from,
    to: this.props.ageRange.to,
  };

  onAgeRangeChangeCallback = () =>
    this.props.onChange({ from: this.state.from, to: this.state.to });

  setStateValidateAndCallback = (state) => {
    this.setState(state, this.debouncedValidateAndCallback);
  };

  setFromInputRef = (ref) => {
    this.fromInputRef = ref;
  };

  handleChange = (prop) => (event) => {
    this.setStateValidateAndCallback({ [prop]: event.target.value });
  };

  validateRangeAndCallback = () => {
    const fromInput = this.fromInputRef;

    if (fromInput) {
      const fromValue = parseInt(this.state.from, 10);
      const toValue = parseInt(this.state.to, 10);
      const rangeIsValid = fromValue <= toValue;

      if (rangeIsValid) {
        fromInput.setCustomValidity('');
        this.debouncedOnChangeCallback();
      } else {
        fromInput.setCustomValidity('Please enter a valid age range');
        fromInput.reportValidity();
      }
    }
  };

  debouncedOnChangeCallback = debounce(
    this.onAgeRangeChangeCallback,
    CONSTANTS.DEBOUNCE.NORMAL,
  );

  /* TODO: debouncedValidateAndCallback causes the validation to be done after you are done typing (300ms).
        However, if you type slowly, you'll still get the error, which focuses the from-input.
        The validation of this component should probably move away from html5 validation, and use an error message in the html
   */
  debouncedValidateAndCallback = debounce(
    this.validateRangeAndCallback,
    CONSTANTS.DEBOUNCE.NORMAL,
  );

  render() {
    return (
      <div className={styles.ageRange}>
        <TextField
          id="ageRangeFromInput"
          label="From"
          value={this.state.from}
          onChange={this.handleChange('from')}
          className={styles.input}
          type="number"
          inputProps={{
            min: AGE_CONSTANTS.MIN,
            max: AGE_CONSTANTS.MAX,
            step: '1',
            ref: this.setFromInputRef,
          }}
          margin="normal"
          required
        />
        <span className={styles.dash}>&mdash;</span>
        <TextField
          id="ageRangeToInput"
          label="To"
          value={this.state.to}
          onChange={this.handleChange('to')}
          className={styles.input}
          type="number"
          inputProps={{
            min: AGE_CONSTANTS.MIN,
            max: AGE_CONSTANTS.MAX,
            step: '1',
          }}
          margin="normal"
          required
        />
      </div>
    );
  }
}

export default AgeRangeInput;
