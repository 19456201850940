export const noop = () => undefined;
export const delay = (result, ms) =>
  new Promise((resolve) => setTimeout(() => resolve(result), ms));

export const getError = (error) => {
  let message = '';

  if (error.response) {
    if (error.response.data) {
      if (error.response.data.error) {
        if (error.response.data.error.description) {
          return error.response.data.error.description;
        } else {
          return error.message;
        }
      } else {
        if (error.response.data.errorMessage) {
          message = error.response.data.errorMessage;
          if (error.response.data.fieldErrors) {
            error.response.data.fieldErrors.forEach((e) => {
              message += ` ${e.field}: ${e.message}`;
            });
          }
        } else {
          message = error.message;
        }
      }
    } else {
      message = error.response;
    }
  } else {
    message = error.message;
  }

  return message;
};

export default getError;
