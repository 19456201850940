import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import { Autocomplete } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import styles from './PartnersMPIntegration.module.css';
import PartnersMPDescription from './PartnersMPDescription';
import PartnerApiKeyPermissions from './PartnerApiKeyPermissions';

export default class PartnersMPIntegration extends React.Component {
  state = {
    disabled: false,
    integration: {},
  };

  constructor(props, context) {
    super(props, context);
    const { updateIntegration, integration, disabled } = this.props;
    this.updateIntegration = updateIntegration;
    this.state.disabled = disabled;
    this.state.integration = {
      ...integration,
      type: integration.type ?? this.getPropsTypeDefaultValue(),
    };
  }

  updateProp = (prop, value) => {
    let newState = {
      ...this.state,
      integration: {
        ...this.state.integration,
        [prop]: value,
      },
    };

    if (newState.integration?.type === '') {
      newState.integration.type = null;
    }

    this.setState(newState);
    this.updateIntegration(this.props.index, newState.integration);
  };

  handleTextFieldChange = (prop) => (event) => {
    this.updateProp(prop, event.target.value);
  };

  handleCheckboxChange = (prop) => (event) => {
    this.updateProp(prop, event.target.checked);
  };

  categoryInputChanged = (event, value) => {
    let type = value === '' ? null : value;
    this.updateProp('type', type);
  };

  updateDescription = (description) => {
    this.updateProp('description', description);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.required === this.props.required) return;
    let defaultValue = this.getPropsTypeDefaultValue();
    this.updateProp('type', defaultValue);
  }

  getDefaultTypeValue = () => {
    let required = this.props.required;
    let currentType = this.state.integration.type;
    return this.getType(required, currentType);
  };

  getPropsTypeDefaultValue = () => {
    let required = this.props.required;
    let currentType = this.props.integration.type;
    return this.getType(required, currentType);
  };

  getType = (required, currentType) => {
    if (required) {
      return !currentType || currentType === '' ? 'POS' : currentType;
    } else {
      return currentType ?? '';
    }
  };

  renderTypes = () => {
    let required = this.props.required;
    let options = ['', 'POS', 'ECOMMERCE'];
    let displayLabel = `Category ${required ? '*' : ''}`;
    let defaultValue = this.getDefaultTypeValue();
    return (
      <Autocomplete
        disabled={this.state.disabled}
        type="text"
        label="category"
        id="categoryInput"
        value={this.state.integration.type ?? defaultValue}
        options={options}
        filterOptions={(options, __) => options.filter((opt) => opt !== '')}
        style={{ width: '100%' }}
        onChange={this.categoryInputChanged}
        disableClearable={required}
        renderInput={(params) => <TextField {...params} label={displayLabel} />}
        required={required}
      />
    );
  };

  getPermissionsAsArray = () => {
    return this.state.integration.permissions?.split(',') ?? [];
  };

  handlePermissionsChange = (permissions) => {
    this.updateProp('permissions', permissions.filter((p) => p).join(','));
  };

  render() {
    return (
      <div
        style={{
          marginTop: '.8rem',
          marginBottom: '.8rem',
          textAlign: 'left',
        }}>
        <div
          style={{
            marginTop: '1rem',
            marginBottom: '.8rem',
            textAlign: 'left',
          }}>
          <FormControlLabel
            disabled={this.state.disabled}
            className={styles.checkBoxContainer}
            control={<Checkbox checked={this.props.required || false} />}
            value={this.props.required || false}
            onChange={this.handleCheckboxChange('showInMerchantPortal')}
            label="In merchant portal"
          />
        </div>
        <div hidden={!this.props.required}>
          <div style={{ marginTop: '.8rem', width: '100%' }}>
            <PartnerApiKeyPermissions
              required={this.props.required}
              initialSelectedPermissions={this.getPermissionsAsArray()}
              onPermissionsChange={this.handlePermissionsChange}
            />
            <TextField
              disabled={this.state.disabled}
              label="Logo Link"
              type="text"
              value={this.state.integration.logoLink ?? ''}
              onChange={this.handleTextFieldChange('logoLink')}
              margin="normal"
              fullWidth
              required={this.props.required}
            />
            <TextField
              disabled={this.state.disabled}
              label="More Info Link"
              type="text"
              value={this.state.integration.moreInfoLink ?? ''}
              onChange={this.handleTextFieldChange('moreInfoLink')}
              margin="normal"
              fullWidth
              required={this.props.required}
            />
            {this.renderTypes()}
            <PartnersMPDescription
              disabled={this.state.disabled}
              updateDescription={this.updateDescription}
              partner={this.state.integration}
              required={this.props.required}
            />
          </div>
        </div>
      </div>
    );
  }
}
