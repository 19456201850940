import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField/TextField';
import { TableBody, TextareaAutosize, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import styles from '../PointsCoupons.module.css';
import SelectCouponButton from './SelectCouponButton';

export default function PointsAndCouponSelection(props) {
  const [points, setPoints] = React.useState(
    props.award.type === 'points' ? props.award.value : '',
  );
  const [content, setContent] = React.useState(props.award.content);
  const [subject, setSubject] = React.useState(props.award.subject);
  const [sendCouponMail, setSendCouponMail] = React.useState(
    props.award.sendCouponMail,
  );

  const subjectChanged = (event) => {
    setSubject(event.target.value);
  };

  const contentChanged = (event) => {
    setContent(event.target.value);
  };

  const pointsChanged = (event) => {
    setPoints(event.target.value);
  };

  const handleSelectPoints = () =>
    props.selectAward({
      description: `Points : ${points}`,
      type: 'points',
      value: points,
    });

  const handleSelectCoupon = (coupon) => {
    props.selectAward({
      description: 'Coupon : ' + coupon.name,
      type: 'coupon',
      value: coupon.id,
      content: content,
      subject: subject,
      sendCouponMail: sendCouponMail,
    });
  };

  const handleCheckboxChange = (event) => {
    setSendCouponMail(event.target.checked);
  };

  const couponsPresent = () => {
    return props.shop && props.shop.coupons && props.shop.coupons.length > 0;
  };

  const checkboxRow = (
    <TableRow key="mailContent">
      <TableCell>
        <Checkbox onChange={handleCheckboxChange} checked={sendCouponMail} />
        Send coupon mail
      </TableCell>
      <TableCell />
    </TableRow>
  );

  const mailContentRows = (
    <>
      <TableRow key="mailContent1">
        <TableCell colSpan={2}>
          <div>Subject</div>
          <div>
            <TextField
              type="text"
              placeholder={'subject'}
              onChange={subjectChanged}
              value={subject}
              id="subject"
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow key="mailContent2">
        <TableCell colSpan={2}>
          Content
          <TextareaAutosize
            placeholder={'content'}
            cols={80}
            rows={20}
            onChange={contentChanged}
            defaultValue={content}
            id="content"
          />
        </TableCell>
      </TableRow>
    </>
  );

  return (
    <Paper className={styles.tableWrapper} elevation={3}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <TextField
                type="number"
                value={points}
                onChange={pointsChanged}
                id="newPoints"
              />{' '}
              points
            </TableCell>
            <TableCell align="right">
              <Button
                id="selectPointsButton"
                color="primary"
                disabled={points === null || points < 1}
                onClick={handleSelectPoints}>
                Select Points
              </Button>
            </TableCell>
          </TableRow>

          {props.shop && props.shop.coupons ? (
            props.shop.coupons.map((coupon) => (
              <TableRow key={coupon.id} hover>
                <TableCell>
                  <Tooltip title={coupon.id} placement="top">
                    <div>Coupon : {coupon.name}</div>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <SelectCouponButton
                    coupon={coupon}
                    onClick={handleSelectCoupon}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={'noCoupons'} hover>
              <TableCell>No Coupons</TableCell>
              <TableCell />
            </TableRow>
          )}
          {couponsPresent() ? checkboxRow : ''}
          {couponsPresent() && sendCouponMail ? mailContentRows : ''}
        </TableBody>
      </Table>
    </Paper>
  );
}
