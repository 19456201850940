import initialState from './state';
import { GET_USER, GET_USER_PARTNER_BALANCES } from './action-types';

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_USER}_SUCCESS`:
      return {
        ...state,
        user: action.payload,
      };
    case `${GET_USER_PARTNER_BALANCES}_SUCCESS`:
      return {
        ...state,
        user: {
          ...state.user,
          integrations: {
            ...state.user.integrations,
            partnerBalances: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

export default reducer;
