import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { goToCampaignDetails } from '../../../../../store/navigation/campaigns/thunks';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToCampaignDetails,
    },
    dispatch,
  );

class EditCampaignButton extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string,
  };

  static defaultProps = {
    campaignId: '',
  };

  handleOnClick = () => this.props.goToCampaignDetails(this.props.campaignId);

  render() {
    return (
      <Button
        id="editCampaignButton"
        color="secondary"
        onClick={this.handleOnClick}>
        Edit
      </Button>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaignButton);
