import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/TextField';
import AddIcon from '@material-ui/icons/Add';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import HasPermission from '../../../../shared/components/User/HasPermission';
import CONSTANTS from '../../../../constants';
import ButtonWithConfirmation from '../../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import UserRepository from '../../../../repository/user/UserRepository';
import getError from '../../../../utils/utils';
import IconButtonWithConfirmation from '../../../../shared/components/ButtonWithConfirmation/IconButtonWithConfirmation';
import ShopRepository from '../../../../repository/shop/ShopRepository';
import styles from './VisitedShops.module.css';

export default class VisitedShopsView extends React.Component {
  state = {
    points: PropTypes.number,
    isBusy: true,
    visitedShops: this.props.user.shops,
  };

  static propTypes = {
    user: PropTypes.object,
  };

  setNewPoints = (value) => {
    this.setState({
      ...this.state,
      points: value,
    });
  };

  setIsBusy = (busy) => {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  };

  changePoints = (visitedShop) => {
    this.setIsBusy(true);
    return UserRepository.changePoints(
      this.props.user.partyId,
      visitedShop.shopId,
      this.state.points,
    )
      .then(() => {
        toastr.success('Success', `Points changed`);
        this.setNewPoints('');
        return UserRepository.getUser(this.props.user.id).then((data) => {
          this.setState({
            ...this.state,
            visitedShops: data.shops,
          });
        });
      })
      .catch((error) => {
        toastr.error('Could not change points', getError(error));
        throw new Error('Could not change points');
      })
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  deleteCustomer = (params) => {
    this.setIsBusy(true);
    return ShopRepository.deleteCustomer(params.shopId, params.userId)
      .then(() => {
        toastr.success('Success', `Customer deleted`);
        return UserRepository.getUser(params.userId).then((data) => {
          this.setState({
            ...this.state,
            visitedShops: data.shops,
          });
        });
      })
      .catch((error) => {
        toastr.error('Could not delete customer', getError(error));
      })
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  newPointsChanged = (event) => {
    this.setNewPoints(event.target.value);
  };

  render() {
    return (
      <div>
        {this.props.isBusy ? (
          <CircularProgress />
        ) : this.state.visitedShops && this.state.visitedShops.length > 0 ? (
          <div>{this.renderTable(this.state.visitedShops)}</div>
        ) : (
          <p>No visited shops found.</p>
        )}
      </div>
    );
  }

  renderTable(visitedShops) {
    return (
      <Paper>
        <HorizontalSpacer size="small" />
        <Table id="visitedShopsTable">
          <TableHead>
            <TableRow>
              <TableCell>Shop Id</TableCell>
              <TableCell>Shop name</TableCell>
              <TableCell>Points</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {visitedShops.map((visitedShop) => (
              <TableRow key={visitedShop.shopId} hover>
                <TableCell>{visitedShop.shopId}</TableCell>
                <TableCell>{visitedShop.shopName}</TableCell>
                <TableCell>{visitedShop.points}</TableCell>
                <TableCell className={styles.actionColumn}>
                  <HasPermission
                    permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
                    <div className={styles.action}>
                      {this.changePointsButton(visitedShop)}
                    </div>
                  </HasPermission>
                </TableCell>
                <TableCell className={styles.actionColumn}>
                  <HasPermission
                    permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
                    <div className={styles.action}>
                      {this.deleteButton(this.props.user, visitedShop)}
                    </div>
                  </HasPermission>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  changePointsButton(visitedShop) {
    return (
      <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
        <ButtonWithConfirmation
          buttonVariant="text"
          buttonText="Change points"
          onConfirm={this.changePoints}
          param={visitedShop}
          cancelButtonText="Cancel"
          icon={<AddIcon />}
          confirmButtonText="Change"
          title="Change points"
          id="ChangePoints"
          message="Enter points to change:">
          <TextField
            className={styles.newPointsInput}
            type="number"
            value={this.state.points}
            onChange={this.newPointsChanged}
            id="newPoints"
          />
        </ButtonWithConfirmation>
      </HasPermission>
    );
  }

  deleteButton(user, visitedShop) {
    return (
      <IconButtonWithConfirmation
        onConfirm={this.deleteCustomer}
        param={{ userId: user.id, shopId: visitedShop.shopId }}
        message="Are you sure that you want to delete this customer record?"
        title="Delete"
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        id={'deleteCustomer'}
        icon={<DeleteIcon />}>
        <div>
          <HorizontalSpacer size={'small'} />
          <Alert severity={'warning'}>
            This cannot be undone. Points, coupons AND ALL OTHER DATA of this
            customer record will be lost.
          </Alert>
        </div>
      </IconButtonWithConfirmation>
    );
  }
}
