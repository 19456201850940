import React from 'react';
import AppBarHeaderContainer from './AppBarHeader/AppBarHeaderContainer';
import SideBarContainer from './SideBar/SideBarContainer';

export default class Navigation extends React.Component {
  render() {
    return (
      <div>
        <AppBarHeaderContainer />
        <SideBarContainer />
      </div>
    );
  }
}
