import React from 'react';
import CONSTANTS from '../../constants';
import styles from './VersionInfo.module.css';

class VersionInfo extends React.Component {
  render() {
    return (
      <span className={styles.versionInfo}>
        v{CONSTANTS.VERSION_INFO.APP_VERSION}
        &nbsp; ({CONSTANTS.VERSION_INFO.BUILD_NUMBER})
      </span>
    );
  }
}

export default VersionInfo;
