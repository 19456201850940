import React from 'react';
import { toastr } from 'react-redux-toastr';
import RegistrationRequestRepository from '../../repository/registrationRequests/RegistrationRequestRepository';
import RrOverviewView from './view/RegistrationRequestOverviewView';

export default class RegistrationRequestOverview extends React.Component {
  state = {
    registrationRequests: [],
    isBusy: false,
    registrationRequestState: 'PENDING',
    searchTerm: '',
  };

  searchRegistrationRequests = (searchTerm) => {
    if (searchTerm.length < 3) {
      toastr.warning('Type at least 3 characters');
      return;
    }
    this.setState({
      ...this.state,
      isBusy: true,
      searchTerm: searchTerm,
    });

    RegistrationRequestRepository.search(
      searchTerm,
      this.state.registrationRequestState,
    )
      .then((data) => {
        this.setState({
          ...this.state,
          registrationRequests: data,
        });
      })
      .catch((error) =>
        toastr.error('Could not load registration requests', error.message),
      )
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  registrationRequestStateChanged = (event) => {
    this.setState(
      {
        ...this.state,
        registrationRequestState: event.target.value,
      },
      () => this.searchRegistrationRequests(this.state.searchTerm),
    );
  };

  deleteRegistrationRequest = (id) => {
    this.setIsBusy(true);

    RegistrationRequestRepository.delete(id)
      .then(() => {
        this.searchRegistrationRequests(this.state.searchTerm);
      })
      .catch((error) =>
        toastr.error('Could not delete registration request', error.message),
      )
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  setIsBusy(busy) {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  }

  render() {
    return (
      <RrOverviewView
        defaultSelectedState={this.state.registrationRequestState}
        registrationRequests={this.state.registrationRequests}
        onSearch={this.searchRegistrationRequests}
        onDelete={this.deleteRegistrationRequest}
        onRegistrationRequestStateChanged={this.registrationRequestStateChanged}
        isBusy={this.state.isBusy}
      />
    );
  }
}
