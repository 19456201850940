import React, { useEffect, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import { Button, Radio } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { AddCircle } from '@material-ui/icons';
import HorizontalSpacer from '../../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import UserRepository from '../../../../repository/user/UserRepository';
import HasPermission from '../../../../shared/components/User/HasPermission';
import CONSTANTS from '../../../../constants';
import IconButtonWithConfirmation from '../../../../shared/components/ButtonWithConfirmation/IconButtonWithConfirmation';
import styles from './Coupons.module.css';

export default function CouponsView(props) {
  const [coupons, setCoupons] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalElements, setTotalElements] = React.useState(0);
  const [isBusy, setIsBusy] = React.useState(false);
  const [active, setActive] = React.useState(null);
  const [init, setInit] = React.useState(true);
  const [userCouponCount, setUserCouponCount] = React.useState(0);

  const loadUserCoupons = useCallback(
    (page, params) => {
      setIsBusy(true);
      UserRepository.getUserCoupons(props.user.id, {
        ...params,
        page: page,
        size: rowsPerPage,
      })
        .then((data) => {
          setCoupons(data.content);
          setPage(data.number);
          setRowsPerPage(data.size);
          setTotalElements(data.totalElements);
          setUserCouponCount(Math.max(userCouponCount, data.content.length));
        })
        .catch((error) => toastr.error('Could not load coupons', error.message))
        .finally(() => {
          setIsBusy(false);
          scrollToTop();
        });
    },
    [props.user.id, rowsPerPage, userCouponCount],
  );

  const handleChangePage = (event, page) => {
    loadUserCoupons(page, {});
  };

  const loadAll = () => {
    loadByStatus(null);
  };

  const loadActive = () => {
    loadByStatus('ACTIVE');
  };

  const loadExpired = () => {
    loadByStatus('EXPIRED');
  };

  const loadRedeemed = () => {
    loadByStatus('REDEEMED');
  };

  const loadByStatus = (status) => {
    setActive(status);
    loadUserCoupons(0, { status: status });
  };

  const scrollToTop = () => {
    const titleElement = document.getElementById('CouponTab');
    titleElement.scrollIntoView();
  };

  const hasCoupons = () => {
    return userCouponCount > 0;
  };

  const activateCoupon = (serialNumber) => {
    setIsBusy(true);
    return UserRepository.activateUserCoupon(props.user.id, serialNumber)
      .then((data) => {
        loadByStatus(active);
      })
      .catch((error) =>
        toastr.error('Could not activate coupon', error.message),
      );
  };

  useEffect(() => {
    if (init) {
      loadUserCoupons(0, {});
      setInit(false);
    }
  }, [init, loadUserCoupons]);

  const renderTable = () => {
    return hasCoupons() ? (
      <Paper id="CouponTab">
        <HorizontalSpacer size="small" />
        <div>
          <Button
            className={styles.button}
            variant="contained"
            color={active == null ? 'primary' : 'secondary'}
            onClick={loadAll}>
            ALL
          </Button>
          <Button
            className={styles.button}
            variant="contained"
            color={active === 'ACTIVE' ? 'primary' : 'secondary'}
            onClick={loadActive}>
            ACTIVE
          </Button>
          <Button
            className={styles.button}
            variant="contained"
            color={active === 'REDEEMED' ? 'primary' : 'secondary'}
            onClick={loadRedeemed}>
            REDEEMED
          </Button>
          <Button
            className={styles.button}
            variant="contained"
            color={active === 'EXPIRED' ? 'primary' : 'secondary'}
            onClick={loadExpired}>
            EXPIRED
          </Button>
        </div>
        <Table id="couponsTable">
          <TableHead>
            <TableRow>
              <TableCell>Coupon</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Activated on</TableCell>
              <TableCell>Redeemed on</TableCell>
              <TableCell>Expires on</TableCell>
              <TableCell>Expired</TableCell>
              <TableCell>Auto-activated</TableCell>
              <TableCell>Deleted</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isBusy ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              coupons.map((coupon) => (
                <TableRow key={coupon.couponSerialNumber} hover>
                  <TableCell>
                    {coupon.description} <br />
                    <span>
                      {coupon.shopNames.length > 1 ? (
                        <Tooltip
                          title={coupon.shopNames.join(', ')}
                          placement="bottom-start"
                          classes={{ tooltip: styles.tooltip }}>
                          <div>{coupon.shopNames[0]}, ...</div>
                        </Tooltip>
                      ) : (
                        coupon.shopNames[0]
                      )}
                    </span>
                  </TableCell>
                  <TableCell>{coupon.status}</TableCell>
                  <TableCell>
                    {coupon.activatedOn
                      ? moment(coupon.activatedOn).format('DD/MM/YY HH:mm')
                      : ''}
                  </TableCell>
                  <TableCell>
                    {coupon.redeemedOn
                      ? moment(coupon.redeemedOn).format('DD/MM/YY HH:mm')
                      : ''}
                  </TableCell>
                  <TableCell>
                    {moment(coupon.expirationDate).format('DD/MM/YY')}
                  </TableCell>
                  <TableCell>
                    <Radio checked={coupon.expired} />
                  </TableCell>
                  <TableCell>
                    <Radio checked={coupon.autoActivated} />
                  </TableCell>
                  <TableCell>
                    <Radio checked={coupon.deleted} />
                  </TableCell>
                  <TableCell>
                    {coupon.validToActivate ? (
                      <HasPermission
                        permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
                        <IconButtonWithConfirmation
                          onConfirm={activateCoupon}
                          param={coupon.couponSerialNumber}
                          message="Are you sure that you want to activate this coupon?"
                          title="Activate coupon"
                          confirmButtonText="Activate"
                          cancelButtonText="Cancel"
                          id={'activateCoupon'}
                          icon={
                            <Tooltip title={'Activate coupon'}>
                              <AddCircle />
                            </Tooltip>
                          }>
                          {coupon.description}
                        </IconButtonWithConfirmation>
                      </HasPermission>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    ) : (
      <Paper id="CouponTab">
        <p>No coupons found.</p>
      </Paper>
    );
  };

  return init ? <CircularProgress /> : renderTable();
}
