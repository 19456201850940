import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../../../../utils/utils';
import ConfirmationDialog from '../../../../../shared/components/ConfirmationDialog/ConfirmationDialog';

export default class PublishCampaignConfirmationDialog extends React.Component {
  static propTypes = {
    isDialogOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    isDialogOpen: false,
    onConfirm: noop,
    onClose: noop,
    onCancel: noop,
  };

  render() {
    return (
      <ConfirmationDialog
        isOpen={this.props.isDialogOpen}
        onConfirm={this.props.onConfirm}
        onClose={this.props.onClose}
        onCancel={this.props.onCancel}
        dialogTitle="Prepare for launch"
        dialogContent="Are you ready to publish the campaign?"
        confirmButtonText="Publish now"
        cancelButtonText="Nope"
      />
    );
  }
}
