import { JoynCrmApi } from '../api/joyn/JoynApi';

export default new (class CRMRepository {
  getUnResolvedActions = (type, sort, order, page) => {
    const url = `/actions/unresolved?type=${type}&size=10&page=${page}&sort=${sort},${order}`;

    return JoynCrmApi.get(url);
  };

  resolveAction = (actionId) => {
    const url = `/actions/${actionId}`;
    const params = { actionId };

    return JoynCrmApi.put(url, params);
  };

  deleteAction = (actionId) => {
    const url = `/actions/${actionId}`;
    const params = { actionId };

    return JoynCrmApi.delete(url, params);
  };

  searchMerchants = (name) => {
    const url = `/odoo/merchants?query=` + name;
    return JoynCrmApi.get(url);
  };

  searchShops = (name) => {
    const url = `/odoo/shops?query=` + name;

    return JoynCrmApi.get(url);
  };

  geoCode = (params) => {
    const url = `/odoo/geocode`;

    return JoynCrmApi.post(url, params);
  };

  readMerchantSubscriptionData = (merchantId) => {
    const url = `odoo/merchants/${merchantId}/subscriptions`;

    return JoynCrmApi.get(url);
  };

  readShopSubscriptionData = (shopId) => {
    const url = `odoo/shops/${shopId}/subscriptions`;

    return JoynCrmApi.get(url);
  };
})();
