import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getActivePaymentTypes,
  isFetchingPartnerBalances,
} from '../../../../../../store/users/selectors';
import { loadUserPartnerBalancesById } from '../../../../../../store/users/thunks';
import UserIntegration from './UserIntegration';

const mapStateToProps = (state) => ({
  activePaymentTypes: getActivePaymentTypes(state),
  isFetching: isFetchingPartnerBalances(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadUserPartnerBalancesById,
    },
    dispatch,
  );

class UserIntegrationContainer extends React.Component {
  static propTypes = {
    userId: PropTypes.number,
  };

  static defaultProps = {
    userId: 0,
  };

  componentDidMount() {
    if (this.props.userId) {
      this.props.loadUserPartnerBalancesById(this.props.userId);
    }
  }

  render() {
    return (
      <UserIntegration
        activePaymentTypes={this.props.activePaymentTypes}
        isFetching={this.props.isFetching}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserIntegrationContainer);
