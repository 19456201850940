import React from 'react';
import { toastr } from 'react-redux-toastr';
import { path } from 'ramda';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import HorizontalSpacer from '../../../shared/components/HorizontalSpacer/HorizontalSpacer';
import UserRepository from '../../../repository/user/UserRepository';
import styles from './UserDetails.module.css';
import UserGeneralCard from './UserGeneralCard';
import UserIntegrations from './UserIntegrations/UserIntegrations';
import UserConsent from './UserConsent/UserConsent';
import VisitedShops from './VisitedShops/VisitedShops';
import Activity from './Activity/Activity';
import Coupons from './Coupons/Coupons';
import UserIdentifiers from './Identifiers/UserIdentifiers';
import TabPanel from './TabPanel';
import UserCampaigns from './Campaigns/UserCampaigns';
import RewardPromotionCodes from './PromotionCodes/RewardPromotionCodes';

export default class UserDetails extends React.Component {
  state = {
    user: {},
    isBusy: true,
    tab: 0,
  };

  componentDidMount() {
    this.setIsBusy(true);
    const userIdFromUrl = path(['match', 'params', 'userId'], this.props);
    const userIdAsNumber = parseInt(userIdFromUrl, 10);

    UserRepository.getUser(userIdAsNumber)
      .then((data) => {
        this.setUser(data);
      })
      .catch((error) => toastr.error('Could not load user', error.message))
      .finally(() => {
        this.setIsBusy(false);
      });
  }

  reloadUser = () => {
    this.setIsBusy(true);

    UserRepository.getUser(this.state.user.id)
      .then((data) => {
        this.setUser(data);
      })
      .catch((error) => toastr.error('Could not reload user', error.message))
      .finally(() => {
        this.setIsBusy(false);
      });
  };

  setUser = (user) => {
    this.setState({
      ...this.state,
      user: user,
    });
  };

  handleChange = (event, newValue) => {
    this.setState({
      ...this.state,
      tab: newValue,
    });
  };

  setIsBusy = (busy) => {
    this.setState({
      ...this.state,
      isBusy: busy,
    });
  };

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  render() {
    return (
      <div className={styles.userDetails}>
        <div className={styles.userDetailsContent}>
          <HorizontalSpacer size="small" />
          {this.state.isBusy ? (
            <CircularProgress />
          ) : (
            <div id="userDetail">
              <UserGeneralCard
                user={this.state.user}
                reloadUser={this.reloadUser}
              />
              <HorizontalSpacer />
              <HorizontalSpacer />
              <AppBar position="static">
                <Tabs
                  value={this.state.tab}
                  onChange={this.handleChange}
                  aria-label="User detail tabs">
                  <Tab label="Visited Shops" {...this.a11yProps(0)} />
                  <Tab label="Coupons" {...this.a11yProps(1)} />
                  <Tab label="Activity" {...this.a11yProps(2)} />
                  <Tab label="Identifiers" {...this.a11yProps(3)} />
                  <Tab label="Integrations" {...this.a11yProps(4)} />
                  <Tab label="Consent" {...this.a11yProps(5)} />
                  <Tab label="Campaigns" {...this.a11yProps(6)} />
                  <Tab label="Reward Promotion Codes" {...this.a11yProps(7)} />
                </Tabs>
              </AppBar>
              <TabPanel value={this.state.tab} index={0}>
                <VisitedShops user={this.state.user} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={1}>
                <Coupons user={this.state.user} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={2}>
                <Activity user={this.state.user} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={3}>
                <UserIdentifiers user={this.state.user} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={4}>
                <UserIntegrations userId={this.state.user.id} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={5}>
                <UserConsent userId={this.state.user.id} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={6}>
                <UserCampaigns user={this.state.user} />
              </TabPanel>
              <TabPanel value={this.state.tab} index={7}>
                <RewardPromotionCodes user={this.state.user} />
              </TabPanel>
            </div>
          )}
        </div>
      </div>
    );
  }
}
