import React from 'react';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import CONSTANTS from '../../../constants';
import HasPermission from '../../../shared/components/User/HasPermission';
import ButtonWithConfirmation from '../../../shared/components/ButtonWithConfirmation/ButtonWithConfirmation';
import UserRepository from '../../../repository/user/UserRepository';

export default function MergeConfirmation(props) {
  const [busy, setBusy] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);

  const handleConfirmation = () => {
    setBusy(true);
    UserRepository.merge({
      source: props.selection1.id,
      sourceType: props.selection1.type,
      target: props.selection2.id,
    })
      .then((data) => {
        setConfirmed(true);
        props.onConfirm();
        toastr.success('Done', data.successMessage);
      })
      .catch((error) => toastr.error('Could not perform action', error.message))
      .finally(() => {
        setBusy(false);
      });
  };

  const confirmationButton =
    props.selection1.id === props.selection2.id ? (
      <div>Cannot merge a user to itself.</div>
    ) : (
      <div>
        <HasPermission permissions={[CONSTANTS.PERMISSIONS.OP_UPDATE_USERS]}>
          <ButtonWithConfirmation
            onConfirm={handleConfirmation}
            title="Confirm the selection"
            message=""
            confirmButtonText="Confirm"
            buttonText="Confirm"
            cancelButtonText="Cancel"
            buttonVariant="contained"
            id={'confirmMerge'}>
            <div>From: {props.selection1.email}</div>
            <div>To: {props.selection2.email}</div>
          </ButtonWithConfirmation>
        </HasPermission>
      </div>
    );
  if (busy) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  if (confirmed) {
    return <div>Action confirmed</div>;
  }
  return confirmationButton;
}
