import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { noop } from '../../../../../utils/utils';
import GenderSelect from '../../../../../shared/components/GenderSelect/GenderSelect';

class CampaignTargetGenderSelect extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: '',
    onChange: noop,
  };

  onSelectGender = (selectEvent) => {
    this.props.onChange({ gender: selectEvent.target.value });
  };

  render() {
    return (
      <div>
        <Typography variant="h6">Restrict to gender</Typography>
        <GenderSelect
          onSelect={this.onSelectGender}
          value={this.props.value}
          margin="normal"
        />
      </div>
    );
  }
}

export default CampaignTargetGenderSelect;
