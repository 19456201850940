import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { noop } from '../../../utils/utils';
import NavList from './NavList/NavList';

class SideBar extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
    onClose: noop,
  };

  render() {
    return (
      <Drawer open={this.props.isOpen} onClose={this.props.onClose}>
        <div
          tabIndex={0}
          role="button"
          onClick={this.props.onClose}
          onKeyDown={noop}>
          <NavList />
        </div>
      </Drawer>
    );
  }
}

export default SideBar;
